import InvoiceCard from "../../component/PurchaseOrder/Invoice/InvoiceCard";
import GeneralFilter from "../../component/GeneralFilter";
import GeneralPagination from "../../component/GeneralPagination";
import { FaEllipsisV } from "react-icons/fa";
import LogInFooter from "../../logincomponent/LogInFooter";
import React, { useEffect, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import TopHeader from "../../navbar/TopHeader";
import TopBar from "../../navbar/TopBar";
import PFList from "../../component/PurchaseOrder/Invoice/PFList";
import ModalFilter from "../../component/ASN/ModalFilter";
import { Link, useNavigate } from "react-router-dom";
import Filter from "../../assets/Icons/Normal Icons/Filter.svg";
import Search from "../../assets/Icons/Normal Icons/Search.svg";
import ErrorModel from "../../constants/Services/Api/errorModel";
import { api } from "../../constants/Services";
import ReactPaginate from "react-paginate";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment/moment";

function CommercialInvoiceList() {
  const navigate = useNavigate();
  const permission = localStorage.getItem("permissions");
  const [showInd, setShowInd] = useState();
  const [page, setPage] = useState({
    current_page: 1,
    per_page: 0,
    total: 0,
  });

  const [showModal, setShowModal] = useState(false);
  const [list, setList] = useState([]);
  const [listDraft, setListDraft] = useState([]);
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(null);
  const [pageCount, setPageCount] = useState(10);
  const [seacrh, setSearch] = useState("");
  const [invoiceStage, setInvoiceStage] = useState();
  const [proformaIn, setProformaIn] = useState();
  const [lists, setLists] = useState([]);

  // START PAGINATION

  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const dropHandler = (e) => {
    setShowInd(e);
    if (e == showInd) {
      setShowInd();
      console.log("1");
    }
  };
  const dropDownMenuClickHandler = () => {
    setShowModal(!showModal);
    setShowInd();
  };

  const dummy = [
    {
      sNo: "01",
      pfn: "DF00001",
      date: "01-12-2022",
      invoice: "Final",
      categ:
        "Fortune promoseven llc (A division of NUkhaba press disbution and release)",
      poRef: "PRF022",
      poDate: "01-12-2022",
      dcn: "GR890865",
    },
    {
      sNo: "02",
      pfn: "DF00002",
      date: "02-12-2022",
      invoice: "Final",
      categ:
        "Fortune promoseven llc (A division of NUkhaba press disbution and release)",
      poRef: "PRF022",
      poDate: "02-12-2022",
      dcn: "GR890862",
    },
    {
      sNo: "01",
      pfn: "DF00001",
      date: "01-12-2022",
      invoice: "Final",
      categ:
        "Fortune promoseven llc (A division of NUkhaba press disbution and release)",
      poRef: "PRF022",
      poDate: "01-12-2022",
      dcn: "GR890865",
    },
    {
      sNo: "02",
      pfn: "DF00002",
      date: "02-12-2022",
      invoice: "Final",
      categ:
        "Fortune promoseven llc (A division of NUkhaba press disbution and release)",
      poRef: "PRF022",
      poDate: "02-12-2022",
      dcn: "GR890862",
    },
    {
      sNo: "01",
      pfn: "DF00001",
      date: "01-12-2022",
      invoice: "Final",
      categ:
        "Fortune promoseven llc (A division of NUkhaba press disbution and release)",
      poRef: "PRF022",
      poDate: "01-12-2022",
      dcn: "GR890865",
    },
    {
      sNo: "02",
      pfn: "DF00002",
      date: "02-12-2022",
      invoice: "Final",
      categ:
        "Fortune promoseven llc (A division of NUkhaba press disbution and release)",
      poRef: "PRF022",
      poDate: "02-12-2022",
      dcn: "GR890862",
    },
  ];
  const data = [
    {
      terms: "Accept",
    },
    {
      terms: "Accept with Comments",
    },
    {
      terms: "Decline with Comments",
    },
  ];

  const getList = () => {
    if (seacrh !== "") {
      api
        .invoice_list(2, +page?.current_page, seacrh)
        .then((res) => {
          if (res?.status == "success") {
            let temp = [];
            res?.invoices?.map((item, index) => {
              temp.push(item?.invoice_stage);
            });
            setLists([...new Set(temp)]);
            setList(res?.invoices);
            let { total, per_page, current_page } = res?.pagination;
            setPage({
              total,
              per_page,
              current_page,
            });
          } else {
            setError(res);
            setErrorBox(true);
          }
        })
        .catch((err) => {
          console.log("err");
        });
    } else {
      api
        .invoice_list(2, +page?.current_page, "")
        .then((res) => {
          if (res?.status == "success") {
            let temp = [];
            res?.invoices?.map((item, index) => {
              temp.push(item?.invoice_stage);
            });
            setLists([...new Set(temp)]);
            setList(res?.invoices);
            let { total, per_page, current_page } = res?.pagination;
            setPage({
              total,
              per_page,
              current_page,
            });
          } else {
            setError(res);
            setErrorBox(true);
          }
        })
        .catch((err) => {
          console.log("err");
        });
    }

    // LIST DRAFT
    api
      .listDraft()
      .then((res) => {
        if (res?.status == "success") {
          let temp = res?.drafts?.filter(
            (i) => i?.draft?.method == "commercial"
          );
          setListDraft(temp);
        } else {
          setError(res);
          setErrorBox(true);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const handlePageChange = (selectedObject) => {
    setPage({ ...page, current_page: selectedObject.selected + 1 });
    setPages(selectedObject.selected + 1);
    setCount(selectedObject.selected);
  };

  const getFiter = () => {
    let params = `?invoice_no=${proformaIn}&type=2`;

    if (invoiceStage) {
      params = params + `&invoice_stage=${invoiceStage}`;
    }

    api
      .invoiceFilter(params)
      .then((res) => {
        if (res?.status == "success") {
          setShowModal(!showModal);
          setList(res?.invoices);
          setPageCount(res?.pagination);
          let { total, per_page, current_page } = res?.pagination;
          setPage({
            total,
            per_page,
            current_page,
          });
        } else {
          setError(res);
          setErrorBox(true);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  // DELETE DRAFT
  const deleteDrafts = (id, resp) => {
    let data = {
      draft_id: id,
    };
    api
      .deleteDraft(data)
      .then((res) => {
        if (res?.status === "success") {
          setShowInd();
          getList();
        } else {
          setError(res);
          setErrorBox(true);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getList();
  }, [page?.current_page, seacrh]);

  return (
    <div>
      <TopBar />
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopHeader type="commercial-invoice-list" />{" "}
      <div className="invoice_list_new">
        <Tabs
          defaultActiveKey="list"
          id="uncontrolled-tab-example"
          className="mx-3"
          // onSelect={(e) => console.log("e", e)}
        >
          <Tab className="mb-2" eventKey="list" title="Submitted">
            <div>
              <div
                style={{ minHeight: "80vh" }}
                className="enquiry-list-cont my-3 py-2 position-relative"
              >
                <div
                  className="d-sm-flex justify-content-between mb-2 align-items-center w-100"
                  // style={{ minWidth: "80%" }}
                >
                  {/* <div className="d-flex-wrap align-items-center gap-2 mb-0">
            <label>Show</label>
            <select
              name=""
              className="cont-border px-2 py-1"
              onChange={setListItems}
            >
              <option value="6">6</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
            <label>
              1-{itemsPerPage} of {lengths}
            </label>
          </div> */}
                  <div className="action-page-cont">
                    <p>Showing</p>
                    <div className="page-status-cont">
                      <p
                        style={{
                          border: "1px solid #ced4da",
                          padding: "4px",
                          margin: "10px 0px",
                          textAlign: "center",
                          width: "35px",
                          height: "28px",
                        }}
                      >
                        {list?.length}
                      </p>
                    </div>
                    <p>of {pageCount?.total} Commercial Invoice</p>
                  </div>
                  <div className="d-flex mt-2 mt-sm-0">
                    <div className="search-box d-flex gap-2 align-items-center px-2 rounded-1">
                      <input
                        placeholder="Search Title"
                        className="w-90 h-90"
                        onChange={(e) => setSearch(e.target.value)}
                        value={seacrh}
                        type={"text"}
                      />
                      <img className="search-icon" src={Search} alt="search" />
                    </div>
                    <button
                      className="filter-btn bg-primary2"
                      // onClick={onClickFilterHandler}
                      onClick={dropDownMenuClickHandler}
                    >
                      <img
                        src={Filter}
                        className="wid-15px me-1"
                        alt="filter"
                      />
                      Filter
                    </button>
                  </div>
                </div>
                {/* <PFList /> */}
                <div className="min-ht-80">
                  <div
                    style={{ minHeight: "60vh" }}
                    className="table-scroll-horizontal over-flow-x-scroll my-2 responsive-width w-100"
                  >
                    <table className="mb-1 py-1 table">
                      <thead style={{ background: "#ededed" }}>
                        <tr className="fnt-fam-f3 opac-85 border-0 text-center">
                          <th className="fs-14px text-center">S.No.</th>
                          <th className="fs-14px">Commercial Invoice Ref.</th>
                          <th className="text-nowrap fs-14px">Invoice Date</th>
                          <th className="text-nowrap fs-14px">Invoice Stage</th>
                          <th className="text-nowrap fs-14px">Total Price</th>
                          {/* <th style={{ minWidth: "100px" }} className="fs-14px">
                    PO Ref. No
                  </th>
                  <th style={{ minWidth: "100px" }} className="fs-14px">
                    PO Date
                  </th> */}
                          <th className="fs-14px text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody className="fs-6 opac-80">
                        {list?.map((item, ind) => {
                           var temp = 0;
                           item.det_data?.map((item1, ind1) => {
                            if(item1.length > 0 && temp != 1){
                              // console.log(item1[0].InvoicedQty, 'InvoicedQty');
                              var invoicedQty = parseInt(item1[0].InvoicedQty);
                              var actualQty = parseInt(item1[0].TotalInvoiceQty);
                              if(invoicedQty < actualQty){
                                console.log(item1,'cfghjkl');
                                temp = 1;
                              }
                            }
                           });
                           console.log(temp, 'jhgfdrtyukj');

                          return (
                            <tr key={ind} className="text-center">
                              <td>
                                {ind + 1 == 10 ? count + 1 : count}
                                {ind + 1 == 10 ? 0 : ind + 1}
                              </td>
                              <td>{item?.invoice_ref_no}</td>
                              <td>
                              {item?.invoice_date
                                  ? moment(item?.invoice_date).format(
                                      "DD-MMM-YYYY"
                                    )
                                  : "-"}
                              </td>
                              {/* <td>{item?.invoice_date}</td> */}
                              <td>
                                {/* {item?.invoice_stage == 1
                                  ? "Advanced"
                                  : item?.invoice_stage == 2
                                  ? "Partial"
                                  : "Final"} */}
                                {/* {item?.invoice_stage == 1
                                  ? "Advance Payment"
                                  : item?.invoice_stage == 2
                                  ? "TT Payment"
                                  : "50% Advance"} */}
                                {item?.stage?.ReferenceDetailsName}
                              </td>
                              <td>
                                {Number(item?.invoice_amount)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              {/* <td style={{ minWidth: "100px" }}>{item?.poRef}</td>
                      <td style={{ minWidth: "100px" }}>
                        <p>{item?.poDate}</p>
                      </td> */}
                              {/* {item?.status == "Yet to Dispatch" && (
                        <td className="text-center">
                          <p
                            style={{ border: "1px solid #0d6efd" }}
                            className="p-1 rounded-0 text-primary"
                          >
                            {item?.status}
                          </p>
                        </td>
                      )} */}
                              {/* {item?.status == "Partially Delivered" && (
                        <td className="text-center">
                          <p
                            style={{ border: "1px solid #e74c3c" }}
                            className="p-1 rounded-0 text-danger"
                          >
                            {item?.status}
                          </p>
                        </td>
                      )}{" "} */}
                              {/* {item?.status == "Inprogress" && (
                        <td className="text-center">
                          <p
                            style={{ border: "1px solid #0d6efd" }}
                            className="p-1 rounded-0 text-primary"
                          >
                            {item?.status}
                          </p>
                        </td>
                      )}
                      {item?.status == "Completed" && (
                        <td className="text-center">
                          <p
                            style={{ border: "1px solid #e74c3c" }}
                            className="p-1 rounded-0 text-danger"
                          >
                            {item?.status}
                          </p>
                        </td>
                      )} */}
                              <td className="position-relative text-center">
                                <button
                                  onClick={() => dropHandler(ind)}
                                  className="cust-btn dropdown-container-tool pt-1"
                                >
                                  <FaEllipsisV />
                                </button>
                                {ind == showInd && (
                                  <div
                                    style={{ zIndex: 2 }}
                                    className="po-card-drop-down1 text-start d-flex flex-column p-3 drop-downs"
                                  >
                                    {permission?.includes("VIEW-INVOICE") && (
                                      <button
                                        onClick={() =>
                                          item?.terms_conditions?.length ==
                                          undefined && temp == 0
                                            ? navigate(
                                                "/invoice/commercial/create",
                                                {
                                                  state: {
                                                    item: item,
                                                    type: "view",
                                                    method: "commercial",
                                                    // method: "proforma",
                                                  },
                                                }
                                              )
                                            : navigate("/invoice", {
                                                state: {
                                                  item: item,
                                                  type: "view",
                                                  method: "commercial",
                                                },
                                              })
                                        }
                                        className="asn_views text-nowrap pb-1"
                                      >
                                        View
                                      </button>
                                    )}
                                    {/* <Link to="/asn" className="text-wht pb-1">
                        Create ASN
                      </Link> */}
                                    {permission?.includes("EDIT-INVOICE") && (
                                      <button
                                        onClick={() =>
                                          item?.terms_conditions?.length ==
                                          undefined && temp == 0
                                            ? navigate(
                                                "/invoice/commercial/create",
                                                {
                                                  state: {
                                                    item: item,
                                                    type: "edit",
                                                    method: "commercial",
                                                  },
                                                }
                                              )
                                            : navigate("/invoice", {
                                                state: {
                                                  item: item,
                                                  type: "edit",
                                                  method: "commercial",
                                                },
                                              })
                                        }
                                        className="asn_views text-nowrap pb-1"
                                      >
                                        Edit
                                      </button>
                                    )}
                                    <button
                                      className="asn_views text-nowrap pb-1"
                                      // onClick={dropDownMenuClickHandler}
                                    >
                                      Follow Up
                                    </button>
                                    {/* <Link className="text-wht pb-1" onClick={invSelHandler}>
                        Create Profomal Invoice
                      </Link>
                      <Link className="text-wht pb-1">
                        Create Delivery Challan
                      </Link>
                      <Link
                        className="text-wht text-nowrap py-1"
                        onClick={invSelHandler}
                      >
                        Create Commercial Invoice
                      </Link>
                      <Link
                        to="/purchase-order/shipping-tracker"
                        className="text-wht py-1"
                      >
                        Update Shipping Tracks
                      </Link> */}
                                  </div>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {/* <ModalFilter setShowModal={setShowModal} showModal={showModal} /> */}
                  </div>
                </div>
                {/* <InvoiceCard />
        <InvoiceCard />
        <InvoiceCard />
        <InvoiceCard /> */}
                <div className="page-inner-cont py-2 d-flex justify-content-end">
                  <ReactPaginate
                    nextLabel="›"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    pageCount={Math.ceil(page?.total / page?.per_page)}
                    previousLabel="‹"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
                <Modal
                  id="#verify"
                  className="prod-filter"
                  show={showModal}
                  centered
                  onHide={() => setShowModal(false)}
                  aria-labelledby="contained-modal-title-vcenter"
                >
                  <Modal.Header closeButton>
                    <h5 className="mb-0">Filter</h5>
                  </Modal.Header>
                  <Modal.Body className="d-flex flex-wrap modal-inputs px-0">
                    <Col xs={6} lg={6}>
                      <div className="input-box">
                        <label>Proforma Invoice Ref.</label>
                        <select
                          onChange={(e) => setProformaIn(e.target.value)}
                          value={proformaIn}
                        >
                          <option>Select Proforma Invoice Ref.</option>
                          {list?.map((item, i) => (
                            <option value={item?.invoice_ref_no}>
                              {item?.invoice_ref_no}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col xs={6} lg={6}>
                      <div className="input-box">
                        <label>Invoice Stage</label>
                        <select
                          onChange={(e) => setInvoiceStage(e.target.value)}
                          value={invoiceStage}
                        >
                          <option>Select Invoice Stage</option>
                          {lists?.map((item, i) => {
                            return (
                              <option value={item}>
                                {item == 1
                                  ? "TT Payment"
                                  : item == 2
                                  ? "Advanced Payment"
                                  : "Final"}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                  </Modal.Body>
                  <div className="d-flex justify-content-end">
                    <button
                      className="cust-btn mb-2 bg-transparent border-1 border me-1 text-dark"
                      onClick={() => {
                        setShowModal(!showModal);
                        setProformaIn("");
                        setInvoiceStage("");
                        getList();
                      }}
                    >
                      Clear
                    </button>
                    <button
                      className="cust-btn mb-2 bg-primary1 me-1 text-white"
                      onClick={getFiter}
                    >
                      Apply
                    </button>
                  </div>
                </Modal>
              </div>
              <div className="rg-footer">
                <LogInFooter />
              </div>
            </div>
          </Tab>{" "}
          <Tab className="mb-2" eventKey="draft" title="Draft">
            <div
              style={{ minHeight: "80vh" }}
              className="enquiry-list-cont my-3 py-2 position-relative"
            >
              <div className="min-ht-80">
                <div
                  style={{ minHeight: "60vh" }}
                  className="table-scroll-horizontal over-flow-x-scroll my-2 responsive-width w-100"
                >
                  <table className="mb-1 py-1 table">
                    <thead style={{ background: "#ededed" }}>
                      <tr className="border-0 text-center">
                        <th className="text-nowrap">S.No</th>
                        <th className="text-nowrap">Commercial Invoice Ref.</th>
                        <th className="text-nowrap">Invoice Date</th>
                        <th className="text-nowrap">Invoice Stage</th>
                        <th className="text-nowrap">Invoice Amount</th>
                        <th className="text-center text-nowrap">Action</th>
                      </tr>
                    </thead>
                    <tbody className="opac-80 text-center">
                      {listDraft?.map((item, ind) => {
                        return (
                          <tr key={ind}>
                            <td>
                              <p>
                                {ind + 1 == 10 ? count + 1 : count}
                                {ind + 1 == 10 ? 0 : ind + 1}
                              </p>
                            </td>
                            <td>
                              <p>{item?.draft?.invoice_ref_no}</p>
                            </td>
                            <td>
                              <p>
                                {item?.draft?.invoice_date
                                  ? moment(item?.draft?.invoice_date).format(
                                      "DD-MMM-YYYY"
                                    )
                                  : "-"}
                              </p>
                            </td>
                            <td>
                              <p>
                                {+item?.draft?.invoice_stage == 1
                                  ? "Advanced"
                                  : +item?.draft?.invoice_stage == 2
                                  ? "Partial"
                                  : "Final"}
                              </p>
                            </td>
                            <td>
                              {item?.draft?.invoice_amount
                                ? Number(item?.draft?.invoice_amount).toFixed(2)
                                : "-"}
                            </td>
                            <td className="position-relative text-center">
                              <button
                                onClick={() => dropHandler(ind)}
                                className="cust-btn dropdown-container-tool pt-1"
                              >
                                <FaEllipsisV />
                              </button>
                              {ind == showInd && (
                                <div
                                  style={{ zIndex: 2 }}
                                  className="po-card-drop-down1 text-start d-flex flex-column p-3 drop-downs"
                                >
                                  {/* {permission?.includes("VIEW-INVOICE") && (
                                    <button
                                      onClick={() =>
                                        item?.draft?.method_type !== "create"
                                          ? navigate(
                                              "/invoice/commercial/create",
                                              {
                                                state: {
                                                  item: item,
                                                  type: "draft",
                                                  method: "commercial",
                                                },
                                              }
                                            )
                                          : navigate("/invoice", {
                                              state: {
                                                item: item,
                                                type: "draft",
                                                method: "commercial",
                                              },
                                            })
                                      }
                                      className="asn_views text-nowrap pb-1"
                                    >
                                      View
                                    </button>
                                  )} */}
                                  {permission?.includes("EDIT-INVOICE") && (
                                    <button
                                      onClick={() =>
                                        item?.draft?.method_type !== "create"
                                          ? navigate(
                                              "/invoice/commercial/create",
                                              {
                                                state: {
                                                  item: item,
                                                  type: "draft",
                                                  method: "commercial",
                                                },
                                              }
                                            )
                                          : navigate("/invoice", {
                                              state: {
                                                item: item,
                                                type: "draft",
                                                method: "commercial",
                                              },
                                            })
                                      }
                                      className="asn_views text-nowrap pb-1"
                                    >
                                      Edit
                                    </button>
                                  )}
                                  <button
                                    className="asn_views text-nowrap pb-1"
                                    onClick={() => deleteDrafts(item?.id, item)}
                                  >
                                    Delete Invoice
                                  </button>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default CommercialInvoiceList;
