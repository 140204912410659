import React, { useState, useEffect } from "react";
import { Col, Image, Form, Button } from "react-bootstrap";
import {
  CompanyImg,
  Twiter,
  Instagram,
  Linkedin,
  Facebook,
  PersonImg,
  TwiterX,
} from "../assets/img";
import McpRegister from "./McpRegister";
import { TbEdit } from "react-icons/tb";
import { FiUpload } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineFilePdf } from "react-icons/ai";
import { AiOutlineFileJpg } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import Select from "react-select";
import { chooseOptions } from "../layout/dummyData";
import { api } from "../constants/Services";
import ErrorModel from "../constants/Services/Api/errorModel";
import Loader from "./Loader";
import useUser from "../constants/Storage/userData";
import useProfileStatus from "../constants/Storage/userStauts";
import { RiShakeHandsFill } from "react-icons/ri";
import { useLocation } from "react-router-dom";

// const BasicInform = ({ image }) => {
const BasicInform = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const location = useLocation();
  const type = location?.state?.type;
  const { profileStatus, setProfileStatus } = useProfileStatus();
  const permissions = localStorage.getItem("permissions");
  // const [image, setImage] = useState();
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const [chooseServiceSupplier, setChooseServiceSupplier] = useState("");

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    // setImage(e.target.value);

    // image(e.target.files[0]);
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const [uploadImage, setUploadImage] = useState();
  const [image, setImage] = useState();

  const fileChange = (e) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    switch (file.type) {
      case "image/png":
        setImage(file);
        setUploadImage(URL.createObjectURL(e.target.files[0]));
        //('image type is png');
        break;
      case "image/jpg":
        //('image/jpg')
        setImage(file);
        setUploadImage(URL.createObjectURL(e.target.files[0]));
        break;
      case "image/jpeg":
        setImage(file);
        setUploadImage(URL.createObjectURL(e.target.files[0]));
        //('image is jpeg')
        break;
      default:
        alert("Only Upload Format png, jpg, jpeg");
      // image extension is not valid
    }
  };

  const [KHId, setKHId] = useState("");
  const [sName, setSName] = useState("");
  const [sCategory, setSCategory] = useState("");
  const [sType, setSType] = useState("");
  const [sourceType, setSourceType] = useState("");
  const [source, setSource] = useState("");
  const [cSize, setCSize] = useState("");
  const [bSource, setBSource] = useState("");
  const [gst, setGst] = useState("");
  const [msmeCategory, setMsmeCategory] = useState("");
  const [msmeNo, setMsmeNo] = useState("");
  const [msmeId, setMsmeId] = useState("");
  const [panNo, setPanNo] = useState("");
  const [wName, setWName] = useState("");
  const [comEmail, setComEmail] = useState("");
  const [currency, setCurrency] = useState("");
  const [payterms, setPayTerms] = useState("");
  const [deliveryTerms, setDeleveryTerms] = useState("");
  const [inActiveList, setInActiveList] = useState("");
  const [mapDivision, setMapDivision] = useState("");
  const [aboutUs, setAboutUs] = useState("");
  const [msmeFile, setMsmeFile] = useState("");
  const [msmeFilePre, setMsmeFilePre] = useState("");

  const [facebook, setFacebook] = useState("");
  const [twiter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedin, setLinkedin] = useState("");

  const [SupplierID, setSupplierID] = useState("");

  // VALIDATION

  const [KHIdErr, setKHIdErr] = useState(false);
  const [sNameErr, setSNameErr] = useState(false);
  const [sCategoryErr, setSCategoryErr] = useState(false);
  const [sTypeErr, setSTypeErr] = useState(false);
  const [sourceTypeErr, setSourceTypeErr] = useState(false);
  const [sourceErr, setSourceErr] = useState(false);
  const [cSizeErr, setCSizeErr] = useState(false);
  const [bSourceErr, setBSourceErr] = useState(false);
  const [gstErr, setGstErr] = useState(false);
  const [msmeCategoryErr, setMsmeCategoryErr] = useState(false);
  const [msmeNoErr, setMsmeNoErr] = useState(false);
  const [panNoErr, setPanNoErr] = useState(false);
  const [wNameErr, setWNameErr] = useState(false);
  const [comEmailErr, setComEmailErr] = useState(false);
  const [currencyErr, setCurrencyErr] = useState(false);
  const [paytermsErr, setPayTermsErr] = useState(false);
  const [deliveryTermsErr, setDeleveryTermsErr] = useState(false);
  const [inActiveListErr, setInActiveListErr] = useState(false);
  const [mapDivisionErr, setMapDivisionErr] = useState(false);
  const [aboutUsErr, setAboutUsErr] = useState(false);

  const [facebookErr, setFacebookErr] = useState(false);
  const [twiterErr, setTwitterErr] = useState(false);
  const [instagramErr, setInstagramErr] = useState(false);
  const [linkedinErr, setLinkedinErr] = useState(false);

  const [buttonEnable, setButtonEnable] = useState(false);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [loader, setLoader] = useState(false);
  const [list, setList] = useState([]);
  const [factoriesList, setFactoriesList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selecteds, setSelecteds] = useState([]);
  const [certificateDoc, setCertificateDoc] = useState(0);
  const [certificateFile, setCertificateFile] = useState('');
  const [certificateUrl, setCertificateUrl] = useState('');
  const [certificateType, setCertificateType] = useState('');
  // API FUNCTION

  const [input, setInput] = useState([]);
  let inputValid = false;
  const details = () => {
    api.createDetails().then((res) => {
      if (res?.status == "success") {
        setInput(res);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });

    api.getFactories().then((res) => {
      let temp = [];
      if (res?.status == "success") {
        let responce = res?.factories?.filter((i) => i?.id !== 1);
        if (responce) {
          responce?.map((item) => {
            temp.push({
              value: item?.id,
              label: item?.name,
            });
          });

          setFactoriesList(temp);
        }
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const profileView = () => {
    api.profileView().then((res) => {
      if (res?.status == "success") {
        let data = res?.supplier;
        let SupplierCategory;
        if (data?.SupplierCategory) {
          SupplierCategory = data?.SupplierCategory.split(" ");
        } else {
          SupplierCategory = data?.SupplierCategory;
        }

        let num = [];
        let nums = [];

        if (data?.InActiveList?.length > 1) {
          let sl = data?.InActiveList?.split(",");
          let plus = sl?.map(Number);
          let result = factoriesList?.filter((i) => plus?.includes(i?.value));

          result?.map((item, ind) => {
            num.push(item?.label);
          });
        } else {
          let results = factoriesList?.filter(
            (i) => i?.value == data?.InActiveList
          );

          num.push(results[0]?.label);
        }

        setSelected(num);

        if (data?.FactoryList?.length > 1) {
          let sl = data?.FactoryList?.split(",");
          let plus = sl?.map(Number);
          let result = factoriesList?.filter((i) => plus?.includes(i?.value));

          result?.map((item, ind) => {
            nums.push(item?.label);
          });
        } else {
          let results = factoriesList?.filter(
            (i) => i?.value == data?.FactoryList
          );

          nums.push(results[0]?.label);
        }

        setSelecteds(nums);
        setProfileStatus(
          res?.supplier?.profile_status ? res?.supplier?.profile_status : "0"
        );
        console.log(data, 'datadatadata');
        setList(res?.supplier);
        setKHId(data?.SupplierID);
        setSName(data?.ShortName);
        setChooseServiceSupplier(SupplierCategory);
        setSourceType(data?.SourceTypeID);
        setSource(data?.SourceID);
        setCSize(data?.CompanySize);
        setBSource(data?.BusinessSourceID);
        setGst(data?.GSTNo);
        setSType(data?.CountryID);
        setMsmeCategory(data?.MSMEID);
        setMsmeNo(data?.MSMENo);
        setPanNo(data?.PanNo);
        setWName(data?.WebSite);
        setComEmail(data?.SPRD_email);
        setCurrency(data?.CurrencyID);
        setMapDivision(data?.SPRD_Division);
        setAboutUs(data?.CompanyDescription);
        setFacebook(data?.Facebook);
        setUploadImage(data?.img_url);
        // setImage(data?.SupplierImage);
        setTwitter(data?.Twitter);
        setInstagram(data?.Instagram);
        setLinkedin(data?.Linkedin);
        setInActiveList(data?.InActiveList);
        setSupplierID(data?.SupplierID);
        setPayTerms(data?.PayTermsID);
        setMsmeFilePre(data?.MSMEFile);
        setCertificateUrl(data?.msms_url);
        setDeleveryTerms(+data?.DeliveryTermsID);
        setSCategory(SupplierCategory[0]);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    details();
  }, []);

  useEffect(() => {
    if (factoriesList) {
      profileView();
    }
  }, [factoriesList]);

  const submitHandle = () => {
    console.log("first");
    selectInputValid();
    if (
      KHId?.length == 0 ||
      KHId == null ||
      sName?.length == 0 ||
      sName == null ||
      chooseServiceSupplier?.length == 0 ||
      // sType?.length == 0 ||
      sourceType?.length == 0 ||
      sourceType == null ||
      source?.length == 0 ||
      source == null ||
      cSize?.length == 0 ||
      cSize == null ||
      bSource?.length == 0 ||
      // bSource == null ||
      // gst?.length == 0 ||
      sCategory?.length == 0 ||
      sCategory == null ||
      // msmeCategory?.length == 0 ||
      // msmeNo?.length == 0 ||
      // panNo?.length == 0 ||
      wName?.length == 0 ||
      wName == null ||
      // comEmail?.length == 0 ||
      currency?.length == 0 ||
      currency == null ||
      // inActiveList?.length == 0 ||
      // mapDivision?.length == 0 ||
      aboutUs?.length == 0 ||
      facebook?.length == 0 ||
      twiter?.length == 0 ||
      instagram?.length == 0 ||
      linkedin?.length == 0 ||
      payterms?.length == 0 ||
      payterms == null ||
      deliveryTerms?.length == 0
    ) {
      console.log("second");
      setKHIdErr(true);
      setSNameErr(true);
      setSCategoryErr(true);
      // setSTypeErr(true);
      setSourceTypeErr(true);
      setSourceErr(true);
      // setCSizeErr(true);
      setBSourceErr(true);
      // setGstErr(true);
      setMsmeCategoryErr(true);
      // setMsmeNoErr(true);
      // setPanNoErr(true);
      setWNameErr(true);
      setPayTermsErr(true);
      setDeleveryTermsErr(true);
      // setComEmailErr(true);
      setCurrencyErr(true);
      // setInActiveListErr(true);
      // setMapDivisionErr(true);
      setAboutUsErr(true);
      setFacebookErr(true);
      setTwitterErr(true);
      setInstagramErr(true);
      setLinkedinErr(true);
    } else if (inputValid) {
      let formdata = new FormData();
      console.log("test");

      formdata.append("short_name", sName);
      formdata.append("category", sCategory);
      formdata.append("source_type", sourceType);
      formdata.append("source", source);
      formdata.append("company_size", cSize);
      formdata.append("bussiness_source", bSource);
      formdata.append("gst_number", 0);
      // formdata.append("gst_number", gst);
      formdata.append("msme_category", msmeCategory);
      formdata.append("msme_number", msmeNo);
      formdata.append("pan_number", panNo ? panNo : "-");
      formdata.append("website", wName);
      formdata.append("about_company", aboutUs);
      formdata.append("division", "map_division");
      formdata.append("currency_id", currency);
      formdata.append("id", KHId);

      if (inActiveList) {
        formdata.append("in_active_list", " ");
      }

      // NEW

      // formdata.append("supplier_code", "SC002");
      formdata.append(
        "supplier_code",
        list?.SupplierCode ? list?.SupplierCode : ""
      );
      formdata.append("address1", list?.Address1 ? list?.Address1 : "");
      formdata.append("address2", list?.Address2 ? list?.Address2 : "");
      formdata.append("address3", list?.Address3 ? list?.Address3 : "");
      formdata.append("address4", list?.Address4 ? list?.Address4 : "");
      formdata.append("address5", list?.Address5 ? list?.Address5 : "");
      formdata.append("city_id", list?.CityID);
      formdata.append("country_id", list?.CountryID);
      formdata.append("state_id", list?.StateID);
      formdata.append("fax", list?.FaxNo ? list?.FaxNo : "");
      formdata.append("delivery_term_id", deliveryTerms);
      formdata.append("pay_term_id", payterms);
      formdata.append(
        "branch_type_id",
        list?.SupplierBranchTypeId ? list?.SupplierBranchTypeId : ""
      );
      formdata.append("msme_file", msmeFile);

      if (image) {
        formdata.append("supplier_image", image);
      }

      // STATIC

      formdata.append("supplier_name", user?.user_name);
      formdata.append("phone_no", list?.PhoneNo);
      formdata.append("email", list?.Email);
      formdata.append("telephone", list?.Telephone);
      formdata.append("telephone_code", list?.TelephoneCode);
      formdata.append("telephone_ext", list?.TelephoneExt);

      if (facebook) {
        formdata.append("facebook", facebook);
      }

      if (twiter) {
        formdata.append("twitter", twiter);
      }

      if (instagram) {
        formdata.append("instagram", instagram);
      }

      if (linkedin) {
        formdata.append("linked_in", linkedin);
      }

      setLoader(true);
      setButtonEnable(true);
      api.profileUpdate(formdata).then((res) => {
        if (res?.status == "success") {
          profileView();
          let data = res?.supplier;
          setList(res?.supplier);
          setKHId(data?.SupplierID);
          setSName(data?.ShortName);
          setSCategory(data?.SupplierCategory);
          setChooseServiceSupplier(data?.SupplierCategory);
          setSourceType(data?.SourceTypeID);
          setSource(data?.SourceID);
          setCSize(data?.CompanySize);
          setBSource(data?.BusinessSourceID);
          setGst(data?.GSTNo);
          setMsmeCategory(data?.MSMEID);
          setMsmeNo(data?.MSMENo);
          setPanNo(data?.PanNo);
          setWName(data?.WebSite);
          setComEmail(data?.SPRD_email);
          setCurrency(data?.CurrencyID);
          setMapDivision(data?.SPRD_Division);
          setAboutUs(data?.CompanyDescription);
          setFacebook(data?.Facebook);
          setTwitter(data?.Twitter);
          setUploadImage(data?.img_url);
          setImage(data?.img_url);
          setInstagram(data?.Instagram);
          setLinkedin(data?.Linkedin);
          setInActiveList(data?.InActiveList);
          setButtonEnable(false);
          setError(res);
          setErrorBox(true);
          setLoader(false);
          setTimeout(() => {
            if (profileStatus == "1" || profileStatus == 1) {
              navigate("/mcprofile/addtransport");
              setTimeout(() => {
                window.location.reload();
              }, 400);
            } else if (profileStatus == "2" || profileStatus == 2) {
              navigate("/mcprofile/addcertificate");
              setTimeout(() => {
                window.location.reload();
              }, 400);
            } else {
              navigate("/mcprofile/transport");
            }
          }, 1500);
        } else {
          setError(res);
          setErrorBox(true);
          setLoader(false);
          setButtonEnable(false);
        }
      });
    }
  };

  const openCertificateDoc = () => {
    window.open(certificateUrl, '_blank');
  };

  const hideCertificateDoc = () => {
    setCertificateDoc(1);
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };
  const selectArr = document.querySelectorAll(".basic-tag select");
  const selectInputValid = () => {
    let arr = [];
    selectArr.length > 0 &&
      selectArr.forEach((ele) => {
        if (ele.value == "" || ele.value == null) {
          document.getElementById(`${ele.id}_err`)?.classList.add("d-block");
          document.getElementById(`${ele.id}_err`)?.classList.remove("d-none");
          arr.push(ele);
        } else {
          document.getElementById(`${ele.id}_err`)?.classList.add("d-none");
          document.getElementById(`${ele.id}_err`)?.classList.remove("d-block");
        }
      });
    arr.length == 0 ? (inputValid = true) : (inputValid = false);
  };

  useEffect(() => {

  }, [certificateType]);
  console.log(bSource, "bSource");
  return (
    <div className="basic-tag">
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      {loader && <Loader />}
      <div className="d-flex-wrap">
        <Col md={3} xs={12} className="px-2 my-2">
          <div className="prof-imag">
            <Image
              ref={uploadedImage}
              src={uploadImage ? uploadImage : PersonImg}
              onClick={() => imageUploader.current.click()}
              className="prf-img  "
              style={{ borderRadius: "100%" }}
            />
            <input
              type="file"
              accept="image/*"
              onChange={fileChange}
              ref={imageUploader}
              className="d-none"
            />
          </div>
        </Col>
        <Col md={8} xs={12} className="px-2 my-2">
          <h5 className="text-uppercase my-4 my-3">
            {user?.supplier?.SupplierName}
          </h5>
          {/* <p>Supplier Registration No: S{user?.emp_id}</p> */}
        </Col>
      </div>
      <div className="basi_inform my-4">
        <Form>
          <div className="d-flex-wrap">
            <Col md={6} xs={12} className="pe-3 my-2 position-relative">
              <label>
                KH Supplier ID <span className="red-text">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder=" S10002"
                onChange={(e) => setKHId(e.target.value)}
                value={KHId}
                disabled
              />
              {(KHId?.length == 0 || KHId == null) && KHIdErr && (
                <p className="err-input pe-3">Enter KH Supplier ID</p>
              )}
            </Col>
            <Col md={6} xs={12} className="pe-3 my-2 position-relative">
              <label>
                Short Name<span className="red-text">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder=""
                onChange={(e) => setSName(e.target.value)}
                value={sName}
                disabled={
                  permissions?.includes("EDIT-BASIC INFORMATION") ? false : true
                }
              />
              {(sName?.length == 0 || sName == null) && sNameErr && (
                <p className="err-input pe-3">Enter Name</p>
              )}
            </Col>

            {/* <Col md={6} xs={12} className="pe-3 my-2">
              <label>
                Source Type<span className="red-text">*</span>
              </label>
              <select
                className="form-select "
                onChange={(e) => setSType(e.target.value)}
                value={sType}
              >
                <option> Inhouse </option>
                <option>Source 1</option>
                <option>Source 2</option>
              </select>
              
            {sType?.length == 0 && sTypeErr && (
              <p className="err-input pe-3">Select Source Type</p>
            )}
            </Col> */}
            <Col md={6} xs={12} className="pe-3 my-2 position-relative">
              <label>
                Source <span className="red-text">*</span>
              </label>
              <select
                className="form-select "
                id="source"
                onChange={(e) => setSource(e.target.value)}
                value={source}
                disabled={
                  permissions?.includes("EDIT-BASIC INFORMATION") ? false : true
                }
              >
                <option value={""} hidden>
                  Select Source
                </option>
                {input?.sources?.map((item, i) => (
                  <option key={i} value={item?.id}>
                    {item?.name}
                  </option>
                ))}
              </select>
              <p className="err-input pe-3 d-none" id="source_err">
                Select Source
              </p>
            </Col>
            <Col md={6} xs={12} className="pe-3 my-2 position-relative">
              <label>
                Source Type<span className="red-text">*</span>
              </label>
              <select
                className="form-select "
                onChange={(e) => setSourceType(e.target.value)}
                id="source_type"
                // value={input?.source_types?.forEach(data=> data.id == sourceType ? sourceType : '')}
                value={sourceType}
                disabled={
                  permissions?.includes("EDIT-BASIC INFORMATION") ? false : true
                }
              >
                <option value={""} hidden>
                  Select Source Type
                </option>
                {input?.source_types?.map((item) => (
                  <option value={item?.id}>{item?.name}</option>
                ))}
              </select>
              <p className="err-input pe-3 d-none" id="source_type_err">
                Select Source Type
              </p>
            </Col>
            <Col md={6} xs={12} className="pe-3 my-2 position-relative">
              <label>
                Supplier Category<span className="red-text">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder=" S10002"
                onChange={(e) => setSCategory(e.target.value)}
                value={sCategory == "M"
                  ? "Material"
                  : sCategory == "L"
                    ? "Leather"
                    : sCategory == "ML"
                      ? "Material & Leather"
                      : ""
                }
                disabled
              />
              {/* <select
                className="form-select pb-0"
                onChange={(e) => setSCategory(e.target.value)}
                // value={sCategory}
               
                disabled
              >
                <option value={""} hidden>
                  Select Category{" "}
                </option>
                {input?.supplier_categories?.map((item) => (
                  <option value={item?.name}>
                    {item?.name == "M"
                      ? "Material"
                      : item?.name == "L"
                      ? "Leather"
                      : item?.name == "ML"
                      ? "Material & Leather"
                      : ""}
                  </option>
                ))}
              </select> */}
              {sCategory?.length == 0 && sCategoryErr && (
                <p className="err-input pe-3">Select Supplier Category</p>
              )}
            </Col>

            <Col md={6} xs={12} className="pe-3 my-2 position-relative dropdown-container">
              <label>
                Business Source
                {/* <span className="red-text">*</span> */}
              </label>

              <select
                className="form-select custom-dropdown"
                onChange={(e) => setBSource(e.target.value)}
                value={bSource}
                disabled
              >
                <option value={""} hidden>
                  Select Business Source
                </option>
                {input?.bussiness_source_types?.map((item) => (
                  <option value={item?.id}>{item?.name}</option>
                ))}
              </select>
              {bSource?.length == 0 && bSourceErr && (
                <p className="err-input pe-3">Select Source</p>
              )}
            </Col>

            {sType == 1 ? (
              <Col md={6} xs={12} className="pe-3 my-2 position-relative">
                <label>
                  GST Number<span className="red-text">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  onChange={(e) => setGst(e.target.value)}
                  value={gst}

                  disabled
                />
                {gst?.length == 0 && gstErr && (
                  <p className="err-input pe-3">Enter GST Number</p>
                )}
              </Col>
            ) : null}

            {sType == 1 && (
              <Col md={6} xs={12} className="pe-3 my-2 position-relative">
                <label>
                  PAN Number<span className="red-text">*</span>
                </label>
                <input
                  className="form-control text-uppercase"
                  type="text"
                  placeholder=""
                  onChange={(e) => setPanNo(e.target.value)}
                  value={panNo}
                  disabled={
                    permissions?.includes("EDIT-BASIC INFORMATION")
                      ? false
                      : true
                  }
                />
                {panNo?.length == 0 && panNoErr && (
                  <p className="err-input pe-3">Enter PAN Number</p>
                )}
              </Col>
            )}

            <Col md={6} xs={12} className="pe-3 my-2 position-relative">
              <label>
                Employee Count <span className="red-text">*</span>
              </label>
              {/* <input
                className="form-control"
                type="text"
                placeholder=""
                onChange={(e) => setCSize(e.target.value)}
                value={cSize}
              /> */}
              <select
                className="form-select"
                onChange={(e) => setCSize(e.target.value)}
                value={cSize !== null ? cSize : ""}
                id="employee_count"
                disabled={
                  permissions?.includes("EDIT-BASIC INFORMATION") ? false : true
                }
              >
                <option value="" hidden>
                  Select Employee Count
                </option>
                <option value={"25"}>0 to 25</option>
                <option value={"50"}>25 to 50</option>
                <option value={"100"}>50 to 100</option>
                <option value={"200"}>100 and above</option>
              </select>
              <p className="err-input pe-3 d-none" id="employee_count_err">
                Enter Employee Count
              </p>
            </Col>

            <Col md={6} xs={12} className="pe-3 my-2  position-relative">
              <div>
                <label>
                  MSME Category<span className="red-text">*</span>
                </label>
                <select
                  className="form-select "
                  onChange={(e) => setMsmeCategory(e.target.value)}
                  value={msmeCategory}
                  disabled={
                    permissions?.includes("EDIT-BASIC INFORMATION")
                      ? false
                      : true
                  }
                >
                  <option value={""}>Select MSME Category</option>
                  {input?.msme_types?.map((item, ind) => (
                    <option value={item?.id} key={ind}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>

              {msmeCategory?.length == 0 && msmeCategoryErr && (
                <p className="err-input pe-3">Select MSME Category</p>
              )}
            </Col>

            {/* <Col md={6} xs={12} className="pe-3 my-2 position-relative">
              <label>MSME ID</label>
              <input
                className="form-control"
                type="text"
                placeholder=""
                onChange={(e) => setMsmeId(e.target.value)}
                value={msmeId}
              />
            </Col> */}

            <Col md={6} xs={12} className="pe-3 my-2 position-relative">
              <label>
                {sType == 1 ? "MSME Number" : "IE Code"}
                {/* <span className="red-text">*</span> */}
              </label>
              {/* <select
                className="form-select "
                onChange={(e) => setMsmeNo(e.target.value)}
                value={msmeNo}
              >
                <option> </option>
                <option>Source 1</option>
                <option>Source 2</option>
              </select> */}
              <input
                className="form-control"
                type="text"
                placeholder=""
                onChange={(e) => setMsmeNo(e.target.value)}
                value={msmeNo}
                disabled={
                  permissions?.includes("EDIT-BASIC INFORMATION") ? false : true
                }
              />
              {msmeNo?.length == 0 && msmeNoErr && (
                <p className="err-input pe-3">
                  Select {sType == 1 ? "MSME No" : "IE Code"}
                </p>
              )}
            </Col>
            <Col md={6} xs={12} className="pe-3 my-2">
              <label className="w-100">Upload Certificate</label>
              {/* <button className="border-0 bg-transparent opac_half fs-3 ms-1">
                <FiUpload />
              </button> */}
              <input
                type="file"
                onChange={(e) => {
                  setMsmeFile(e.target.files[0]);
                  setMsmeFilePre(URL.createObjectURL(e.target.files[0]));
                  setCertificateUrl(URL.createObjectURL(e.target.files[0]));
                  setCertificateFile(e.target.files[0].name);
                  setCertificateType(e.target.files[0].type);
                  setCertificateDoc(0);
                }}
                disabled={
                  permissions?.includes("EDIT-BASIC INFORMATION") ? false : true
                }
              />
              {/* {msmeFilePre ? (
                <div className="d-flex gap-1 my-4">
                  <div className="d-flex align-items-center pdf-icon-cont">
                    <AiOutlineFilePdf />
                  </div>
                  <p className="d-flex align-items-center">{"Certificate"}</p>
                </div>
              ) : null} */}
            </Col>
            {msmeFilePre && certificateDoc == 0 && (
              <Col md={12} xs={12} className="pe-3 my-2 position-relative">
                <p className="d-flex justify-content-between px-2 my-2 py-2 align-items-center file-upload-cont-bg wid-97-perc">
                  <span className="d-flex gap-2" onClick={openCertificateDoc} style={{ cursor: 'pointer' }}>
                    {certificateType == 'application/pdf' ?
                      <AiOutlineFilePdf className="text-success fs-4" /> :
                      <AiOutlineFileJpg className="text-success fs-4" />
                    }
                    {certificateFile}
                  </span>
                  <span
                    className="create-inv-delete-icon-cont"
                  // onClick={() =>
                  //   type == "view" ? null : setSupFile(null) && setSupFiles(null)
                  // }
                  >
                    <span onClick={hideCertificateDoc} style={{ cursor: 'pointer' }}>
                      <RiDeleteBin6Line />
                    </span>
                  </span>
                </p>
              </Col>
            )}
            <Col md={6} xs={12} className="pe-3 my-2 position-relative">
              <label>
                Website<span className="red-text">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="www.abc.com"
                onChange={(e) => setWName(e.target.value)}
                value={wName}
                disabled={
                  permissions?.includes("EDIT-BASIC INFORMATION") ? false : true
                }
              />
              {wName?.length == 0 && wNameErr && (
                <p className="err-input pe-3">Enter Website URL</p>
              )}
            </Col>

            {/* <Col md={6} xs={12} className="pe-3 my-2 position-relative">
              <label>
                Company Email ID<span className="red-text">*</span>
              </label>
              <input
                className="form-control"
                type="email"
                placeholder=""
                onChange={(e) => setComEmail(e.target.value)}
                value={comEmail}
              />
              {comEmail?.length == 0 && comEmailErr && (
                <p className="err-input pe-3">Enter Company Email Id</p>
              )}
            </Col> */}

            <Col md={6} xs={12} className="pe-3 my-2 position-relative ">
              <label>
                Basic Currency<span className="red-text">*</span>
              </label>
              <select
                className="form-select "
                onChange={(e) => setCurrency(e.target.value)}
                value={currency}
                id="currency"
                disabled={
                  permissions?.includes("EDIT-BASIC INFORMATION") ? false : true
                }
              >
                <option value={""} hidden>
                  Select Currency
                </option>
                {input?.currencies?.map((item) => (
                  <option value={item?.currency_id}>
                    {item?.currency_name}
                  </option>
                ))}
              </select>
              {currency?.length == 0 && currencyErr && (
                <p className="err-input pe-3 d-none" id="currency_err">
                  Select Currency
                </p>
              )}
            </Col>
            <Col md={6} xs={12} className="pe-3 my-2 position-relative ">
              <label>
                Payment Terms<span className="red-text">*</span>
              </label>
              <select
                className="form-select "
                onChange={(e) => setPayTerms(e.target.value)}
                // value={input?.pay_terms?.forEach((data) =>
                //   data.id == payterms ? payterms : ""
                // )}
                value={payterms}
                id="payterms"
                disabled={
                  permissions?.includes("EDIT-BASIC INFORMATION") ? false : true
                }
              >
                <option value={""} hidden>
                  Select Payment Terms
                </option>
                {input?.pay_terms?.map((item) => (
                  <option value={item?.id}>{item?.name}</option>
                ))}
              </select>
              <p className="err-input pe-3 d-none" id="payterms_err">
                Select Pay Terms
              </p>
            </Col>
            <Col md={6} xs={12} className="pe-3 my-2 position-relative ">
              <label>
                Delivery Inco Terms<span className="red-text">*</span>
              </label>
              <select
                className="form-select "
                onChange={(e) => setDeleveryTerms(e.target.value)}
                // value={input?.delivery_types?.forEach((data) =>
                //   data.id == deliveryTerms ? deliveryTerms : ""
                // )}
                value={deliveryTerms}
                id="delivery_inco"
                disabled={
                  permissions?.includes("EDIT-BASIC INFORMATION") ? false : true
                }
              >
                <option value={""} hidden>
                  Select Delivery Inco Terms
                </option>
                {input?.delivery_types?.map((item) => (
                  <option value={item?.id}>{item?.name}</option>
                ))}
              </select>
              <p className="err-input pe-3 d-none" id="delivery_inco_err">
                Select Delivery Inco Terms
              </p>
            </Col>

            {/* <Col md={6} xs={12} className="pe-3 my-2 position-relative">
              <label>
                Map KH Division<span className="red-text">*</span>
              </label>
              <select
                className="form-select "
                onChange={(e) => setMapDivision(e.target.value)}
                value={mapDivision}
              >
                <option> </option>
                <option>Source 1</option>
                <option>Source 2</option>
              </select>
              {mapDivision?.length == 0 && mapDivisionErr && (
                <p className="err-input pe-3">Select Map KS Division</p>
              )}
            </Col> */}

            <Col md={6} xs={12} className="pe-3 my-2 position-relative">
              <label>
                Active Factory List<span className="red-text">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="All Factory Selected"
                value={selecteds?.toString()}
                disabled
              />

              {/* {inActiveList?.length == 0 && inActiveListErr && (
                <p className="err-input pe-3">Enter InActive List</p>
              )} */}
            </Col>
            <Col md={6} xs={12} className="pe-3 my-2 position-relative">
              <label>
                InActive Factory List<span className="red-text">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="All Factory Selected"
                value={selected?.toString()}
                disabled
              />
              {/* {inActiveList?.length == 0 && inActiveListErr && (
                <p className="err-input pe-3">Enter InActive List</p>
              )} */}
            </Col>
            {/* <Col md={6} xs={12} className="pe-3 my-2 position-relative">
              <label>
                InActive List<span className="red-text">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="All Company Selected"
                value={selected?.toString()}
                disabled
              />
              {/* {inActiveList?.length == 0 && inActiveListErr && (
                <p className="err-input pe-3">Enter InActive List</p>
              )} 
            </Col> */}
          </div>
        </Form>
      </div>
      {/* <div className="register-con my-4 ">
        <h2>Payment Terms</h2>
        <Form>
          <textarea
            className="form-control"
            row="3"
            // onChange={(e) => setAboutUs(e.target.value)}
            // value={aboutUs}
          ></textarea>
          {/* {aboutUs?.length == 0 && aboutUsErr && (
            <span>Enter About Your Company</span>
          )} 
        </Form>
      </div> */}
      {/* <div className="register-con my-4 ">
        <h2>Delivery Terms</h2>
        <Form>
          <textarea
            className="form-control"
            row="3"
            // onChange={(e) => setAboutUs(e.target.value)}
            // value={}
          ></textarea>
          {/* {aboutUs?.length == 0 && aboutUsErr && (  
            <span>Enter About Your Company</span>
          )} 
        </Form>
      </div> */}
      <div className="primary-con my-3">
        <h1>Primary Contact</h1>
        <div className="my-3">
          <h6>{list?.SupplierName}</h6>
          {/* <p>Business - Finance</p> */}
          <ul>
            <li>
              <span>Mobile:</span> {list?.PhoneNo}
            </li>
            <li>
              <span>Landline:</span> {list?.Telephone} / {list?.TelephoneExt}
            </li>
            <li>
              <span>Email:</span> {list?.Email}
            </li>
          </ul>
        </div>
      </div>
      <McpRegister />
      <div className="register-con my-4 ">
        <h2>
          About Company <span className="red-text">*</span>
        </h2>
        <div className="d-flex-al-jb my-4 position-relative">
          <p>Tell us about your Company</p>
          {/* <button
            className="rounded-5 border-0 p-2 bg-hash"
            onClick={() => navigate("/editabout")}
          >
            <TbEdit />
          </button> */}
        </div>
        <Form>
          <textarea
            className="form-control"
            row="3"
            onChange={(e) => setAboutUs(e.target.value)}
            value={aboutUs}
            placeholder={"Enter the Description about your company"}
            disabled={
              permissions?.includes("EDIT-BASIC INFORMATION") ? false : true
            }
          ></textarea>
          {aboutUs?.length == 0 && aboutUsErr && (
            <p
              style={{
                float: "right",
                fontSize: "13px",
                color: "#ff0000",
                fontFamily: "f2",
              }}
            >
              Enter About Your Company
            </p>
          )}
        </Form>
      </div>
      {/*social media  */}
      <div className="socials-con my-4 ">
        <h2>Social Media </h2>

        <Form>
          <div className="  my-4">
            <div className="d-flex-al my-2">
              <Col sm={3} xs={12} className="my-2">
                <div className="d-flex-al">
                  <Image src={Facebook} />
                  <h6>Facebook</h6>
                </div>
              </Col>
              <Col sm={8} xs={12} className="my-2 position-relative">
                <input
                  className="form-control"
                  placeholder="Enter Facebook URL"
                  onChange={(e) => setFacebook(e.target.value)}
                  value={facebook}
                  disabled={
                    permissions?.includes("EDIT-BASIC INFORMATION")
                      ? false
                      : true
                  }
                />
                {facebook?.length == 0 && facebookErr && (
                  <span>Enter Facebook URL</span>
                )}
              </Col>
            </div>
            <div className="d-flex-al my-2">
              <Col sm={3} xs={12} className="my-2">
                <div className="d-flex-al">
                  <Image src={TwiterX} />
                  <h6>Twitter</h6>
                </div>
              </Col>
              <Col sm={8} xs={12} className="my-2 position-relative">
                <input
                  className="form-control"
                  placeholder="Enter Twitter URL"
                  onChange={(e) => setTwitter(e.target.value)}
                  value={twiter}
                  disabled={
                    permissions?.includes("EDIT-BASIC INFORMATION")
                      ? false
                      : true
                  }
                />
                {twiter?.length == 0 && twiterErr && (
                  <span>Enter Twitter URL</span>
                )}
              </Col>
            </div>

            <div className="d-flex-al my-2">
              <Col sm={3} xs={12} className="my-2">
                <div className="d-flex-al">
                  <Image src={Instagram} />
                  <h6>Instagram</h6>
                </div>
              </Col>
              <Col sm={8} xs={12} className="my-2 position-relative">
                <input
                  className="form-control"
                  placeholder="Enter Instagram URL"
                  onChange={(e) => setInstagram(e.target.value)}
                  value={instagram}
                  disabled={
                    permissions?.includes("EDIT-BASIC INFORMATION")
                      ? false
                      : true
                  }
                />
                {instagram?.length == 0 && instagramErr && (
                  <span>Enter Instagram URL</span>
                )}
              </Col>
            </div>
            <div className="d-flex-al my-2">
              <Col sm={3} xs={12} className="my-2">
                <div className="d-flex-al">
                  <Image src={Linkedin} />
                  <h6>Linkedin</h6>
                </div>
              </Col>
              <Col sm={8} xs={12} className="my-2 position-relative">
                <input
                  className="form-control"
                  placeholder="Enter LinkedIn URL"
                  onChange={(e) => setLinkedin(e.target.value)}
                  value={linkedin}
                  disabled={
                    permissions?.includes("EDIT-BASIC INFORMATION")
                      ? false
                      : true
                  }
                />
                {linkedin?.length == 0 && linkedinErr && (
                  <span>Enter Linkedin URL</span>
                )}
              </Col>
            </div>
          </div>
        </Form>
      </div>
      {/*social media  */}

      {permissions?.includes("EDIT-BASIC INFORMATION") && (
        <div div className="pe-3 my-2 fnt-fam-f2  d-flex-al-je ben_design">
          <button
            className="text-blue border-0 fnt-fam-f2 wid-9r h-2_5r bg-light"
            onClick={() => navigate("/dashboard")}
          >
            Previous
          </button>
          {/*   <Link to="#" className="btn bode-btn">
          Save as Draft
        </Link> */}
          <Button
            className="btn fnt-fam-f2  sub-btn"
            onClick={submitHandle}
            disabled={buttonEnable == true ? true : false}
          >
            Save & Next
          </Button>
        </div>
      )}
    </div>
  );
};
export default BasicInform;
