import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import TopBar from "../../navbar/TopBar";
import TopHeader from "../../navbar/TopHeader";
import Radio_icon from "../../assets/Icons/Normal Icons/RadioButton.svg";
import { api } from "../../constants/Services";
import ErrorModel from "../../constants/Services/Api/errorModel";
import moment from "moment/moment";
import { AiOutlineFilePdf } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";
import useUser from "../../constants/Storage/userData";

const GstView = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const detail = location?.state?.item;
  const [activeBtn, setActiveBtn] = useState(0);
  const [list, setList] = useState([]);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [btn, setBtn] = useState(false);
  const [taxHeader, setTaxHeader] = useState([]);
  const [GSTCal, setGSTCal] = useState([]);
  const [result, setResult] = useState();
  const [poNo, setPoNo] = useState([]);
  const [poDate, setPoDate] = useState([]);

  const getList = () => {
    api.invoiceView(detail?.id).then((resp) => {
      if (resp?.status == "success") {
        let tempArr = [];

        resp?.invoice_details?.map((item, ind) => {
          tempArr.push({
            id:
              item?.detail_delivery_details
                ?.LeatherPurchaseOrderDetailDelDateID ||
              item?.detail_delivery_details
                ?.MaterialPurchaseOrderDetailDelDateID,
            factory_id: item?.factory_id,
            type: item?.type,
            po_quantity: item?.po_quantity,
            invoice_quantity: item?.invoice_quantity,
            typedQuantity: +item?.qty,
            quantity: item?.detail_delivery_details?.Quantity,
            tax: item?.full_order_detail?.tax?.TaxPercentage
              ? Math.round(item?.full_order_detail?.tax?.TaxPercentage)
              : 0,
            detail_delivery_details: item?.detail_delivery_details,
            full_order_detail: item?.full_order_detail,
            amount:
              +item?.qty * Number(item?.full_order_detail?.Price).toFixed(2),
          });
        });

        // console.log("tempArr", tempArr);

        // OLD
        const sum = {};
        let tax = [];
        // console.log("resp?.invoice_details", resp?.invoice_details);
        for (var i = 0; i < tempArr?.length; i++) {
          const tax = tempArr[i];
          const amount = parseFloat(tax.amount);
          sum[tax.tax] = sum[tax.tax] ? sum[tax.tax] + amount : amount;
        }

        let temp = {};
        let arr = [];
        let taxHeaders = [];
        let totalTableAmount = 2090;
        let isGst = true;

        for (const [key, value] of Object.entries(sum)) {
          let final = (value * +key) / 100;
          temp[+key] = Number(final).toFixed(2);
          arr.push({
            gst_id: +key,
            tax_value: Number(final).toFixed(2),
            value: Number(+value).toFixed(2),
            total: Number(+value + +final).toFixed(2),
          });
        }
        // console.log("arr", arr);

        const subAmount = arr.reduce((a, b) => +a + +b?.value, 0);
        let divider = subAmount / 2;

        taxHeaders.push({
          value: isGst == true ? subAmount : "",
          name: "IGST",
        });

        taxHeaders.push({
          value: isGst !== true ? divider : "",
          name: "CGST",
        });

        taxHeaders.push({
          value: isGst !== true ? divider : "",
          name: "SGST",
        });

        setTaxHeader(taxHeaders);
        let poNoArr = [];
        let poDateArr = [];
        resp?.po_details?.map((item, ind) => {
          poNoArr.push(
            item?.purchase_order?.LeatherPurchaseOrderNo ||
              item?.purchase_order?.MaterialPurchaseOrderNo
          );

          poDateArr.push(
            moment(
              item?.purchase_order?.LeatherPurchaseOrderDate ||
                item?.purchase_order?.MaterialPurchaseOrderDate
            ).format("YYYY-MM-DD")
          );
        });
        setPoNo(poNoArr);
        setPoDate(poDateArr);
        setList(resp);

        const taxAmountValue = arr.reduce((a, b) => +a + +b?.tax_value, 0);

        setResult(Number(taxAmountValue).toFixed(2));
        // console.log("subAmount", subAmount);
        setGSTCal(arr);

        let documents = [];

        resp?.invoice_filling_documents?.map((item, ind) => {
          documents.push(item?.document_url);
        });

        setDocuments(documents);
      } else {
        setError(resp);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    if (detail?.id) {
      getList();
    } else {
      navigate(-1);
    }
  }, []);

  const onChangeUpload = (e) => {
    let max_length = 3;
    if (Array.from(e.target.files).length > max_length) {
      e.preventDefault();
      alert(`Cannot upload files more than ${max_length}`);
    } else {
      let temp = [...e.target.files];
      let upload_view = [];
      let upload = [];
      temp?.map((url) => {
        upload_view.push({
          url: URL.createObjectURL(url),
          name: url?.name,
          id: "",
          type: "edit",
        });
        upload.push(url);
      });
      setDocuments(upload);
      // setimageUrl((upload_edit) => [...upload_edit, ...upload_view]);
    }
  };

  const removeImage = (id, index) => {
    let temp = [...documents];
    temp.splice(index, 1);
    temp.splice(index, 1);
    setDocuments(temp);
  };

  const submitHandle = () => {
    let formdata = new FormData();
    formdata.append("invoice_id", detail?.id);
    formdata.append("gst_fine", activeBtn);
    formdata.append("status", activeBtn == 0 ? 1 : activeBtn == 1 ? 0 : 0);

    if (activeBtn == 0 && documents?.length > 0) {
      documents?.map((item, ind) => {
        if (item?.name) {
          formdata.append(`documents[${ind}]`, item);
        }
      });
    }

    setBtn(true);
    api.invoiceFine(formdata).then((res) => {
      if (res?.status == "success") {
        navigate(-1);
      } else {
        setError(res);
        setErrorBox(true);
      }
      setBtn(false);
    });
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  // console.log("GSTCal", GSTCal);

  return (
    <div>
      <TopBar />
      <TopHeader type="invoice-gst" />
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <div
        style={{ minHeight: "80vh" }}
        className="enquiry-list-cont my-3 py-2 position-relative"
      >
        <div className="d-flex flex-wrap">
          <Col md={3} lg={3} sm={12}>
            <div className="d-flex mt-2">
              <p className="lft-rit primary1 mb-0" style={{ color: "#8f959d" }}>
                Invoice ID{" "}
              </p>
              <p className="mb-0 primary1">:</p>
              <p className="lft-rit1 text-dark mb-0 mx-1">
                {list?.invoice?.id}
              </p>
            </div>
          </Col>
          <Col md={3} lg={3} sm={12}>
            <div className="d-flex mt-2">
              <p className="lft-rit primary1 mb-0" style={{ color: "#8f959d" }}>
                Invoice Date{" "}
              </p>
              <p className="mb-0 primary1">:</p>
              <p className="lft-rit1 text-dark mb-0 mx-1">
                {list?.invoice?.invoice_date
                  ? moment(list?.invoice?.invoice_date).format("DD-MMM-YYYY")
                  : "-"}
              </p>
            </div>
          </Col>

          <Col md={3} lg={3} sm={12}>
            <div className="d-flex mt-2">
              <p className="lft-rit primary1 mb-0" style={{ color: "#8f959d" }}>
                PO No{" "}
              </p>
              <p className="mb-0 primary1">:</p>
              <p className="lft-rit1 text-dark mb-0 mx-1">{poNo}</p>
            </div>
          </Col>
          <Col md={3} lg={3} sm={12}>
            <div className="d-flex mt-2">
              <p className="lft-rit primary1 mb-0" style={{ color: "#8f959d" }}>
                PO Date{" "}
              </p>
              <p className="mb-0 primary1">:</p>
              <p className="lft-rit1 text-dark mb-0 mx-1">{poDate}</p>
            </div>
          </Col>
        </div>
        <div className="d-flex flex-wrap">
          <Col md={3} lg={3} sm={12}>
            <div className="d-flex mt-2">
              <p className="lft-rit primary1 mb-0" style={{ color: "#8f959d" }}>
                Invoice Value
              </p>
              <p className="mb-0 primary1">:</p>
              <p className="lft-rit1 text-dark mb-0 mx-1">
                {list?.invoice?.tax_total_amount
                  ? Number(list?.invoice?.tax_total_amount).toFixed(2)
                  : "-"}
              </p>
            </div>
          </Col>
          <Col md={3} lg={3} sm={12}>
            <div className="d-flex mt-2">
              <p className="lft-rit primary1 mb-0" style={{ color: "#8f959d" }}>
                Payment Stage
              </p>
              <p className="mb-0 primary1">:</p>
              <p className="lft-rit1 text-dark mb-0 mx-1">
                {+list?.invoice?.invoice_stage == 1
                  ? "Advanced"
                  : +list?.invoice?.invoice_stage == 2
                  ? "Partial"
                  : +list?.invoice?.invoice_stage == 3
                  ? "Final"
                  : "-"}
              </p>
            </div>
          </Col>
        </div>
        {/* <Col className="mb-3">
          <div className="rit-ad-prod mt-3">
            <h5 className="title py-2">GST Summary</h5>
            <div className="table-scroll-horizontal over-flow-x-scroll">
              <div className="d-flex table-scroll-horizontal over-flow-x-scroll mb-2 w-100">
                <Col xs={8} className="py-2 d-flex w-100 m-0">
                  <div className="d-flex gap-3 p-0 quo-scope-btm-tb-2nd-cont w-100">
                    <div className="d-flex quotation-nd-table-cont">
                      <table className="mt-3 cont-border py-2 mb-0 bor-right table w-100">
                        {taxHeader?.map((item, ind) => {
                          return (
                            <tr
                              className="fnt-fam-f3 opac_half table-bordered text-center"
                              style={{ borderWidth: "1px" }}
                            >
                              <th
                                className="text-nowrap"
                                style={{ padding: "5px" }}
                              >
                                {item?.name}
                              </th>
                              <td style={{ padding: "5px" }}>
                                <p className="opac-80">
                                  {item?.value
                                    ? Number(item?.value)
                                        .toFixed(2)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    : "-"}
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                    <div className=" p-0 quo-scope-btm-tb-2nd-cont  w-100">
                      <div className="quotation-nd-table-cont">
                        <table className="mt-3 cont-border py-2 mb-0 bor-right table">
                          <thead>
                            <tr className="fnt-fam-f3 opac_half table-bordered">
                              <th className="fs-14px text-center text-nowrap">
                                GST %
                              </th>
                              <th className="fs-14px text-center text-nowrap">
                                Taxable Value
                              </th>
                              <th className="fs-14px text-center text-nowrap">
                                Tax Value
                              </th>
                            </tr>
                          </thead>

                          <tbody className="opac-80">
                            {GSTCal?.map((item, ind) => {
                              return (
                                <tr>
                                  <td className="text-center">{"18"}</td>
                                  <td className="text-center">{"200"}</td>
                                  <td className="text-center">{"475"}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <table className="d-flex justify-content-end light-grey-bg py-2">
                          <tbody>
                            <tr>
                              <td className="d-flex gap-2 fw-bold px-2">
                                <span>Total Tax : </span>{" "}
                                <span>
                                  {"983268"} {"INR"}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>{" "}
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </Col> */}
        <Col>
          <div className="d-flex table-scroll-horizontal over-flow-x-scroll mb-2 w-100">
            {+user?.supplier?.CountryID == 1 && (
              <Col xs={8} className="py-2 d-flex w-100 m-0">
                <div className="d-flex gap-3 p-0 quo-scope-btm-tb-2nd-cont w-100">
                  <div className="d-flex quotation-nd-table-cont">
                    <table className="mt-3 cont-border py-2 mb-0 bor-right table w-100">
                      {taxHeader?.map((item, ind) => {
                        return (
                          <tr
                            className="fnt-fam-f3 opac_half table-bordered text-center"
                            style={{ borderWidth: "1px" }}
                          >
                            <th
                              className="text-nowrap"
                              style={{ padding: "5px" }}
                            >
                              {item?.name}
                            </th>
                            <td style={{ padding: "5px" }}>
                              <p className="opac-80">
                                {item?.value
                                  ? Number(item?.value)
                                      .toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : "-"}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                  <div className=" p-0 quo-scope-btm-tb-2nd-cont  w-100">
                    <div className="quotation-nd-table-cont">
                      <table className="mt-3 cont-border py-2 mb-0 bor-right table">
                        <thead>
                          <tr className="fnt-fam-f3 opac_half table-bordered">
                            <th className="fs-14px text-center text-nowrap">
                              GST %
                            </th>
                            <th className="fs-14px text-center text-nowrap">
                              Taxable Value
                            </th>
                            <th className="fs-14px text-center text-nowrap">
                              Tax Value
                            </th>
                          </tr>
                        </thead>

                        <tbody className="opac-80">
                          {GSTCal?.map((item, ind) => {
                            return (
                              <tr key={ind}>
                                <td className="text-center">{item?.gst_id}</td>
                                <td className="text-center">{item?.value}</td>
                                <td className="text-center">
                                  {item?.tax_value}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <table className="d-flex justify-content-end light-grey-bg py-2">
                        <tbody>
                          <tr>
                            <td className="d-flex gap-2 fw-bold px-2">
                              <span>Total Tax : </span>{" "}
                              <span>
                                {result ? result : ""}{" "}
                                {/* {currencyDisplay?.currency_code} */}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>{" "}
                    </div>
                  </div>
                </div>
              </Col>
            )}
          </div>
        </Col>
        <Col>
          <div className="mt-3 rit-ad-prod">
            <div
              className={
                type == "view" ? "d-flex mt-2" : "d-flex flex-column mt-2"
              }
            >
              <p className="lft-rit primary1 mb-0" style={{ color: "#8f959d" }}>
                GST Fined Status{" "}
                {type !== "view" && <span className="text-danger">*</span>}
              </p>

              {type == "view" ? (
                <>
                  <p className="mb-0 primary1">:</p>
                  <p className="lft-rit1 text-dark mb-0 mx-1">test</p>
                </>
              ) : (
                <div className="d-flex align-items-center mx-3 mx-lg-0 mt-2 mb-3 mt-lg-0">
                  {["Yes", "No"]?.map((item, index) => {
                    return (
                      <button
                        onClick={() => setActiveBtn(index)}
                        className="d-flex align-items-center border-0 bg-transparent p-0 me-4"
                      >
                        <div className="radio-btn">
                          {activeBtn == index ? (
                            <img src={Radio_icon} className="wid-15px" />
                          ) : (
                            <div
                              className="radio-btn-inner"
                              style={{
                                background: "#fff",
                              }}
                            ></div>
                          )}
                        </div>
                        <p className="me-1" style={{ fontSize: "14px" }}>
                          {item}
                        </p>
                      </button>
                    );
                  })}
                </div>
              )}
            </div>
            <h5 className="title  py-2">GST Filled Proof Document</h5>
            <Col xs={12} style={{ paddingLeft: "3px" }}>
              {activeBtn == 0 && type !== "view" && (
                <div>
                  {" "}
                  <input
                    className="custom-file-input-1"
                    type="file"
                    multiple
                    onChange={onChangeUpload}
                  />
                  <h5 className="primary1 note py-2">
                    Note: Format: jpg, jpeg, png. Upload upto 5MB each file
                    allowed to upload maximum of 5 Uploads
                  </h5>
                </div>
              )}

              {documents?.map((item, ind) => (
                <div className="d-flex gap-1 my-4" key={ind}>
                  <div className="d-flex align-items-center pdf-icon-cont">
                    <AiOutlineFilePdf />
                  </div>
                  <p className="d-flex align-items-center">
                    {"Uploaded Files"}
                  </p>
                  <TiDeleteOutline onClick={() => removeImage(ind)} />
                </div>
              ))}
            </Col>
          </div>
        </Col>
        <Col
          className="d-flex mt-3 justify-content-end px-2 mb-4"
          xs={12}
          md={12}
          lg={10}
        >
          <button
            className="cust-btn bg-lit-primary4 me-2"
            style={{ background: "#f3f3f3" }}
            onClick={() => navigate(-1)}
          >
            Clear
          </button>
          {type !== "view" && (
            <button
              className="cust-btn text-white bg-primary1"
              onClick={submitHandle}
              disabled={btn == true ? true : false}
            >
              Submit
            </button>
          )}
        </Col>
      </div>
    </div>
  );
};

export default GstView;
