// @ts-nocheck
import POFilter from "../../component/PurchaseOrder/POFilter";
import GeneralPagination from "../../component/GeneralPagination";
import POCard from "../../component/PurchaseOrder/POCard";
import Filter from "../../assets/Icons/Normal Icons/Filter.svg";
import Search from "../../assets/Icons/Normal Icons/Search.svg";
import TopHeader from "../../navbar/TopHeader";
import TopBar from "../../navbar/TopBar";
import { Col, Modal, Image } from "react-bootstrap";
import LogInFooter from "../../logincomponent/LogInFooter";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GRNTableContent from "./GRN/GRNTableContent";
import POList from "../../component/PurchaseOrder/POList";
import Select from "react-select";
import { api } from "../../constants/Services";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Radio_icon from "../../assets/Icons/Normal Icons/RadioButton.svg";
import { FavLogo, Check } from "../../assets/img";
import { FaEllipsisV } from "react-icons/fa";
import ErrorModel from "../../constants/Services/Api/errorModel";

function PurchaseOrderList() {
  const navigate = useNavigate();
  const [showFilterContent, setShowFilterContent] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [count, setCount] = useState(0);
  const permission = localStorage.getItem("permissions");
  const [dropShow, setDropShow] = useState(false);
  const [showInd, setShowInd] = useState();
  const [openCard, setOpenCard] = useState(false);
  const [activeBtn, setActiveBtn] = useState(1);
  const [submitAck, setSubmitAck] = useState(false);
  const [showInvModal, setShowInvModal] = useState(false);
  const [showInvModals, setShowInvModals] = useState(false);
  const [showComInvModal, setShowComInvModal] = useState(false);
  const [showComInvModals, setShowComInvModals] = useState(false);
  const [list, setList] = useState([]);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [btn, setbtn] = useState(false);
  const [checkboxs, setcheckboxs] = useState([]);
  const [pages, setPages] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [search, setSearch] = useState();
  const [activePages, setActivePages] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [awardedByFilter, setAwardedByFilter] = useState([]);

  // NEW FILTER

  const [pur_type, setPur_type] = useState();
  const [pur_status, setPur_status] = useState();
  const [pur_date, setPur_date] = useState();
  const [Proforma, setProforma] = useState([]);
  const [commercial, setCommercial] = useState([]);

  const dropHandler = (e) => {
    setShowInd(e);
    if (e == showInd) {
      setShowInd();
      // console.log("1");
    }
  };

  const dropDownMenuClickHandler = () => {
    setShowInd();
    setOpenCard(true);
  };

  // PROFORMA

  const invSelHandler = (item) => {
    setProforma(item);
    setShowInvModal(true);
    setDropShow(false);
  };

  const invSelHandlers = () => {
    setShowInvModals(true);
    setDropShow(false);
  };

  // COMMERCIALS

  const invComHandler = (item) => {
    setCommercial(item);
    setShowComInvModal(true);
    setDropShow(false);
  };

  const invComHandlers = () => {
    setShowComInvModals(true);
    setDropShow(false);
  };

  const POCardCloseHandler = () => setOpenCard(false);
  const ackSubmitHandler = () => {
    setSubmitAck(true);
    setOpenCard(false);
  };

  // console.log("commercial", commercial);

  const getList = () => {
    let params = `?page=${pages}`;

    if (search) {
      setPageCount(1); 
      params = params + `&search=${search}`;
    }

    setbtn(true);
    api.purchaseOrderList(params).then((res) => {
      let status = [];
      let awardedBy = [];
      if (res?.status == "success") {
        // setActivePages(res);
        setList(res?.purchase_orders);
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);
        setActivePages(res);
        // setList(res?.purchase_orders);
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);

        res?.purchase_orders?.map((item, ind) => {
          status.push(item?.Sup_Approval);
          awardedBy.push({
            FactoryID: item?.FactoryID,
            FactoryName: item?.FactoryName,
          });
        });
        // status
        const idstatus = status.map((Sup_Approval) => Sup_Approval);
        const filteredstatus = status.filter(
          (Sup_Approval, index) => !idstatus.includes(Sup_Approval, index + 1)
        );
        setStatusFilter(filteredstatus);
        // award by
        const idsaward = awardedBy.map(({ FactoryID }) => FactoryID);
        const filteredaward = awardedBy.filter(
          ({ FactoryID }, index) => !idsaward.includes(FactoryID, index + 1)
        );
        setAwardedByFilter(filteredaward);
        setbtn(false);
        setShowFilterContent(false);
      } else {
        setError(res);
        setbtn(false);
        setErrorBox(true);
        setShowFilterContent(false);
      }
    });
  };

  const getFilter = () => {
    let params = `?page=${pages}`;

    if (pur_type) {
      params = params + `&type=${pur_type}`;
    }

    if (pur_status) {
      params = params + `&status=${pur_status}`;
    }

    if (pur_date) {
      params = params + `&factory_name=${pur_date}`;
    }

    setbtn(true);
    api.purchaseOrderList(params).then((res) => {
      let temp = [];
      if (res?.status == "success") {
        setList(res?.purchase_orders);
        setbtn(false);

        res?.purchase_orders?.map((item, i) => {
          temp.push({
            type: item?.type == "M" ? "Material" : "Leather",
            purchase_order_id: item?.purchase_order_id,
            purchase_order_no: item?.purchase_order_no,
            purchase_order_date: moment(item?.purchase_order_date).format(
              "MM/DD/YYYY"
            ),
            total_price: parseInt(item.total_price).toFixed(2),
            FactoryName: item?.FactoryName,
            Approval: item?.Approval,
            AmnNo: item?.AmnNo,
            FactoryID: item?.FactoryID,
            Price: item?.Price,
            sub_type: item?.type,
          });
        });
        setShowFilterContent(false);
        setActivePages(res);
        // setList(res?.purchase_orders);
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);
      } else {
        setError(res);
        setbtn(false);
        setErrorBox(true);
        setShowFilterContent(false);
      }
    });
  };

  const handlePageChange = (selectedObject) => {
    setPages(selectedObject.selected + 1);
    setCount(selectedObject.selected);
  };

  const [unique, setUnique] = useState([]);

  const changeNoPo = (event, ind) => {
    let temps = [...checkboxs];
    const value = event;

    const index = temps.indexOf(event);

    if (temps.includes(value)) {
      temps.splice(index, 1);
      setcheckboxs(temps);
    } else {
      temps.push(value);
      setcheckboxs(temps);
    }

    // setUnique(temps[0]?.sub_type);
    setUnique("L");

    console.log(temps);
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const POUploadHandler = () => {
    navigate("/invoice/create", {
      state: { checkboxs: checkboxs, method: "proforma" },
    });
  };

  const clearData = () => {
    setPur_date("");
    setPur_status("");
    setPur_type("");
    getList();
    setShowFilterContent(false);
  };

  useEffect(() => {
    getList();
  }, [pages, search]);

  const data = [
    {
      type: "warning",
    },
    {
      type: "Success",
    },
    {
      type: "danger",
    },
  ];

  const POFilterSubmitHandler = () => setShowFilterContent(false);
  const onClickFilterHandler = () => setShowFilterContent(true);

  const options = [
    { value: "Delivery Challan", label: "Delivery Challan" },
    { value: "Proforma Invoice", label: "Proforma Invoice" },
    { value: "Commercial Invoice", label: "Commercial Invoice" },
  ];

  const dropNavigateHandler = (e) => {
    let target = e.value;

    switch (target) {
      case "Proforma Invoice":
        if (checkboxs?.length > 0) {
          invSelHandlers();
        }

        break;
      case "Delivery Challan":
        let dataBox = checkboxs?.filter((e) => e.type == "L");
        let dataBoxs = checkboxs?.filter((e) => e.type == "M");
        if (dataBox?.length > 0 && dataBoxs?.length > 0) {
          alert("You Cannot Pass Material & Leather");
        } else {
          if (checkboxs?.length > 0) {
            let temp = [1];

            // if (
            //   checkboxs?.filter((i) => !temp?.includes(i?.dcCreated)).length ==
            //   0
            // ) {
            //   alert("DC Already Created");
            //   // navigate("/dc", { state: { checkboxs: checkboxs, type: "add" } });
            // } else {
            navigate("/dc", { state: { checkboxs: checkboxs, type: "add" } });
            // }
          }
        }
        break;
      case "Commercial Invoice":
        if (checkboxs?.length > 0) {
          invComHandlers();
        }
        // navigate("/invoice");
        break;
      default:
        break;
    }
    setSelectedOption(target);
    // console.log(
    // "🚀 ~ file: POList.js:38 ~ dropNavigateHandler ~ target:",
    // target
    // );
  };
  const renderedCards = data.map((card, ind) => {
    return <POCard key={ind} type={card.type} />;
  });

  const renderedRadioButtons = data.map((item, index) => {
    return (
      <button
        key={index}
        onClick={() => setActiveBtn(index)}
        className="d-flex align-items-center border-0 bg-transparent p-0 text-nowrap po-card-radio-btn"
      >
        <div className="radio-btn">
          {activeBtn == index ? (
            <img src={Radio_icon} className="wid-15px" />
          ) : (
            <div
              className="radio-btn-inner"
              style={{
                background: "#fff",
              }}
            ></div>
          )}
        </div>
        {item.terms}
      </button>
    );
  });

  return (
    <>
      <TopBar />
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopHeader type="purchase-order-list" />
      <div
        style={{ minHeight: "70vh" }}
        className="enquiry-list-cont py-2 position-relative"
      >
        <div className="d-flex flex-wrap">
          {/* <POFilter setShowFilterContent={setShowFilterContent} /> */}
          <div className="d-sm-flex justify-content-between mb-2 align-items-center w-100">
            {/* <div className="d-flex-wrap align-items-center gap-2 mb-0">
              <label>Show</label>
              <select name="" className="cont-border px-2 py-1">
                <option value="5">6</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
              <label>1-5 of 20</label>
            </div> */}
            <div className="action-page-cont">
              <p>Showing</p>
              <div className="page-status-cont">
                <p
                  style={{
                    border: "1px solid #ced4da",
                    padding: "4px",
                    margin: "10px 0px",
                    textAlign: "center",
                    width: "35px",
                    height: "28px",
                  }}
                >
                  {activePages?.purchase_orders?.length}
                </p>
              </div>
              <p>of {activePages?.pagination?.total} Purchase Orders</p>
            </div>

            <div className="d-flex mt-2 ms-auto mt-sm-0">
              <div className="search-box d-flex gap-2 align-items-center px-2 rounded-1">
                <input
                  placeholder="Search PO.Ref.No"
                  className="w-90 h-90"
                  type={"number"}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                <img className="search-icon" src={Search} alt="search" />
              </div>
              <button
                className="filter-btn bg-primary2"
                onClick={onClickFilterHandler}
              >
                <img src={Filter} className="wid-15px me-1" alt="filter" />
                Filter
              </button>
              <div className="ms-md-2" style={{ minWidth: "200px" }}>
                <Select
                  defaultValue={selectedOption}
                  onChange={(e) => dropNavigateHandler(e)}
                  options={options}
                  placeholder={"Create"}
                />
              </div>
            </div>
          </div>
        </div>
        {/* {renderedCards} */}
        {/* <POList /> */}
        <div
          style={{ minHeight: "70vh" }}
          className="table-scroll-horizontal over-flow-x-scroll my-2  "
        >
          <div className="min-ht-80">
            <table className="mb-1 py-1 table">
              <thead style={{ background: "#ededed" }}>
                <tr className="fnt-fam-f3 opac-85 border-0 text-center">
                  <th className="fs-14px" style={{ width: "90px" }}>
                    Sl.No.
                  </th>
                  <th className="text-nowrap fs-14px">PO. Type</th>
                  {/* <th className="text-nowrap fs-14px">PO. ID</th> */}
                  <th className="text-nowrap fs-14px">PO. Ref. No</th>
                  <th className="fs-14px text-nowrap">Amdt. No.</th>
                  <th className="text-nowrap fs-14px">PO. Date</th>
                  <th className="fs-14px text-nowrap">PO. Value</th>
                  <th className="fs-14px text-nowrap">Factory</th>
                  <th className="fs-14px text-nowrap text-center">Status</th>
                  <th className="fs-14px text-nowrap text-center">Action</th>
                </tr>
              </thead>
              <tbody className="fs-6 opac-80 text-center">
                {list?.map((item, ind) => {
                  return (
                    <tr key={ind}>
                      <td>
                        <div className="d-flex gap-1">
                          <div>
                            {item?.Sup_Approval == "D " ? null : (
                              <input
                                type="checkbox"
                                className="me-1"
                                onClick={() => changeNoPo(item, ind)}
                                // disabled={!unique.includes(unique)}
                              />
                            )}
                          </div>
                          <div>
                            {ind + 1 == 10 ? count + 1 : count}
                            {ind + 1 == 10 ? 0 : ind + 1}
                          </div>
                        </div>
                      </td>
                      <td>
                        {item?.type == "M"
                          ? "Material"
                          : item?.type == "L"
                          ? "Leather"
                          : " "}
                      </td>
                      {/* <td>
                        {item?.purchase_order_id || item?.purchase_order_id}
                      </td> */}
                      <td>
                        {item?.purchase_order_no || item?.purchase_order_no}
                      </td>
                      <td>{item?.AmnNo}</td>

                      <td>
                        {item?.purchase_order_date
                          ? moment(item?.purchase_order_date).format(
                              "DD-MMM-YYYY"
                            )
                          : ""}
                      </td>
                      {/* <td style={{ minWidth: "305px" }}>
                  <Link
                    type="enquiry-view"
                    // to="/purchase-order/view"
                    className="pointer-cur"
                  >
                    <p>{item?.title}</p>
                  </Link>
                </td> */}
                      <td>
                        {Number(item?.total_price).toFixed(2) ||
                          Number(item?.total_price).toFixed(2)}
                      </td>
                      {/* <td></td> */}
                      <td>{item?.FactoryName}</td>
                      <td
                        // style={{ maxWidth: "125px", wordWrap: "normal" }}
                        className={
                          item?.Sup_Approval == "A "
                            ? "text-success"
                            : item?.Sup_Approval == "P "
                            ? "text-warning"
                            : item?.Sup_Approval == "D "
                            ? "text-danger"
                            : item?.Sup_Approval == "C "
                            ? "text-success"
                            : "text-warning"
                        }
                      >
                        {item?.Sup_Approval == "A "
                          ? "Accepted"
                          : item?.Sup_Approval == "P "
                          ? "Yet to Acknowledge"
                          : item?.Sup_Approval == "D "
                          ? "Declined"
                          : item?.Sup_Approval == "C "
                          ? "Accepetd"
                          : "Yet to Acknowledge"}
                      </td>
                      {item?.status == "Inprogress" && (
                        <td className="text-center">
                          <p
                            style={{ border: "1px solid #0d6efd" }}
                            className="p-1 rounded-0 text-primary"
                          >
                            {item?.status}
                          </p>
                        </td>
                      )}
                      {item?.status == "Completed" && (
                        <td className="text-center">
                          <p
                            style={{ border: "1px solid #e74c3c" }}
                            className="p-1 rounded-0 text-danger"
                          >
                            {item?.status}
                          </p>
                        </td>
                      )}
                      <td className="position-relative text-center">
                        <button
                          onClick={() => dropHandler(ind)}
                          className="cust-btn dropdown-container-tool pt-1"
                        >
                          <FaEllipsisV />
                        </button>
                        {ind == showInd && (
                          <div
                            style={{ zIndex: 2 }}
                            className="po-card-drop-down1 text-start d-flex flex-column p-3 drop-downs"
                          >
                            {permission?.includes("VIEW-PURCHASEORDER") && (
                              <button
                                onClick={() =>
                                  navigate("/purchase-order/view", {
                                    state: { data: item },
                                  })
                                }
                                className="asn_views pb-1"
                                style={
                                  item?.Sup_Approval == "D "
                                    ? { width: "100px" }
                                    : item?.Sup_Approval == "P "
                                    ? { width: "100px" }
                                    : item?.Sup_Approval == null
                                    ? { width: "100px" }
                                    : null
                                }
                              >
                                View PO
                              </button>
                            )}
                            {item?.Sup_Approval ==
                            "D " ? null : item?.Sup_Approval ==
                              "P " ? null : item?.Sup_Approval ==
                              null ? null : (
                              <>
                                {" "}
                                {item?.asnCreated == 1
                                  ? null
                                  : permission?.includes(
                                      "ADD-ADVANCESHIPPINGNOTE"
                                    ) && (
                                      <button
                                        onClick={() =>
                                          navigate("/asn", {
                                            state: { data: item, type: "add" },
                                          })
                                        }
                                        className="asn_views pb-1"
                                      >
                                        Create ASN
                                      </button>
                                    )}
                                {/* <button
                              className=" text-nowrap pb-1"
                              onClick={dropDownMenuClickHandler}
                            >
                              Acknowledge PO
                            </button> */}
                                {permission?.includes("ADD-INVOICE") && (
                                  <button
                                    className="asn_views pb-1"
                                    onClick={() => invSelHandler(item)}
                                  >
                                    Create Proforma Invoice
                                  </button>
                                )}
                                {/* {item?.dcCreated == 1 */}
                                {item?.dcCreated == 10
                                  ? null
                                  : permission?.includes(
                                      "ADD-DELIVERYCHALLAN"
                                    ) && (
                                      <button
                                        onClick={() =>
                                          navigate("/dc", {
                                            state: { data: item, type: "add" },
                                          })
                                        }
                                        className="asn_views pb-1"
                                      >
                                        Create Delivery Challan
                                      </button>
                                    )}
                                {permission?.includes("ADD-INVOICE") && (
                                  <button
                                    className="asn_views text-nowrap py-1"
                                    onClick={() => invComHandler(item)}
                                  >
                                    Create Commercial Invoice
                                  </button>
                                )}
                                {/* {permission?.includes(
                                  "LIST-DELIVERYCHALLAN"
                                ) && (
                                  <button
                                    onClick={() =>
                                      navigate(
                                        "/dc-list",
                                        {
                                          state: { data: item },
                                        }
                                      )
                                    }
                                    className="asn_views py-1"
                                  >
                                    Update Shipping Tracks
                                  </button>
                                )} */}
                              </>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <Modal
            id="#po-card-acknowledge"
            className="po-acknoweld-card-cont px-3 fnt-fam-f2  pt-3 pb-1"
            show={openCard}
            size="md"
            centered
            onHide={() => setOpenCard(false)}
            aria-labelledby="contained-modal-title-vcenter"
          >
            <div className=" d-flex justify-content-between pb-2 mt-3">
              <h5 className="primary1 fs-1r">Acknowledgement</h5>
              <span onClick={POCardCloseHandler}> X </span>
            </div>
            <div className="d-flex flex-wrap gap-3 mb-2">
              {renderedRadioButtons}
            </div>
            <label className="my-3">
              Comments<span className="red-text">*</span>
            </label>
            <p className="border-btm">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu
              lacinia massa. Donec at diam sed mauris sollicitudin dignissim
              placerat eu nisl. Curabitur faucibus consequat sodales.
            </p>
            <p className="d-flex justify-content-end opac-85">Max 250</p>
            <div className="d-flex mt-4 mb-1 justify-content-end gap-3">
              <button
                className="clear-can-btn border-0 rounded-1 fnt-fam-f1"
                style={{ color: "black" }}
              >
                Clear
              </button>
              <button
                className="bg-primary1 bg-primary1 sub-app-btn text-white rounded-1 border-0 fnt-fam-f1"
                onClick={ackSubmitHandler}
              >
                Submit
              </button>
            </div>
          </Modal>

          <Modal
            id="#sucess"
            className="otp-modal"
            show={submitAck}
            size="md"
            centered
            onHide={() => setSubmitAck(false)}
            aria-labelledby="contained-modal-title-vcenter"
          >
            <div className="sup-modal ">
              <Image src={FavLogo} />
              <div className="mt-2">
                <div className="d-flex-as-jc img_tick ">
                  <Image src={Check} />
                  <div className="px-3">
                    <p>Do you want to Submmit the Acknowledge ?</p>
                  </div>
                </div>
                <div className="d-flex flex-row-reverse">
                  <div className="row">
                    <div className="col-sm">
                      <Link
                        style={{
                          backgroundColor: "#D1D3D4",
                          width: "70px",
                          height: "30px",
                        }}
                        className="my-3 modal_btn"
                        to="#"
                        onClick={() => {
                          setSubmitAck(false);
                        }}
                      >
                        No
                      </Link>
                    </div>
                    <div className="col-sm">
                      <Link
                        style={{
                          width: "70px",
                          height: "30px",
                        }}
                        className="my-3 modal_btn"
                        to="#"
                        onClick={() => {
                          setSubmitAck(false);
                        }}
                      >
                        Yes
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          {/* SINGLE COMMERCIAL */}

          <Modal
            id="#sucess"
            className="proforma-com-inv"
            show={showComInvModal}
            size="md"
            centered
            onHide={() => setShowComInvModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Image
              style={{ width: "10%" }}
              className="ps-2 ms-1 mt-2 pt-1"
              src={FavLogo}
            />
            <div className="sup-modal ">
              <div>
                <div className="d-flex-as-jc img_tick ">
                  {/* <Image src={Check} />  */}
                  <div className="px-3 mb-2 pb-1">
                    <p style={{ fontSize: "12px" }}>
                      How do you want to create the Commercial Invoice?
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row-reverse">
                  <div className="row">
                    <div className="col-sm">
                      <button
                        style={{
                          minWidth: "70px",
                          minHeight: "30px",
                          fontSize: "revert",
                        }}
                        className="my-3 modal_btn"
                        onClick={() =>
                          navigate("/invoice/commercial/create", {
                            state: {
                              commercial: commercial,
                              method: "commercial",
                            },
                          })
                        }
                      >
                        By Upload
                      </button>
                    </div>
                    <div className="col-sm">
                      <button
                        style={{
                          minWidth: "70px",
                          minHeight: "30px",
                          fontSize: "revert",
                        }}
                        className="my-3 modal_btn"
                        onClick={() =>
                          navigate("/invoice", {
                            state: {
                              commercial: commercial,
                              method: "commercial",
                            },
                          })
                        }
                      >
                        By Create
                      </button>
                    </div>
                    <div className="col-sm">
                      <Link
                        style={{
                          backgroundColor: "#D1D3D4",
                          minWidth: "70px",
                          minHeight: "30px",
                          fontSize: "small",
                        }}
                        className="my-3 modal_btn"
                        to="#"
                        onClick={() => {
                          setShowComInvModal(false);
                        }}
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          {/* MULTIPLE COMMERCIAL */}

          <Modal
            id="#sucess"
            className="proforma-com-inv"
            show={showComInvModals}
            size="md"
            centered
            onHide={() => setShowComInvModals(false)}
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Image
              style={{ width: "10%" }}
              className="ps-2 ms-1 mt-2 pt-1"
              src={FavLogo}
            />
            <div className="sup-modal ">
              <div>
                <div className="d-flex-as-jc img_tick ">
                  {/* <Image src={Check} />  */}
                  <div className="px-3 mb-2 pb-1">
                    <p style={{ fontSize: "12px" }}>
                      How do you want to create the Commercial Invoice?
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row-reverse">
                  <div className="row">
                    <div className="col-sm">
                      <button
                        style={{
                          minWidth: "70px",
                          minHeight: "30px",
                          fontSize: "revert",
                        }}
                        className="my-3 modal_btn"
                        onClick={() =>
                          checkboxs?.length > 0
                            ? navigate("/invoice/commercial/create", {
                                state: {
                                  checkboxs: checkboxs,
                                  method: "commercial",
                                },
                              })
                            : ""
                        }
                      >
                        By Upload
                      </button>
                    </div>
                    <div className="col-sm">
                      <button
                        style={{
                          minWidth: "70px",
                          minHeight: "30px",
                          fontSize: "revert",
                        }}
                        className="my-3 modal_btn"
                        onClick={() =>
                          checkboxs?.length > 0
                            ? navigate("/invoice", {
                                state: {
                                  checkboxs: checkboxs,
                                  method: "commercial",
                                },
                              })
                            : ""
                        }
                      >
                        By Create
                      </button>
                    </div>
                    <div className="col-sm">
                      <Link
                        style={{
                          backgroundColor: "#D1D3D4",
                          minWidth: "70px",
                          minHeight: "30px",
                          fontSize: "small",
                        }}
                        className="my-3 modal_btn"
                        to="#"
                        onClick={() => {
                          setShowComInvModals(false);
                        }}
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          {/* SINGLE PROFORMA */}

          <Modal
            id="#sucess"
            className="proforma-com-inv"
            show={showInvModal}
            size="md"
            centered
            onHide={() => setShowInvModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Image
              style={{ width: "10%" }}
              className="ps-2 ms-1 mt-2 pt-1"
              src={FavLogo}
            />
            <div className="sup-modal ">
              <div>
                <div className="d-flex-as-jc img_tick ">
                  {/* <Image src={Check} />  */}
                  <div className="px-3 mb-2 pb-1">
                    <small>
                      How do you want to create the Proforma Invoice?
                    </small>
                  </div>
                </div>
                <div className="d-flex flex-row-reverse">
                  <div className="row">
                    <div className="col-sm">
                      <button
                        style={{
                          minWidth: "70px",
                          minHeight: "30px",
                          fontSize: "revert",
                        }}
                        className="my-3 modal_btn"
                        onClick={() =>
                          navigate("/invoice/create", {
                            state: { Proforma: Proforma, method: "proforma" },
                          })
                        }
                      >
                        By Upload
                      </button>
                    </div>
                    <div className="col-sm">
                      <button
                        style={{
                          minWidth: "70px",
                          minHeight: "30px",
                          fontSize: "revert",
                        }}
                        className="my-3 modal_btn"
                        onClick={() =>
                          navigate("/invoice", {
                            state: {
                              Proforma: Proforma,
                              method: "proforma",
                            },
                          })
                        }
                      >
                        By Create
                      </button>
                    </div>
                    <div className="col-sm">
                      <Link
                        style={{
                          backgroundColor: "#D1D3D4",
                          minWidth: "70px",
                          minHeight: "30px",
                          fontSize: "small",
                        }}
                        className="my-3 modal_btn"
                        to="#"
                        onClick={() => {
                          setShowInvModal(false);
                        }}
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          {/* MULTIPLE PROFORMA */}
          <Modal
            id="#sucess"
            className="proforma-com-inv"
            show={showInvModals}
            size="md"
            centered
            onHide={() => setShowInvModals(false)}
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Image
              style={{ width: "10%" }}
              className="ps-2 ms-1 mt-2 pt-1"
              src={FavLogo}
            />
            <div className="sup-modal ">
              <div>
                <div className="d-flex-as-jc img_tick ">
                  {/* <Image src={Check} />  */}
                  <div className="px-3 mb-2 pb-1">
                    <small>
                      How do you want to create the Proforma Invoice?
                    </small>
                  </div>
                </div>
                <div className="d-flex flex-row-reverse">
                  <div className="row">
                    <div className="col-sm">
                      <button
                        style={{
                          minWidth: "70px",
                          minHeight: "30px",
                          fontSize: "revert",
                        }}
                        className="my-3 modal_btn"
                        onClick={() =>
                          checkboxs?.length > 0 ? POUploadHandler() : ""
                        }
                      >
                        By Upload
                      </button>
                    </div>
                    <div className="col-sm">
                      <button
                        style={{
                          minWidth: "70px",
                          minHeight: "30px",
                          fontSize: "revert",
                        }}
                        className="my-3 modal_btn"
                        onClick={() =>
                          checkboxs?.length > 0
                            ? navigate("/invoice", {
                                state: {
                                  checkboxs: checkboxs,
                                  method: "proforma",
                                },
                              })
                            : ""
                        }
                      >
                        By Create
                      </button>
                    </div>
                    <div className="col-sm">
                      <Link
                        style={{
                          backgroundColor: "#D1D3D4",
                          minWidth: "70px",
                          minHeight: "30px",
                          fontSize: "small",
                        }}
                        className="my-3 modal_btn"
                        to="#"
                        onClick={() => {
                          setShowInvModals(false);
                        }}
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <div className="">
          {/* <div className="action-page-cont">
            <p>Show</p>
            <div className="page-status-cont">
              <select onChange={setListItems}>
                <option value={10}>{"10"}</option>
                <option value={20}>{"20"}</option>
                <option value={list?.length}>{list?.length}</option>
              </select>
            </div>
            <p>{adminData?.length} Results</p>
          </div> */}
          <div className="page-inner-cont py-2 d-flex justify-content-end">
            <ReactPaginate
              nextLabel="›"
              onPageChange={handlePageChange}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="‹"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
        <Modal
          id="#po-filter-card"
          className="p-3 po-filter-card-cont fnt-fam-f1"
          show={showFilterContent}
          size="md"
          centered
          onHide={() => setShowFilterContent(false)}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className="d-flex flex-wrap justify-content-between mt-4 fs-5 px-4 border-btm">
            <h5 className="font-blue fs-1r fnt-fam-f3  fw-bold">Filter</h5>
            <span
              onClick={() => setShowFilterContent(false)}
              style={{ cursor: "pointer" }}
            >
              {" "}
              X{" "}
            </span>
          </div>
          <div className="d-flex flex-wrap mt-4 mb-2 mx-4 justify-content-between">
            <Col lg={5} md={5} sm={12} xs={12} className="d-flex flex-column">
              <label className="px-2 opac_half">Purchase Order Type</label>
              <select
                name=""
                className="po-filter-card-dropdown px-2 py-1"
                onChange={(e) => setPur_type(e.target.value)}
                value={pur_type}
              >
                <option value="">Select Purchase Order Type</option>
                <option value="M">Material</option>
                <option value="L">Leather</option>
              </select>
            </Col>
            {/* <Col lg={6} md={5} sm={12} xs={12} className="d-flex flex-column">
              <label className="px-2 pb-2 opac_half">Process Type</label>
              <p className="border-btm pb-2 px-2">-</p>
            </Col> */}
          </div>
          <div className="d-flex flex-wrap  mx-4 justify-content-between my-2">
            <Col lg={5} md={6} sm={12} xs={12} className="d-flex flex-column">
              <label className="my-1 px-2 opac_half">Status</label>
              <select
                name=""
                className="po-filter-card-dropdown px-2 py-1"
                onChange={(e) => setPur_status(e.target.value)}
                value={pur_status}
              >
                <option value="">Select Status</option>
                {statusFilter?.map((item, i) => (
                  <option value={item}>
                    {" "}
                    {item == "A "
                      ? "Accepted"
                      : item == "P "
                      ? "Yet to Acknowledge"
                      : item == "D "
                      ? "Declined"
                      : item == "C "
                      ? "Accepted"
                      : item == null
                      ? "Yet to Acknowledge"
                      : null}
                  </option>
                ))}
              </select>
            </Col>
            {/* <Col lg={6} md={6} sm={12} xs={12} className="d-flex flex-column">
              <label className="my-1 px-2 opac_half">Product Category</label>
              <select name="" className="po-filter-card-dropdown px-2 py-1">
                <option value="5">ArmyBoots/Combat Boots</option>
                <option value="10">30</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </Col> */}
          </div>
          <div className="d-flex flex-wrapmx-4 mx-4 justify-content-between mt-2 mb-4 ">
            <Col lg={5} md={6} sm={12} xs={12} className="d-flex flex-column">
              <div className="input-box px-2">
                {/* <label className="opac_half">Delivery Date</label> */}
                <label className="opac_half">Factory</label>

                {/* <input
                  // type="date"
                  onChange={(e) => setPur_date(e.target.value)}
                  value={pur_date}
                /> */}
                <select
                  name=""
                  onChange={(e) => setPur_date(e.target.value)}
                  value={pur_date}
                >
                  <option value="">Select Factory</option>
                  {awardedByFilter?.map((item, i) => (
                    <option value={item?.FactoryName}>
                      {item?.FactoryName}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="d-flex flex-column"
            ></Col>
          </div>
          <div className="d-flex flex-wrap me-4 mt-4 mb-3 justify-content-end gap-3">
            <button
              className="clear-can-btn border-0 rounded-1 fnt-fam-f1"
              style={{ color: "black" }}
              onClick={clearData}
            >
              Clear
            </button>
            <button
              className="bg-primary1 bg-primary1 sub-app-btn text-white rounded-1 border-0 fnt-fam-f1"
              // onClick={POFilterSubmitHandler}
              onClick={getFilter}
              disabled={btn ? true : false}
            >
              Apply
            </button>
          </div>
        </Modal>
        {/* <GeneralPagination /> */}
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default PurchaseOrderList;
