import React, { useEffect, useState } from "react";
import SideBar from "../navbar/SideBar";
import { ProSidebarProvider } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { RxArrowDown, RxDashboard } from "react-icons/rx";
import { BsBroadcastPin } from "react-icons/bs";
import { FaGhost } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faEnvelopesBulk,
  faGauge,
  faMoneyBill,
  faUserShield,
  faMoneyCheck
} from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import useUser from "../constants/Storage/userData";
import { api } from "../constants/Services";
import useProfileStatus from "../constants/Storage/userStauts";

const Navbar = ({ outletWidth, setOutletWidth }) => {
  const [linkwidth, setLinkwidth] = useState(false);
  const [dropArrow, setDropArrow] = useState(false);
  const [pro_status, setPro_status] = useState();
  const { profileStatus } = useProfileStatus();
  // const [enterAdmin, setEnterAdmin] = useState(false);
  // const [profileDrop, setProfileDrop] = useState(false);
  // const [pursuceDrop, setPursuceDrop] = useState(false);
  const [currentSubmenu, setCurrentSubMenu] = useState("");
  const location = useLocation();
  const { user } = useUser();
  const permissions = localStorage.getItem("permissions");
  const { pathname } = location;
  const splitLocations = pathname.split("/");
  // console.log(splitLocations);

  const onClickSubmenuHandler = (currentMenu) => {
    currentSubmenu === currentMenu
      ? setCurrentSubMenu("")
      : setCurrentSubMenu(currentMenu);
  };
  const onClickExpandHandler = () => {
    setLinkwidth(!linkwidth);
    setDropArrow(false);
    setOutletWidth(!outletWidth);
  };

  const [list, setList] = useState([]);

  const getList = () => {
    api.createDetails().then((res) => {
      if (res?.status == "success") {
        setList(res?.bussiness_source_types);
      } else {
      }
    });
  };

  // const onClickDropDown = () => {
  //   setDropArrow(!dropArrow);
  // };
  // const onClickProfileDropDown = () => {
  //   setProfileDrop(!profileDrop);
  // };

  // const onClickEnterAdminHandler = () => {
  //   setEnterAdmin(!enterAdmin);
  // };
  // const onClickPurchaseDropDown = () => {
  //   setPursuceDrop(!pursuceDrop);
  // };

  useEffect(() => {
    setPro_status(profileStatus);
  }, [profileStatus]);

  return (
    <div className="nav-bar">
      <nav>
        <ul
          style={{
            width: linkwidth ? "210px" : "47px",
          }}
        >
          {permissions?.includes("LIST-DASHBOARD") && (
            <li className="nav-li">
              <button
                className={
                  currentSubmenu === "dashboard"
                    ? "toggle-nav-btn-active"
                    : "toggle-nav-btn"
                }
                onClick={onClickExpandHandler}
                style={{ width: "47px" }}
              >
                <FontAwesomeIcon icon={faGauge} />
              </button>
              {linkwidth && (
                <Link
                  type="dashboard"
                  onClick={() => {
                    onClickSubmenuHandler("dashboard");
                    onClickExpandHandler();
                  }}
                  className={
                    // splitLocations[1] === "dashboard"
                    currentSubmenu === "dashboard"
                      ? "nav-link active"
                      : "nav-link "
                  }
                  to={
                    user?.supplier?.business_source?.ReferenceDetailsName ==
                    "Freight Forwarder"
                      ? "/dashboard-user"
                      : user?.supplier?.business_source?.ReferenceDetailsName ==
                        "Transport Agent"
                      ? "/dashboard-user"
                      : "/dashboard"
                  }
                >
                  Dashboard
                </Link>
              )}
            </li>
          )}
          {permissions?.includes("LIST-USERS") && (
            <li className="nav-li">
              <button
                style={{ width: "47px" }}
                className={
                  // splitLocations[1] === "enterprise-admin" ||
                  currentSubmenu === "enterprise-admin"
                    ? "toggle-nav-btn-active"
                    : "toggle-nav-btn"
                }
                onClick={onClickExpandHandler}
              >
                <FontAwesomeIcon icon={faUserShield} />
              </button>
              {linkwidth && (
                <Link
                  type="dashboard"
                  className={
                    // splitLocations[1] === "enterprise-admin" &&
                    currentSubmenu === "enterprise-admin"
                      ? "nav-link active"
                      : "nav-link "
                  }
                  onClick={() => onClickSubmenuHandler("enterprise-admin")}
                >
                  Enterprise Admin
                  <RxArrowDown />
                </Link>
              )}
            </li>
          )}
          {currentSubmenu === "enterprise-admin" && linkwidth ? (
            <ul className="sub-menu">
              {permissions?.includes("LIST-USERS") && (
                <li>
                  <Link
                    style={{ marginLeft: "10px" }}
                    className={
                      splitLocations[2] === "userlist"
                        ? "sub-nav-link active"
                        : "sub-nav-link "
                    }
                    to="/enterprise-admin/userlist"
                    onClick={onClickExpandHandler}
                  >
                    Users
                  </Link>
                </li>
              )}
              {permissions?.includes("LIST-ROLES") && (
                <li>
                  <Link
                    className={
                      splitLocations[2] === "roles"
                        ? "sub-nav-link active"
                        : "sub-nav-link "
                    }
                    to="/enterprise-admin/roles"
                    style={{ marginLeft: "10px" }}
                    onClick={onClickExpandHandler}
                  >
                    Role Configuration
                  </Link>
                </li>
              )}
            </ul>
          ) : (
            ""
          )}
          <li className="nav-li">
            <button
              style={{ width: "47px" }}
              className={
                // splitLocations[1] === "mcprofile" ||
                // splitLocations[1] === "product"
                currentSubmenu === "product"
                  ? "toggle-nav-btn-active"
                  : "toggle-nav-btn"
              }
              onClick={onClickExpandHandler}
            >
              <FontAwesomeIcon icon={faUser} />
            </button>
            {linkwidth && (
              <button
                className={
                  // splitLocations[1] === "mcprofile" ||
                  // splitLocations[1] === "product"
                  currentSubmenu === "product"
                    ? "enq-btn nav-link active"
                    : "enq-btn nav-link"
                }
                onClick={() => onClickSubmenuHandler("product")}
              >
                Profiles
                <RxArrowDown />
              </button>
            )}
          </li>
          {currentSubmenu === "product" && linkwidth ? (
            <ul className="sub-menu">
              {permissions?.includes("LIST-BASIC INFORMATION") && (
                <li>
                  <Link
                    style={{ marginLeft: "10px" }}
                    className={
                      splitLocations[1] === "mcprofile"
                        ? "sub-nav-link active"
                        : "sub-nav-link "
                    }
                    to={
                      +pro_status == 1
                        ? "/mcprofile/addtransport"
                        : +pro_status == 2
                        ? "/mcprofile/addcertificate"
                        : "/mcprofile"
                    }
                    onClick={onClickExpandHandler}
                  >
                    Master Company Profile
                  </Link>
                </li>
              )}

              {user?.is_primary == 2 && user?.sm_department_id == null
                ? null
                : permissions?.includes("LIST-PRODUCTS") && (
                    <li>
                      <Link
                        className={
                          splitLocations[1] === "product"
                            ? "sub-nav-link active"
                            : "sub-nav-link "
                        }
                        to="/product/productList"
                        style={{ marginLeft: "10px" }}
                        onClick={onClickExpandHandler}
                      >
                        Product Profile
                      </Link>
                    </li>
                  )}
            </ul>
          ) : (
            ""
          )}
          {/* <li className="nav-li">
            <button
              className={
                currentSubmenu === "enquires"
                  ? "toggle-nav-btn-active"
                  : "toggle-nav-btn"
              }
              style={{ width: "47px" }}
              onClick={onClickExpandHandler}
            >
              <FontAwesomeIcon icon={faEnvelopesBulk} />
            </button>
            {linkwidth && (
              <button
                className={
                  currentSubmenu === "enquires"
                    ? "enq-btn nav-link active"
                    : "enq-btn nav-link"
                }
                onClick={() => onClickSubmenuHandler("enquires")}
              >
                Enquires
                <RxArrowDown />
              </button>
            )}
          </li> */}
          {/* {currentSubmenu === "enquires" && linkwidth ? (
            <ul className="sub-menu">
              <li>
                <Link
                  type="enquiry-list"
                  className={
                    currentSubmenu === "enquires"
                      ? "sub-nav-link active"
                      : "sub-nav-link "
                  }
                  to="/enquiry/enquiry-list"
                  style={{ marginLeft: "10px" }}
                >
                  Received Enquires
                </Link>
              </li>
              <li>
                <Link
                  type="enquiry-list"
                  className={
                    splitLocations[1] === "quotation"
                      ? "sub-nav-link active"
                      : "sub-nav-link "
                  }
                  to="/quotation/list"
                  style={{ marginLeft: "10px" }}
                >
                  Quotations
                </Link>
              </li>
            </ul>
          ) : (
            ""
          )} */}
          {user?.is_primary == 2 && user?.sm_department_id == null ? null : (
            <li className="nav-li">
              <button
                style={{ width: "47px" }}
                className={
                  // splitLocations[1] === "asn-listing" ||
                  // splitLocations[1] === "purchase-order" ||
                  // splitLocations[1] === "dc"
                  currentSubmenu === "purchase-order"
                    ? "toggle-nav-btn-active"
                    : "toggle-nav-btn"
                }
                onClick={onClickExpandHandler}
              >
                <FontAwesomeIcon icon={faMoneyBill} />
              </button>
              {linkwidth && (
                <button
                  className={
                    // splitLocations[1] === "asn-listing" ||
                    // splitLocations[1] === "purchase-order" ||
                    // splitLocations[1] === "dc"
                    currentSubmenu === "purchase-order"
                      ? "enq-btn nav-link active"
                      : "enq-btn nav-link"
                  }
                  onClick={() => {
                    onClickSubmenuHandler("purchase-order");
                  }}
                >
                  {user?.supplier?.business_source?.ReferenceDetailsName ==
                  "Freight Forwarder"
                    ? "Shipping"
                    : user?.supplier?.business_source?.ReferenceDetailsName ==
                      "Transport Agent"
                    ? "Shipping"
                    : "PO Management"}
                  <RxArrowDown />
                </button>
              )}
            </li>
          )}
          {currentSubmenu === "purchase-order" && linkwidth ? (
            <ul className="sub-menu">
              {user?.supplier?.business_source?.ReferenceDetailsName ==
              "Freight Forwarder"
                ? null
                : user?.supplier?.business_source?.ReferenceDetailsName ==
                  "Transport Agent"
                ? null
                : permissions?.includes("LIST-PURCHASEORDER") && (
                    <li>
                      <Link
                        type="dashboard"
                        style={{ marginLeft: "10px" }}
                        className={
                          // splitLocations[1] === "purchase-order"
                          splitLocations[1] === "purchase-order" &&
                          splitLocations[2] === "list"
                            ? "sub-nav-link active"
                            : "sub-nav-link "
                        }
                        to="/purchase-order/list"
                        onClick={onClickExpandHandler}
                      >
                        Received POs
                      </Link>
                    </li>
                  )}
              {user?.supplier?.business_source?.ReferenceDetailsName ==
              "Freight Forwarder"
                ? null
                : user?.supplier?.business_source?.ReferenceDetailsName ==
                  "Transport Agent"
                ? null
                : permissions?.includes("LIST-ADVANCESHIPPINGNOTE") && (
                    <li>
                      <Link
                        type="dashboard"
                        style={{ marginLeft: "10px" }}
                        className={
                          splitLocations[1] === "asn-listing"
                            ? "sub-nav-link active"
                            : "sub-nav-link "
                        }
                        to="/asn-listing"
                        onClick={onClickExpandHandler}
                      >
                        ASN
                      </Link>
                    </li>
                  )}
              {user?.supplier?.business_source?.ReferenceDetailsName ==
              "Freight Forwarder"
                ? null
                : user?.supplier?.business_source?.ReferenceDetailsName ==
                  "Transport Agent"
                ? null
                : permissions?.includes("LIST-INVOICE") && (
                    <li>
                      <Link
                        type="dashboard"
                        style={{ marginLeft: "10px" }}
                        className={
                          splitLocations[1] === "invoice"
                            ? //  &&
                              // splitLocations[2] === "list"
                              "sub-nav-link active"
                            : "sub-nav-link "
                        }
                        to="/invoice/list"
                        onClick={onClickExpandHandler}
                      >
                        Proforma Invoice
                      </Link>
                    </li>
                  )}
              {user?.supplier?.business_source?.ReferenceDetailsName ==
              "Freight Forwarder"
                ? null
                : user?.supplier?.business_source?.ReferenceDetailsName ==
                  "Transport Agent"
                ? null
                : permissions?.includes("LIST-INVOICE") && (
                    <li>
                      <Link
                        type="dashboard"
                        style={{ marginLeft: "10px" }}
                        className={
                          splitLocations[1] === "commercial-invoice"
                            ? //  &&
                              // splitLocations[2] === "list"
                              "sub-nav-link active"
                            : "sub-nav-link "
                        }
                        to="/commercial-invoice/list"
                        onClick={onClickExpandHandler}
                      >
                        Commercial Invoice
                      </Link>
                    </li>
                  )}
              {user?.supplier?.business_source?.ReferenceDetailsName ==
              "Freight Forwarder" ? (
                <li>
                  <Link
                    type="dc"
                    style={{ marginLeft: "10px" }}
                    className={
                      splitLocations[1] === "dc-list"
                        ? "sub-nav-link active"
                        : "sub-nav-link "
                    }
                    to="/dc-list"
                    onClick={onClickExpandHandler}
                  >
                    Delivery Challan
                  </Link>
                </li>
              ) : user?.supplier?.business_source?.ReferenceDetailsName ==
                "Transport Agent" ? (
                <li>
                  <Link
                    type="dc"
                    style={{ marginLeft: "10px" }}
                    className={
                      splitLocations[1] === "dc-list"
                        ? "sub-nav-link active"
                        : "sub-nav-link "
                    }
                    to="/dc-list"
                    onClick={onClickExpandHandler}
                  >
                    Delivery Challan
                  </Link>
                </li>
              ) : (
                permissions?.includes("LIST-DELIVERYCHALLAN") && (
                  <li>
                    <Link
                      type="dc"
                      style={{ marginLeft: "10px" }}
                      className={
                        splitLocations[1] === "dc-list"
                          ? "sub-nav-link active"
                          : "sub-nav-link "
                      }
                      to="/dc-list"
                      onClick={onClickExpandHandler}
                    >
                      Delivery Challan
                    </Link>
                  </li>
                )
              )}

              {/* <li>
                <Link
                  type="dashboard"
                  style={{ marginLeft: "10px" }}
                  className={
                    splitLocations[1] === "invoice"
                      ? "sub-nav-link active"
                      : "sub-nav-link "
                  }
                  to="/invoice"
                >
                  Commercial Invoice
                </Link>
              </li> */}
              {/* <li>
                <Link
                  type="dashboard"
                  style={{ marginLeft: "10px" }}
                  className={
                    splitLocations[2] === "shpping-tracker"
                      ? "sub-nav-link active"
                      : "sub-nav-link "
                  }
                  to="/purchase-order/shipping-tracker"
                >
                  Shipping Tracker
                </Link>
              </li> */}
              {/* <li>
                <Link
                  type="dashboard"
                  style={{ marginLeft: "10px" }}
                  className={
                    splitLocations[1] === "purchase-order"
                      ? "sub-nav-link active"
                      : "sub-nav-link "
                  }
                  to="/"
                >
                  GST Payment Status
                </Link>
              </li> */}
              {user?.supplier?.business_source?.ReferenceDetailsName ==
              "Freight Forwarder"
                ? null
                : user?.supplier?.business_source?.ReferenceDetailsName ==
                  "Transport Agent"
                ? null
                : permissions?.includes("LIST-GOODSRECEIPTNOTE") && (
                    <li>
                      <Link
                        type="dashboard"
                        style={{ marginLeft: "10px" }}
                        className={
                          // splitLocations[2] === "goods-received-note"
                          splitLocations[2] === "grn"
                            ? "sub-nav-link active"
                            : "sub-nav-link "
                        }
                        // to="/purchase-order/goods-received-note"
                        to="/purchase-order/grn"
                        onClick={onClickExpandHandler}
                      >
                        GRN
                      </Link>
                    </li>
                  )}
              {user?.supplier?.business_source?.ReferenceDetailsName ==
              "Freight Forwarder"
                ? null
                : user?.supplier?.business_source?.ReferenceDetailsName ==
                  "Transport Agent"
                ? null
                : permissions?.includes("LIST-RETURNNOTE") && (
                    <li>
                      <Link
                        type="dashboard"
                        style={{ marginLeft: "10px" }}
                        className={
                          splitLocations[2] === "return-notice-table"
                            ? "sub-nav-link active"
                            : "sub-nav-link "
                        }
                        to="/purchase-order/return-notice-table"
                        onClick={onClickExpandHandler}
                      >
                        Return Note
                      </Link>
                    </li>
                  )}
            </ul>
          ) : (
            ""
          )}
           <li className="nav-li">
            <button
              style={{ width: "47px" }}
              className={
                currentSubmenu === "price-enquiry"
                  ? "toggle-nav-btn-active"
                  : "toggle-nav-btn"
              }
              onClick={onClickExpandHandler}
            >
              <FontAwesomeIcon icon={faMoneyCheck} />
            </button>
            {linkwidth && (
              <Link
                type="dashboard"
                className={
                  currentSubmenu === "price-enquiry"
                    ? "nav-link active"
                    : "nav-link "
                }
                to="/price-enquiries"
                onClick={() => onClickSubmenuHandler("price-enquiry")}
              >
                Price Enquiry
              </Link>
            )}
          </li>
          {user?.supplier?.business_source?.ReferenceDetailsName ==
          "Freight Forwarder"
            ? null
            : user?.supplier?.business_source?.ReferenceDetailsName ==
              "Transport Agent"
            ? null
            : permissions?.includes("LIST-ACCOUNT") && (
                <li className="nav-li">
                  <button
                    style={{ width: "47px" }}
                    className={
                      currentSubmenu === "acc-setting"
                        ? "toggle-nav-btn-active"
                        : "toggle-nav-btn"
                    }
                    onClick={onClickExpandHandler}
                  >
                    <FontAwesomeIcon icon={faEnvelopesBulk} />
                  </button>
                  {linkwidth && (
                    <Link
                      type="dashboard"
                      onClick={() => {
                        onClickSubmenuHandler("acc-setting");
                        onClickExpandHandler();
                      }}
                      className={
                        currentSubmenu === "acc-setting"
                          ? "nav-link active"
                          : "nav-link "
                      }
                      to="/account-settings"
                    >
                      {/* Account  */}
                      Settings
                    </Link>
                  )}
                </li>
              )}
          {/* <li className="nav-li">
            <button
              style={{ width: "47px" }}
              className={
                currentSubmenu === "broadcast"
                  ? "toggle-nav-btn-active"
                  : "toggle-nav-btn"
              }
              onClick={onClickExpandHandler}
            >
              <BsBroadcastPin />
            </button>
            {linkwidth && (
              <Link
                type="broadcast"
                onClick={() => onClickSubmenuHandler("broadcast")}
                className={
                  currentSubmenu === "broadcast"
                    ? "nav-link active"
                    : "nav-link "
                }
                to="admin/broadcast/list"
              >
                BroadCast
              </Link>
            )}
          </li> */}
          {/* <li className="nav-li">
            <button
              style={{ width: "47px" }}
              className={
                currentSubmenu === "setting"
                  ? "toggle-nav-btn-active"
                  : "toggle-nav-btn"
              }
              onClick={onClickExpandHandler}
            >
              <FontAwesomeIcon icon={faEnvelopesBulk} />
            </button>
            {linkwidth && (
              <Link
                type="dashboard"
                onClick={() => onClickSubmenuHandler("setting")}
                className={
                  // splitLocations[1] === "account-settings"
                  currentSubmenu === "setting" ? "nav-link active" : "nav-link "
                }
                to="admin/settings"
              >
                Settings
              </Link>
            )}
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
