// export const BASE_URL = "http://192.168.1.28:8000/"; // kartheshwari akka
// export const BASE_URL = "http://192.168.1.39/laravel-juara/public/"; // sujith sir
// export const BASE_URL = "http://192.168.1.30:8000/";
// export const BASE_URL = "http://192.168.43.114/juara/public/";ip
// export const BASE_URL = "http://192.168.1.71/laravel-juara/public/"; // Kowshika
// export const BASE_URL = "https://sapidemo.khexports.in/index.php/";
export const BASE_URL = "https://sapidemo.khexports.in/public/index.php/"; // live
// export const BASE_URL = "http://localhost:8000/index.php/"
// export const BASE_URL = "https://script.kh.businessgateways.in/api/";
//export const BASE_URL = "http://192.168.195.187/juara/public/api/";
// export const BASE_URL = "http://192.168.1.11/laravel-juara/public/";

// export const URL = {
//   // REGISTER
//
//   COUNTRY: "countries",
//   REGISTER: "supplier/register",s
//   OTP: "otp-verification",
//   RESENDOTP: "resend-otp",
//   SET_PASSWORD: "set-password",
//   ACCEPT_REJECT: "supplier/accept-reject",

//   // FORGOT PASSWORD

//   FORGOT_PASSWORD: "request/otp",
//   FORGOT_PASSWORD_OTP_VERIFY: "password-otp-verification",

//   // LOGIN

//   LOGIN: "supplier/login",

//   // PROFILE

//   PROFILE_VIEW: "profile-view",
//   PROFILE_UPDATE: "profile-update",

//   // DEPARTMENT

//   DEPARTMENT_ADD: "departments",
//   DEPARTMENT_EDIT: "departments/",
//   DEPARTMENT_LIST: "departments",
//   DEPARTMENT_STATUS: "department/status/",

//   // ADDRESS

//   ADDRESS_LIST: "addresses",
//   ADDRESS_ADD: "addresses",
//   ADDRESS_EDIT: "addresses/",
//   ADDRESS_VIEW: "addresses/",
//   ADDRESS_DELETE: "addresses/",
//   STATELIST: "states/",
//   CITYLIST: "cities/",

//   // CERTIFICATE && AWARD

//   CERTIFICATE_AWARD_LIST: "certificate-awards",
//   CERTIFICATE_AWARD_ADD: "certificate-awards",
//   CERTIFICATE_AWARD_EDIT: "certificate-awards/",
//   CERTIFICATE_AWARD_VIEW: "certificate-awards/",
//   CERTIFICATE_AWARD_DELETE: "certificate-awards/",

//   // PAYMENT SETTING

//   PAYMENT_ADD: "bank-details",

//   // SUPPLIER USER

//   SUPPLIER_USER: "supplier-users",

//   SUPPLIER_USERS: "supplier-user",

//   SUPPLIER_USER_LOGS: "supplier/activity-logs/",

//   // ROLES

//   ROLES_CREATE: "supplier-roles/create",

//   ROLES: "supplier-roles",

//   ROLE: "supplier-role",

//   // CHANGE ADMIN

//   CHANGE_ADMIN: "change/primary-contact",
// };

export const URL = {
  // REGISTER

  COUNTRY: "supplierapi/create/suppliers",

  REGISTER: "supplierapi/supplier/register",

  VERIFY_OTP: "supplierapi/supplier/otp-verify",

  RESEND_OTP: "supplierapi/resendOtp",

  TOKEN_VERIFY: "supplierapi/supplier-token-validate/",

  SET_PASSWORD: "supplierapi/supplier/user-register",

  SET_PASS: "supplierapi/password-set",

  LOGIN: "supplierapi/supplier/login",

  LOGOUT: "supplierapi/supplier/logout",

  FORGOT_PASSWORD: "supplierapi/supplier/forgot-password",

  // SUPPLIER USER

  SUPPLIER_USER: "supplierapi/supplier-user",

  SUPPLIER_USERS: "supplierapi/supplier-user",

  SUPPLIER_USER_PASS: "supplierapi/password-set",

  SUPPLIER_USER_FILTER: "supplierapi/user-search",

  SUPPLIER_USER_FILTERS: "supplierapi/user-filter",

  // ROLES

  ROLES: "supplierapi/supplier-role",

  ROLES_CREATE: "supplierapi/supplier-user/create",

  ROLE: "api/role",

  // BRANCH COUNTRY STATE CITY

  ADDRESS: "supplierapi/supplier-address/create",

  ADDRESS_ADD: "supplierapi/supplier-address",

  BRANCH: "supplierapi/supplier-bank/create",

  // TRANSPORTP

  TRANSPORT: "supplierapi/supplier-transport",

  SKIP_UPDATE: "supplierapi/skip-profile-update",

  // CERTIFICATE AND AWARD

  CERTIFICATE_AWARD: "supplierapi/supplier-document",

  // CHANGE PASSWORD

  CHANGE_PASSWORD: "supplierapi/supplier/change-password",

  // CHANGE_ADMIN

  CHANGE_ADMIN: "supplierapi/primary-contact",

  CHANGE_ADMIN_PASSWORD: "supplierapi/supplier/password-verify",

  CHANGE_ADMIN_USER: "supplierapi/supplier-map-user/",

  // NOTIFICATION

  NOTIFICATION: "supplierapi/notifications",

  // MC PROFILE

  MC_PROFILE: "supplierapi/supplier-master-profile",

  MC_PROFILE_UPDATE: "supplierapi/profile-update",

  MC_FACTORIES: "api/factories",

  // DEPARTMENT AND DESIGNATION

  DEPART_DESIGN: "supplierapi/supplier-master",

  // CREATE DETAILS

  CREATE_DETAILS: "supplierapi/supplier-create-details",

  // SUPPLIER BANK

  SUPPLIER_BANK: "supplierapi/supplier-bank",

  SUPPLIER_BANK_LIST: "supplierapi/bank-details",

  SUPPLIER_BRANCH_LIST: "supplierapi/bank-branches",

  // MASTER PRODUCTS

  MASTER_PRODUCTS: "supplierapi/master-products",

  MASTER_PRODUCTS_FILTER: "supplierapi/material-filters",

  MASTER_PRODUCT_VIWE: "supplierapi/master-product",

  SUPPLIER_PRO_ADD: "supplierapi/supplier-product/add",

  SUPPLIER_PRO_LIST: "supplierapi/supplier-products",

  SUPPLIER_POR_FILTER: "supplierapi/search",

  SUPPLIER_PRO_VIEW: "supplierapi/supplier-product/view/",

  SUPPLIER_PRO_EDIT: "supplierapi/supplier-product/edit",

  SUPPLIER_PRO_DELETE: "supplierapi/supplier-product-delete/",

  SUPPLIER_PRO_FACTORY: "supplierapi/supplier-factories",

  SUPPLIER_DOCUMENT_EDIT: "supplierapi/supplier-product-document",

  SUPPLIER_DOCUMENT_DELETE: "supplierapi/supplier-product-delete/",

  // PURCHASE ORDER
  INVOICE_LIST: "supplierapi/invoice-list",
  INVOICE_VIEW: "supplierapi/invoice-view",
  INVOICE_FILTER: "supplierapi/invoice-list-filter",
  SUPPLIER_PURCHASE: "supplierapi/supplier-purchase-orders",

  SUPPLIER_PURCHASE_VIEW: "supplierapi/supplier-purchase-order",

  
  //PRICE ENQUIRY LIST
  SUPPLIER_PRICE_ENQUIRY_LIST: "supplierapi/price-enquiry-list",
  SUPPLIER_UPDATE_PRICE_ENQUIRY: "supplierapi/price-enquiry-update",
  
  //COMMON
  IS_IGST: "/supplierapi/is-igst",
  TAX_LIST: "/supplierapi/tax-list",

  SUPPLIER_TERMS: "supplierapi/terms-conditions",
  SUPPLIER_PURCHASE_FILTER: "supplierapi/purchase-order-filter",
  APPROVALREMARKS_TERMS: "supplierapi/approvalRemarks",
  PO_INVOICE_UPLOAD: "supplierapi/po/invoice-upload",
  PO_UPLOAD_DATAS: "supplierapi/po/dcs",
  PO_DC_INV_LIST: "supplierapi/po/invoice-create",
  PO_INV_ADD: "supplierapi/po/invoice-add",

  PO_INVOICE_UPLOAD_EDIT: "supplierapi/po/invoice-update-upload",

  PO_INVOICE_EDIT: "supplierapi/po/invoice-update",
  // SUPPLIER_PURCHASE_SEARCH: "/supplierapi/user-search",

  // ASN

  ASN_LIST: "supplierapi/asns",

  ASN_TABLE: "supplierapi/purchase-order-detail",

  ASN_STORE: "supplierapi/asn-store",

  STORE_SHIPMENT: "supplierapi/store-shipment",

  ASN_CREATE: "supplierapi/asns/create",

  ASN_TABLE_FILTER: "supplierapi/asns-filter",

  ASN_TABLE_DEL_DATES: "supplierapi/asn-transport-del-dates",

  ASN_PO_GROUP: "supplierapi/po-group/lists",

  ASN_UPDATE: "supplierapi/asn-update",

  // DASHBORD

  DASHBORD: "supplierapi/dashboard-purchase-orders",

  DASHBORD_ASN: "supplierapi/dashboard-asn-list",

  DASHBORD_GRN: "supplierapi/grn-list-dashboard",

  DASHBORD_DC: "supplierapi/asn-supplier-dashboard",

  DASHBORD_RETRUN_NOTE: "supplierapi/return-notes-dashboard",

  DASHBORD_ON_IN_TRANSIT: "supplierapi/dashboard-on-in-transit",

  DASHBORD_ON_DELIVERED: "supplierapi/dashboard-on-delivered",

  DASHBORD_ON_GRN: "supplierapi/dashboard-on-grn-osd",

  DASHBORD_ON_RETURN: "supplierapi/dashboard-on-returns",

  DASHBORD_UPCOMING_LIST: "supplierapi/dashboard-up-coming-delivers",

  DASHBORD_AMOUNT: "supplierapi/supplier/dashboard",

  // DC

  DC_CREATE: "supplierapi/dc-create",

  DC_PO_DETAILS: "supplierapi/dc-po-details",

  DC_PO_DETAIL: "supplierapi/dc-po-detail",

  DC_CREATE_STAGES: "supplierapi/dc-create-stages",

  DC_CREATE_STAGES_MANY: "supplierapi/dc-create-stages-many",

  DC_UPDATE: "supplierapi/dc-update",

  DC_UPDATE_STAGES: "supplierapi/dc-update-stage",

  DC_UPDATE_STAGES_MANY: "supplierapi/dc-update-stages-many",

  DC_TABLE: "supplierapi/asn-supplier-details",

  DC_VIEW: "supplierapi/asn-supplier-detail",

  DC_STAGES_PORT: "supplierapi/port-list",

  DC_TABLE_FILTER: "supplierapi/asn-supplier-detail-filter",

  DC_UOM_LIST: "supplierapi/uom-list",

  CONVERSION: "supplierapi/conversion",

  CUBIC_CONVERSION: "supplierapi/cubic-conversion",

  // GRN

  GRN_TABLE: "supplierapi/grn-list",

  GRN_TABLE_FILTER: "supplierapi/grn-filter",

  GRN_VIEW: "supplierapi/grn-view",

  GRN_RESPONCE: "supplierapi/grn-response-add",

  // RETURN NOTE

  RETURN_NOTE_VIEW: "supplierapi/return-note-view",

  RETURN_NOTE_TABLE: "supplierapi/return-notes",
  
  RETURN_NOTE_FILTER_TABLE: "supplierapi/return-filter-notes",

  RETURN_NOTE_REMARKS: "supplierapi/return-note-save-remarks",

  // INVOICE FINE
  INVOICE_FINE: "supplierapi/invoice-fine",

  // DRAFTS
  DRAFTS: "supplierapi/invoice-drafts",
  ASN_DARFTS: "supplierapi/asn-drafts",
  DC_DARFTS: "supplierapi/dc-drafts",
  SAVE_DRAFTS: "supplierapi/save-draft",
  DELETE_DRAFTS: "supplierapi/draft/delete",
};

export const TOKEN = "token";
export const USER_DETAILS = "user_details";
export const USER_STATUS = "user_status";
export const PROFILE = "profile";

export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
