import React, { useState, useEffect } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
function DcTable({
  i,
  asnTable,
  changeAsnTable,
  onNoNameChange,
  noName,
  onNoNameChanges,
  noNames,
  type,
  handleSelectAll,
  isCheck,
  isCheckAll,
  errorQyt,
}) {
  const location = useLocation();
  const [awardedQty, setAwardedQty] = useState([]);

  useEffect(() => {
    let awarded_qty = asnTable?.map((item) => {
      return item?.material_purchase_order_detail?.purchase_order_detail_del_dates?.reduce(
        (acc, val) => acc + +val?.Quantity,
        0
      );
    });
    // console.log("awarded_qty", awarded_qty);
    setAwardedQty(awarded_qty);
  }, [asnTable]);
  // console.log("isCheck", isCheck, i);
  // console.log("isChecks", isCheck[i], i);
  //   return false;

  // console.log("asnTable", asnTable);

  const handleQty = (event, id, i, asn_qty, delivery_qty) => {
    if (type == "edit") {
      onNoNameChanges(event, id, asn_qty, delivery_qty);
    } else {
      onNoNameChange(event, id, i, asn_qty, delivery_qty);
    }
  };

  return (
    <div className="product-table scroll-100 my-1 py-1">
      <Table className="mb-0">
        <thead>
          <tr className="text-center">
            <th className="text-nowrap d-flex gap-1">
              <label>
                {type !== "edit" && (
                  <label className="d-flex table-chk-cont">
                    <input
                      type="checkbox"
                      className="me-1"
                      onChange={() => handleSelectAll(i)}
                      checked={isCheckAll[i] ? true : false}
                    />
                  </label>
                )}
              </label>
              <span className="text-dark mt-1"> S.No</span>
            </th>
            <th className="text-nowrap">Supplier Mat Code</th>
            <th className="text-nowrap"> KH SKU Code</th>
            <th className="text-nowrap" style={{ minWidth: "209px" }}>
              KH SKU
            </th>
            <th className="text-nowrap">HSN Code</th>
            <th>UOM</th>
            <th className="text-nowrap">
              {/* Award Qty */}ASN Shipment Qty
              {/* <button className="cust-btn ms-0 ps-2">
                <MdOutlineKeyboardArrowDown />
              </button> */}
            </th>
            <th className="text-nowrap">
              Delivered Qty
              {/* <button className="cust-btn ms-0 ps-2">
                <MdOutlineKeyboardArrowDown />
              </button> */}
            </th>
            <th>DC Qty</th>
          </tr>
        </thead>
        <tbody className="border-0">
          {asnTable &&
            asnTable?.map((item, ind) => {
              let data = item?.Quantity;
              // console.log("item", item);
              let shipment_qty =
                item?.purchase_order_detail_del_date
                  ?.material_purchase_order_detail?.sum_quantity ||
                item?.purchase_order_detail_del_date
                  ?.leather_purchase_order_detail?.sum_quantity ||
                item?.material_purchase_order_detail?.sum_quantity ||
                item?.leather_purchase_order_detail?.sum_quantity;

              let delivered_qty =
                item?.material_purchase_order_detail?.sum_received_quantity ||
                item?.leather_purchase_order_detail?.sum_received_quantity;

              return (
                <tr
                  className={`text-center ${
                    +delivered_qty >= +shipment_qty ? "opacity-50" : ""
                  }`}
                >
                  <td className="d-flex gap-1">
                    {type !== "edit" && (
                      <label className="d-flex table-chk-cont">
                        <input
                          type="checkbox"
                          className="me-1"
                          onChange={() => changeAsnTable(i, item, ind)}
                          checked={isCheck?.[i]?.includes(item)}
                          disabled={
                            +delivered_qty >= +shipment_qty ? true : false
                          }
                        />
                      </label>
                    )}
                    <label className="mt-1"> {ind + 1}</label>
                  </td>
                  <td>{"-"}</td>
                  <td>
                    {item?.material_purchase_order_detail?.sku
                      ?.MaterialSKUCode ||
                      item?.leather_purchase_order_detail?.sku
                        ?.LeatherSKUCode ||
                      item?.sku?.MaterialSKUCode ||
                      item?.sku?.LeatherSKUCode}
                  </td>
                  <td>
                    <p className=" my-1" style={{ fontSize: "14px" }}>
                      {item?.material_purchase_order_detail?.sku
                        ?.MaterialSKUName ||
                        item?.leather_purchase_order_detail?.sku
                          ?.LeatherSKUName ||
                        item?.sku?.MaterialSKUName ||
                        item?.sku?.LeatherSKUName}
                    </p>
                  </td>
                  <td>
                    {item?.material_purchase_order_detail?.sku?.material_header
                      ?.hsn?.HSNCode ||
                      item?.leather_purchase_order_detail?.sku?.leather_header
                        ?.hsn?.HSNCode ||
                      item?.sku?.material_header?.hsn?.HSNCode ||
                      item?.leather_header?.hsn?.HSNCode ||
                      item?.sku?.leather_header?.hsn?.HSNCode ||
                      item?.sku?.material_header?.hsn?.HSNCode}
                  </td>
                  <td>
                    {item?.material_purchase_order_detail?.sku?.material_header
                      ?.uom?.UomCode ||
                      item?.leather_purchase_order_detail?.sku?.leather_header
                        ?.uom?.UomCode ||
                      item?.sku?.material_header?.uom?.UomCode ||
                      item?.leather_header?.uom?.UomCode ||
                      item?.sku?.leather_header?.uom?.UomCode ||
                      item?.sku?.material_header?.uom?.UomCode}
                  </td>
                  {/* <td>{awardedQty[ind]}</td> */}
                  <td>
                    {item?.purchase_order_detail_del_date
                      ?.material_purchase_order_detail?.sum_quantity ||
                      item?.purchase_order_detail_del_date
                        ?.leather_purchase_order_detail?.sum_quantity ||
                      item?.material_purchase_order_detail?.sum_quantity ||
                      item?.leather_purchase_order_detail?.sum_quantity}
                  </td>
                  <td>
                    {item?.material_purchase_order_detail
                      ?.sum_received_quantity ||
                      item?.leather_purchase_order_detail
                        ?.sum_received_quantity}
                  </td>
                  <td>
                    {/* {Math.round(item?.Quantity)} */}
                    <input
                      type="number"
                      value={
                        type == "edit"
                          ? noNames[
                              item?.MaterialPurchaseOrderDetailDelDateID ||
                                item?.LeatherPurchaseOrderDetailDelDateID
                            ]
                          : noName?.[i]?.[
                              item?.MaterialPurchaseOrderDetailDelDateID ||
                                item?.LeatherPurchaseOrderDetailDelDateID
                            ]
                      }
                      onChange={(e) =>
                        handleQty(
                          e,
                          item?.MaterialPurchaseOrderDetailDelDateID ||
                            item?.LeatherPurchaseOrderDetailDelDateID,
                          i,
                          item?.purchase_order_detail_del_date
                            ?.material_purchase_order_detail?.sum_quantity ||
                            item?.purchase_order_detail_del_date
                              ?.leather_purchase_order_detail?.sum_quantity ||
                            item?.material_purchase_order_detail
                              ?.sum_quantity ||
                            item?.leather_purchase_order_detail?.sum_quantity,
                          item?.material_purchase_order_detail
                            ?.sum_received_quantity ||
                            item?.leather_purchase_order_detail
                              ?.sum_received_quantity
                        )
                      }
                      disabled={
                        type == "edit"
                          ? false
                          : !isCheck?.[i]?.includes(item)
                          ? true
                          : +delivered_qty >= +shipment_qty
                          ? true
                          : false
                      }
                    />

                    {errorQyt?.[
                      item?.MaterialPurchaseOrderDetailDelDateID ||
                        item?.LeatherPurchaseOrderDetailDelDateID
                    ] == 1 && (
                      <p className="err" style={{ fontSize: "10px" }}>
                        Exceeding Max Qty
                      </p>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
}

export default DcTable;
