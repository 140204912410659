import React from "react";
import TopHeader from "../../../navbar/TopHeader";
import TopBar from "../../../navbar/TopBar";
import Search from "../../../assets/Icons/Normal Icons/Search.svg";
import Filter from "../../../assets/Icons/Normal Icons/Filter.svg";
import LogInFooter from "../../../logincomponent/LogInFooter";
import { Col, Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";
import GRNTableContent from "./GRNTableContent";
import GRNFilterSec from "./GRNFilterSec";
import ReactPaginate from "react-paginate";
import { RiDeleteBack2Line } from "react-icons/ri";
import { RxTriangleUp } from "react-icons/rx";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import ViewDetails from "../../../assets/Icons/Normal Icons/ViewDetails.svg";
import ErrorModel from "../../../constants/Services/Api/errorModel";
import { api } from "../../../constants/Services";
import moment from "moment/moment";

function GRNPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [openCard, setOpenCard] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [pages, setPages] = useState(null);
  const [pageCount, setPageCount] = useState(10);
  const [list, setList] = useState([]);
  const [responce, setResponse] = useState("");
  const [responceBox, setResponseBox] = useState(true);
  const [btn, setBtn] = useState(false);
  const [grnEntryNo, setGrnEntryNo] = useState([]);

  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const data = location?.state?.data;
  // console.log("location", data);

  // START PAGINATION

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const grnModalCloseHandler = () => setOpenCard(false);

  const grnSupplierSubmitHandler = (supres) => setOpenCard(supres);

  const modelHideHandler = () => setShowModel(false);

  const modelShowHandler = () => setShowModel(true);

  const handlePageChange = (selectedObject) => {
    setPages(selectedObject.selected + 1);
  };

  const getList = () => {
    let formdata = new FormData();

    formdata.append("type", data?.type);
    formdata.append("factory_id", data?.factory_id);
    formdata.append("grn_id", data?.grn_id);

    api.grnView(formdata).then((res) => {
      if (res?.status == "success") {
        setList(res?.grn);
        setGrnEntryNo(res?.gate_entry_no);
        setResponse(res?.grn?.SupplierResponseMsg);
        if (res?.grn?.SupplierResponseMsg) {
          setResponseBox(false);
        }
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const grnResponseAdd = () => {
    let formdata = new FormData();

    formdata.append("type", data?.type);
    formdata.append("factory_id", data?.factory_id);
    formdata.append("grn_id", data?.grn_id);
    formdata.append("response_msg", responce);

    setBtn(true);
    api.grnResponse(formdata).then((res) => {
      if (res?.status == "success") {
        grnModalCloseHandler();
        setError(res);
        setBtn(false);
        setErrorBox(true);
        setTimeout(() => {
          navigate("/purchase-order/grn");
        }, 1500);
      } else {
        setError(res);
        setErrorBox(true);
        setBtn(false);
      }
    });
  };

  useEffect(() => {
    if (data) {
      getList();
    } else {
      navigate("/purchase-order/grn");
    }
  }, []);

  return (
    <>
      <TopBar />
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopHeader type="grn" />
      <Col
        className="m-3 p-3 d-sm-flex flex-column flex-wrap grn-whole-cont min-ht-80"
        xs={12}
        lg={12}
      >
        <h6 className="primary1 mb-2 fnt-fam-f2"> Goods Received Note</h6>
        <div className="fnt-fam-f2 my-2 d-flex flex-column flex-wrap gap-2 opac-85 border-btm">
          <p className="d-flex flex-wrap gap-3">
            <span className="wid-11r">
              GRN No:{" "}
              {list?.MaterialGoodsReceiptNoteNo ||
                list?.LeatherGoodsReceiptNoteNo}
            </span>
            <span className="wid-11r">
              GRN date:{" "}
              {list?.MaterialGoodsReceiptNoteDate
                ? moment(list?.MaterialGoodsReceiptNoteDate).format(
                    "DD-MMM-YYYY"
                  )
                : list?.LeatherGoodsReceiptNoteDate
                ? moment(list?.LeatherGoodsReceiptNoteDate).format(
                    "DD-MMM-YYYY"
                  )
                : null}
            </span>
            <span className="wid-11r">
              Gate Entry No:{" "}
              {grnEntryNo?.MaterialGateEntryNo}
              {/* {list?.MaterialGoodsReceiptNoteNo || list?.LeatherGoodsReceiptNoteNo} */}
            </span>
            <span className="wid-11r">DC No: {list?.DCNo}</span>
            <span className="wid-11r">
              DC Date:{" "}
              {list?.DCDate ? moment(list?.DCDate).format("DD-MMM-YYYY") : null}
            </span>
          </p>
          <p className="d-flex flex-wrap gap-3">
            <span className="wid-11r">Invoice No: {list?.InvoiceNo}</span>
            <span className="wid-11r">
              Invoice date:{" "}
              {list?.InvoiceDate
                ? moment(list?.InvoiceDate).format("DD-MMM-YYYY")
                : null}
            </span>
            <span className="wid-11r">
              Curreny: {list?.currency?.CurrencyCode}
            </span>
            <span className="wid-11r">
              Exchange Rate INR: {Math.round(list?.ExchangeRate)}
            </span>
            <span className="wid-11r">Shipping Stage: Partial</span>
          </p>
          <p className="d-flex flex-wrap gap-3 pb-1 mb-2">
            <span className="wid-11r">BOE No: {list?.BOENo}</span>
            <span className="wid-11r">
              BOE Date:{" "}
              {list?.BOEDate
                ? moment(list?.BOEDate).format("DD-MMM-YYYY")
                : null}
            </span>
            <span className="wid-11r">
              Lience Type: {list?.licence_type?.LicenseTypeDetailsName}
            </span>
            <span className="wid-11r">Licence No: {list?.LicenseNo}</span>
          </p>
        </div>
        {/* <div className="d-sm-flex flex-wrap justify-content-between pe-5 me-3">
          <div>
            <p className="fnt-fam-f2">
              <span className="opac-85">Purchase Order Ref No:</span>1234782
            </p>
            <p className="pt-1 mb-2 pb-2 fw-bold">Shoe Accessories</p>
          </div>
          <div>
            <label className="mb-2 fs-14px">
              Received Date<span className="red-text">*</span>
            </label>
            <p className="wid-11r border-btm fs-14px mb-2">10.01.2023</p>
          </div>
        </div> */}
        {/* <h6 className="primary1 mb-1 pt-1 fnt-fam-f2">Bill of Materials</h6>  */}
        {/* <GRNFilterSec grnSupplierSubmitHandler={grnSupplierSubmitHandler} />  */}
        <div className="d-sm-flex justify-content-between mb-2 flex-wrap align-items-center responsive-width">
          <div className="d-flex flex-wrap mt-sm-0 ms-auto">
            {/* <div
              style={{ height: "34px", width: "254px" }}
              className="search-box grn-search-bar ms-auto d-flex gap-2 mb-3 align-items-center px-2 rounded-1"
            >
              <input placeholder="Search Title" />
              <img className="search-icon" src={Search} alt="search" />
            </div> */}
            <div className="d-flex gap-2 flex-wrap me-2 pe-1">
              <button
                style={{ height: "34px" }}
                onClick={() => grnSupplierSubmitHandler(true)}
                className="bg-primary1 text-white px-2 ms-2 fs-14px  border-0 fnt-fam-f2"
              >
                Supplier Response
              </button>
            </div>
          </div>
        </div>
        <div className="table-scroll-horizontal over-flow-x-scroll my-2 responsive-width">
          <table className="mb-1 py-1 grn-table table">
            <thead style={{ background: "#ededed" }}>
              <tr className="fnt-fam-f3 opac-85 border-0 text-center">
                <th className="fs-14px">Sl.No</th>
                <th className="text-nowrap fs-14px">Supplier Mat. Code</th>
                <th className="fs-14px">KH Material SKU</th>
                <th className="fs-14px">
                  DC Qty
                  {/* <td className="text-nowrap fs-14px">
                    DC Qty
                    <select
                  name=""
                  className="border-0"
                  style={{ background: "#ededed" }}
                >
                  <option value="5"></option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
                  </td> */}
                </th>
                <th className="text-nowrap fs-14px">
                  GRN Qty
                  {/* <td className="fs-14px">
                   
                    <select
                  name=""
                  className="border-0"
                  style={{ background: "#ededed" }}
                >
                  <option value="5"></option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
                  </td> */}
                </th>
                {/* <th className="text-nowrap fs-14px">OSD Qty</th> */}
                {/* <th className="text-nowrap fs-14px position-relative">
                  <span> OSD Type</span>
                  <select
                    name=""
                    className="border-0 pointer"
                    style={{ background: "#ededed" }}
                  >
                    <option></option>
                    <option value="10">Shortage</option>
                    <option value="15">Damage</option>
                  </select>
                </th> */}
                <th className="fs-14px">Remarks</th>
              </tr>
            </thead>
            <tbody className="fs-6 opac-80">
              {list?.grn_details?.map((item, ind) => {
                return (
                  <tr key="01" className="text-center">
                    <td>{ind + 1}</td>
                    <td>
                      {item?.purchase_order_detail_del_dates
                        ?.material_purchase_order_detail?.sku
                        ?.MaterialSKUCode ||
                        item?.purchase_order_detail_del_dates
                          ?.leather_purchase_order_detail?.sku?.MaterialSKUCode}
                    </td>
                    <td>
                      <div className="my-1 position-relative">
                        {item?.purchase_order_detail_del_dates
                          ?.material_purchase_order_detail?.sku
                          ?.MaterialSKUName ||
                          item?.purchase_order_detail_del_dates
                            ?.leather_purchase_order_detail?.sku
                            ?.LeatherSKUName}
                        {/* <span
                          className="font-col-sec"
                          onClick={modelShowHandler}
                        >
                          <img src={ViewDetails} className="wid-15px ms-1" />
                        </span> */}
                        {/* {showModel && (
                          <div className="quo-scop-tab-model-box p-3 ">
                            <p className="enq-view-modelbox-arrow-cont ">
                              <RxTriangleUp />
                            </p>
                            <div className="d-flex justify-content-between border-btm pb-2">
                              <p className="fnt-fam-f3 fs-5 opac-85 d-flex gap-2 align-items-center">
                                View Details
                                <AiOutlineExclamationCircle />
                              </p>
                              <p
                                className="fnt-fam-f3 fs-5"
                                onClick={modelHideHandler}
                              >
                                <RiDeleteBack2Line />
                              </p>
                            </div>
                            <div className="d-sm-flex flex-wrap">
                              <Col xs={5} className="bor-right">
                                <p className="py-1 my-1">
                                  <span className="opac_half">
                                    Material Type:{" "}
                                  </span>
                                  Hardware
                                </p>
                                <p className="py-1 my-1">
                                  <span className="opac_half">Width : </span>
                                  258mm
                                </p>
                                <p className="py-1 my-1">
                                  <span className="opac_half">Size 1 : </span>
                                  40cm
                                </p>
                                <p className="py-1 my-1">
                                  <span className="opac_half">Size 2 : </span>
                                  58mm
                                </p>
                                <p className="py-1 my-1">
                                  <span className="opac_half">CMNO : </span>
                                  TE857856
                                </p>
                              </Col>
                              <Col xs={6} className="px-2">
                                <p className="py-1 my-1">
                                  <span className="opac_half">
                                    Vendor Referance :{" "}
                                  </span>
                                  UY00123
                                </p>
                                <p className="py-1 my-1">
                                  <span className="opac_half">Price : </span>
                                  1200
                                </p>
                                <p className="py-1 my-1">
                                  <span className="opac_half">PO.No : </span>
                                  37
                                </p>
                              </Col>
                            </div>
                          </div>
                        )} */}
                      </div>
                      {/* <p className="text-wrap my-1 wid_15">
                        text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                        Richard McClintock, a
                      </p> */}
                    </td>
                    <td>
                      {Math.round(
                        item?.purchase_order_detail_del_dates?.Quantity
                      )}
                    </td>
                    {/* <td>{Math.round(item?.Quantity)}</td> */}
                    <td>{Math.round(item?.ReceivedQty)}</td>
                    {/* <td>{item?.ost_type?.ReferenceDetailsName}</td> */}
                    <td className="text-wrap">{item?.Remarks}</td>
                  </tr>
                );
              })}

              {/* <tr key="02">
                <td>02</td>
                <td>SPC09842456</td>
                <td>
                  <p className=" my-1">
                    R95CH456789{" "}
                    <span className="font-col-sec">
                      <img src={ViewDetails} className="wid-15px ms-1" />
                    </span>
                  </p>
                  <p className="text-wrap my-1  wid_15">
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a
                  </p>
                </td>
                <td>B$5678945</td>
                <td>1</td>
                <td>4</td>
                <td>Damage</td>
                <td className="text-wrap">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aenean eu lacinia massa. Donec at diam sed mauris sollicitudin
                  dignissim
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
        {/* <div className="page-inner-cont py-2 d-flex justify-content-end">
          <ReactPaginate
            nextLabel="›"
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="‹"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div> */}

        {/* <h6 className="primary1 mt-2 pt-2 fnt-fam-f2 mb-0">Package Received</h6>
        <Col xxl={8} lg={10} md={10} sm={12}>
          <div className="cont-border mt-3 fnt-fam-f2">
            <div className="d-sm-flex  border-btm p-2 ">
              <p className="fw-bold grn-btn-cont-fst-para">
                As per Delivery Challan
              </p>
              <p className="grn-btn-cont-fst-para">
                <span className="opac_half">No of Packages:</span>{" "}
                {list?.asn?.NoOfBox}
              </p>
              <p className="grn-btn-cont-fst-para">
                <span className="opac_half text-nowrap">
                  Total Gross Weight:
                </span>{" "}
                {list?.asn?.GrossWeight
                  ? Math.round(list?.asn?.GrossWeight)
                  : "-"}
              </p>
              <p className="grn-btn-cont-fst-para">
                <span className="opac_half">Total Net Weight:</span>{" "}
                {list?.asn?.NetWeight ? Math.round(list?.asn?.NetWeight) : "-"}
              </p>
              <p className="grn-btn-cont-fst-para">
                <span className="opac_half">Total Volumn:</span>
                {/* {list?.asn?.} 
              </p>
            </div>
            <div className="d-sm-flex p-2 ">
              <p className="fw-bold" style={{ width: "177px" }}>
                Actually Received
              </p>
              <p className="cont-border grn-btn-cont-sec-para">
                {list?.asn?.package_detail?.PackageDimension}
              </p>
              <p className="cont-border grn-btn-cont-sec-para">
                {list?.asn?.package_detail?.PackageLabel}
              </p>
              <p className="cont-border grn-btn-cont-sec-para">
                {list?.asn?.package_detail?.PackageType}
              </p>
              <p className="cont-border grn-btn-cont-sec-para">
                {list?.asn?.package_detail?.PackageWeight}
              </p>
            </div>
          </div>
        </Col> */}
        <button
          // onClick={() => setShipmentModal(false)}
          onClick={() => navigate("/dashboard")}
          className="cust-btn border-0 bg-primary1  text-white mt-2 me-4 ms-auto d-sm-flex"
        >
          Close
        </button>
      </Col>
      <Modal
        id="#grn-card-supplier"
        className="po-acknoweld-card-cont px-3 fnt-fam-f2 border-top-blue-thick pt-3 pb-1"
        show={openCard}
        size="md"
        centered
        onHide={() => setOpenCard(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className=" d-sm-flex  flex-wrap justify-content-between pb-2 border-btm mt-3">
          <h6 className="primary1 fnt-fam-f2 fw-bold">Supplier Response</h6>
          <Link
            to="/dc"
            className="text-white bg-dark px-1 border-0 fnt-fam-f2"
          >
            Delivery Challan
          </Link>
        </div>

        <label className="my-3 fnt-fam-f2">Supplier Response</label>
        <textarea
          className="border-btm"
          onChange={(e) => setResponse(e.target.value)}
          value={responce}
          disabled={responceBox ? false : true}
        ></textarea>
        <p className="d-flex justify-content-end opac-85">Max 250</p>
        <div className="d-flex mt-4 mb-1 justify-content-end gap-3">
          <button
            className="clear-can-btn border-0 rounded-1 fnt-fam-f2"
            style={{ color: "black" }}
            onClick={grnModalCloseHandler}
          >
            Close
          </button>
          {responceBox && (
            <button
              className="bg-primary1 bg-primary1 sub-app-btn text-white rounded-1 border-0 fnt-fam-f2"
              onClick={grnResponseAdd}
              disabled={btn ? true : false}
            >
              Save
            </button>
          )}
        </div>
      </Modal>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default GRNPage;
