import React, { useEffect, useState } from "react";
import { Col, Modal, Table } from "react-bootstrap";
import TableASN from "../../../component/ASN/TableASN";
import TopASN from "../../../component/ASN/TopASN";
import GeneralPagination from "../../../component/GeneralPagination";
import ASNList from "../../../component/PurchaseOrder/ASNList";
import DcList from "../../../component/PurchaseOrder/DcList";
import POFilter from "../../../component/PurchaseOrder/POFilter";
import LogInFooter from "../../../logincomponent/LogInFooter";
import TopBar from "../../../navbar/TopBar";
import TopHeader from "../../../navbar/TopHeader";
import { FaEllipsisV } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import ModalFilter from "../../../component/ASN/ModalFilter";
import { api } from "../../../constants/Services";
import ReactPaginate from "react-paginate";
import ErrorModel from "../../../constants/Services/Api/errorModel";
import Search from "../../../assets/Icons/Normal Icons/Search.svg";
import Filter from "../../../assets/Icons/Normal Icons/Filter.svg";
import moment from "moment/moment";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const DcListScreen = () => {
  const navigate = useNavigate();
  const permission = localStorage.getItem("permissions");
  const [showInd, setShowInd] = useState();
  const [activeBtn, setActiveBtn] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);
  const [activePages, setActivePages] = useState([]);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [listDraft, setListDraft] = useState([]);

  const [matType, setMatType] = useState();
  const [dcNo, setDcNo] = useState();
  const [khDivision, setKhDivision] = useState();

  const dropDownMenuClickHandler = () => {
    setShowModal(!showModal);
    setShowInd();
  };

  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);

  const dropHandler = (e) => {
    setShowInd(e);
    if (e == showInd) {
      setShowInd();
      console.log("1");
    }
  };

  const data = [
    {
      terms: "Accept",
    },
    {
      terms: "Accept with Comments",
    },
    {
      terms: "Decline with Comments",
    },
  ];

  const dummy = [
    {
      sNo: "01",
      kh: "GRUIO0987",
      ASN: "P00001",
      poId: "01",
      date: "01-12-2022",
      poRef: "PRF001",
      material_type: "Leather",
      poDate: "01-12-2022",
      status: "Delivered",
    },
    {
      sNo: "02",
      ASN: "P00002",
      kh: "GKJUYO0987",
      poId: "02",
      date: "02-12-2022",
      poRef: "PRF002",
      material_type: "Material",
      poDate: "02-12-2022",
      status: "Dispatch",
    },
    {
      sNo: "02",
      kh: "GTRIO0987",
      ASN: "P00002",
      poId: "02",
      date: "02-12-2022",
      poRef: "PRF002",
      material_type: "Leather",
      poDate: "02-12-2022",
      status: "Intransmit",
    },
    {
      sNo: "02",
      kh: "GRUIOT7876",
      ASN: "P00002",
      poId: "02",
      date: "02-12-2022",
      poRef: "PRF002",
      material_type: "Material",
      poDate: "02-12-2022",
      status: "Dispatch",
    },
  ];

  const getFilter = () => {
    var formdata = new FormData();
    formdata.append("material_type", matType);
    formdata.append("kh_division_id", khDivision);
    formdata.append("dc_no", dcNo);

    api.dcFilter(formdata).then((res) => {
      if (res?.status == "success") {
        setList(res?.asn_supplier_details);
        setActivePages(res);
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);
        setShowModal(false);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const ClearFilter = () => {
    setMatType("");
    setKhDivision("");
    setDcNo("");
    getList();
    setShowModal(false);
  };

  const getList = () => {
    let params;

    if (page) {
      params = `?page=${pages}`;
    }

    if (search !== "") {
      params = `?page=${page}` + `&dc_no=${search}`;
    }

    api.dcTables(params).then((res) => {
      if (res?.status == "success") {
        let temp = [];
        setList(res?.asn_supplier_details);
        res?.asn_supplier_details?.map((item, index) => {
          temp.push({
            FactoryID: item?.FactoryID,
            FactoryName: item?.FactoryName,
          });
        });
        const ids = temp.map(({ FactoryID }) => FactoryID);
        const filtered = temp.filter(
          ({ FactoryID }, index) => !ids.includes(FactoryID, index + 1)
        );
        setLists(filtered);
        setActivePages(res);
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });

    // LIST DRAFT
    api
      .listDcDraft()
      .then((res) => {
        if (res?.status == "success") {
          setListDraft(res?.drafts);
        } else {
          setError(res);
          setErrorBox(true);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const handlePageChange = (selectedObject) => {
    if (search !== "") {
      setPage(selectedObject.selected + 1);
    } else {
      setPages(selectedObject.selected + 1);
    }

    setCount(selectedObject.selected);
  };

  useEffect(() => {
    getList();
  }, [search, pages, page]);
  // KH division, DC num, DC date, Invoice no, PO no, material type, ETD, ETA, status.

  const [names, setName] = useState([]);
  const [counts, setCounts] = useState([0]);

  const onChangess = (e, ind) => {
    const name = e.target.name;
    const value = e.target.value;

    console.log("name", name);
    console.log("value", value);

    let temp = [...names];

    temp[ind][name] = value;

    // console.log("te", temp);

    setName(temp);
  };

  const onChanges = (e, index, ind_1) => {
    const value = e.target.value;
    setName((pre) => ({ ...pre, [`${index}${ind_1}`]: value }));
  };

  const addCounts = () => {
    let num = 0;
    let temp = [...counts];

    temp.push(num + 1);

    setCounts(temp);
  };

  // console.log("name", names);

  // return (
  //   <div className="App">
  //     {counts?.map((item, ind) => (
  //       <Table>
  //         <thead>
  //           <tr>
  //             <th>S.No</th>
  //             <th>KH</th>
  //             <th>No</th>
  //             <th>id</th>
  //             <th>Date</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {list?.map((items, index) => (
  //             <tr key={index}>
  //               <td>{items?.id}</td>
  //               <td>{items?.MaterialType}</td>
  //               <td>{items?.FactoryName}</td>
  //               <td>{items?.DCNNo}</td>
  //               <d>
  //                 <input
  //                   name="qty"
  //                   onChange={(e) => onChanges(e, ind, index)}
  //                   value={names[`${ind}${index}`]}
  //                 />
  //               </d>
  //             </tr>
  //           ))}
  //         </tbody>
  //       </Table>
  //     ))}
  //     <button onClick={addCounts}> Create Table </button>
  //   </div>
  // );

  return (
    <>
      <TopBar />
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopHeader type="dc-list" />
      <div className="invoice_list_new">
        <Tabs
          defaultActiveKey="list"
          id="uncontrolled-tab-example"
          className="mx-3"
          // onSelect={(e) => console.log("e", e)}
        >
          <Tab className="mb-2" eventKey="list" title="Submitted">
            <div className="enquiry-list-cont min-ht-80 py-2 position-relative">
              {/* <Col className="px-3 m-xxl-auto" xs={12} xxl={8}>  */}
              <Col className="px-5 m-xxl-auto" xs={12}>
                <div className="d-sm-flex justify-content-between mb-2 align-items-center w-100">
                  <div className="action-page-cont">
                    <p>Showing</p>
                    <div className="page-status-cont">
                      <p
                        style={{
                          border: "1px solid #ced4da",
                          padding: "4px",
                          margin: "10px 0px",
                          textAlign: "center",
                          width: "35px",
                          height: "28px",
                        }}
                      >
                        {activePages?.asn_supplier_details?.length}
                      </p>
                    </div>
                    <p>of {activePages?.pagination?.total} Delivery Challans</p>
                  </div>
                  <div className="d-flex mt-2 mt-sm-0">
                    <div className="search-box d-flex gap-2 align-items-center px-2 rounded-1">
                      <input
                        placeholder="Search Title"
                        className="w-90 h-90"
                        type={"number"}
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                      />
                      <img className="search-icon" src={Search} />
                    </div>
                    <button
                      className="filter-btn bg-primary2"
                      // onClick={onClickFilterHandler}
                      onClick={dropDownMenuClickHandler}
                    >
                      <img src={Filter} className="wid-15px me-1" />
                      Filter
                    </button>
                  </div>
                </div>
                {/* <DcList /> */}
                <div className="table-scroll-horizontal over-flow-x-scroll my-2 responsive-width min-ht-80 w-100">
                  <table className="mb-1 py-1 table">
                    <thead
                      style={{ background: "#ededed" }}
                      className="text-center"
                    >
                      <tr className="fnt-fam-f3 opac-85 border-0 text-center">
                        <th className="fs-14px">S.No.</th>
                        <th className="fs-14px text-nowrap">KH Division</th>
                        <th className="text-nowrap fs-14px">DC No.</th>
                        <th className="text-nowrap fs-14px">DC Date</th>
                        <th className="text-nowrap fs-14px">Invoice No.</th>
                        {/* <th className="text-nowrap fs-14px">PO No</th> */}
                        <th className="fs-14px text-nowrap">Material Type</th>
                        <th className="fs-14px">Status</th>
                        <th className="fs-14px text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody className="fs-6 opac-80 text-center">
                      {list?.map((item, ind) => {
                        return (
                          <tr key={ind}>
                            <td>
                              <p>
                                {" "}
                                {ind + 1 == 10 ? count + 1 : count}
                                {ind + 1 == 10 ? 0 : ind + 1}
                              </p>
                            </td>
                            <td className="text-nowrap">
                              <p>{item?.FactoryName}</p>
                            </td>
                            <td className="text-nowrap">
                              <p>{item?.DCNNo}</p>
                            </td>
                            <td className="text-nowrap">
                              {item?.DCDate
                                ? moment(item?.DCDate).format("DD-MMM-YYYY")
                                : ""}
                            </td>

                            <td className="text-nowrap">
                              <p>{item?.InvoiceNo}</p>
                            </td>
                            {/* <td>{item?.poRef}</td> */}
                            <td className="text-nowrap">
                              {item?.MaterialType == "M"
                                ? "Material"
                                : item?.MaterialType == "L"
                                ? "Leather"
                                : ""}
                            </td>
                            <td
                              className={
                                item?.Approval == 1
                                  ? "text-primary"
                                  : item?.Approval == 2
                                  ? "text-danger"
                                  : item?.Approval == 3
                                  ? "text-success"
                                  : item?.Approval == 4
                                  ? "text-primary"
                                  : ""
                              }
                            >
                              {item?.Approval == 1
                                ? "Transit"
                                : item?.Approval == 2
                                ? "Dispatch"
                                : item?.Approval == 3
                                ? "Delivered"
                                : item?.Approval == 4
                                ? "Completed"
                                : "-"}
                            </td>
                            {item?.status == "Intransmit" && (
                              <td className="text-center">
                                <p
                                  style={{
                                    border: "1px solid #0d6efd",
                                    marginTop: "5px",
                                  }}
                                  className="p-1 rounded-0 text-primary"
                                >
                                  {item?.status}
                                </p>
                              </td>
                            )}
                            {item?.status == "Dispatch" && (
                              <td className="text-center">
                                <p
                                  style={{
                                    border: "1px solid #e74c3c",
                                    marginTop: "5px",
                                  }}
                                  className="p-1 rounded-0 text-danger"
                                >
                                  {item?.status}
                                </p>
                              </td>
                            )}
                            {item?.status == "Delivered" && (
                              <td className="text-center">
                                <p
                                  style={{
                                    border: "1px solid #07bc0c",
                                    marginTop: "5px",
                                    color: "#07bc0c",
                                  }}
                                  className="p-1 rounded-0"
                                >
                                  {item?.status}
                                </p>
                              </td>
                            )}
                            <td className="position-relative text-center">
                              <button
                                onClick={() => dropHandler(ind)}
                                className="cust-btn dropdown-container-tool pt-1"
                              >
                                <FaEllipsisV />
                              </button>
                              {ind == showInd && (
                                <div
                                  style={{ zIndex: 2 }}
                                  className="po-card-drop-down1 text-start d-flex flex-column p-3 drop-downs"
                                >
                                  {permission?.includes(
                                    "EDIT-DELIVERYCHALLAN"
                                  ) && (
                                    <button
                                      className="asn_views text-nowrap pb-1"
                                      onClick={() =>
                                        navigate("/dc", {
                                          state: { dcData: item, type: "view" },
                                        })
                                      }
                                    >
                                      View
                                    </button>
                                  )}
                                  {permission?.includes(
                                    "EDIT-DELIVERYCHALLAN"
                                  ) && (
                                    <button
                                      className="asn_views text-nowrap pb-1"
                                      onClick={() =>
                                        navigate("/dc", {
                                          state: { dcData: item, type: "edit" },
                                        })
                                      }
                                    >
                                      Edit
                                    </button>
                                  )}
                                  {item?.TransportedBy == "sp" &&
                                    permission?.includes(
                                      "EDIT-DELIVERYCHALLAN"
                                    ) && (
                                      <button
                                        className="asn_views text-nowrap pb-1"
                                        onClick={() =>
                                          navigate("/dc", {
                                            state: {
                                              dcData: item,
                                              TransportedBy:
                                                item?.TransportedBy,
                                            },
                                          })
                                        }
                                      >
                                        Update Shipping Tracker
                                      </button>
                                    )}
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/* <ModalFilter setShowModal={setShowModal} showModal={showModal} /> */}
                </div>
                <div className="page-inner-cont py-2 d-flex justify-content-end">
                  <ReactPaginate
                    nextLabel="›"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="‹"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </Col>
              <Modal
                id="#verify"
                className="prod-filter"
                show={showModal}
                centered
                onHide={() => setShowModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
              >
                <Modal.Header closeButton>
                  <h5 className="mb-0">DC Filter</h5>
                </Modal.Header>
                <Modal.Body className="d-flex flex-wrap modal-inputs px-0">
                  <Col xs={6} lg={6}>
                    <div className="input-box">
                      <label>Material Type</label>
                      <select
                        onChange={(e) => setMatType(e.target.value)}
                        value={matType}
                      >
                        <option>Select Material Type</option>
                        <option value={"M"}>Material</option>
                        <option value={"L"}>Leather</option>
                        <option value={"M&L"}>Material & Leather</option>
                      </select>
                    </div>
                  </Col>
                  <Col xs={6} lg={6}>
                    <div className="input-box">
                      <label>DC No</label>
                      <select
                        onChange={(e) => setDcNo(e.target.value)}
                        value={dcNo}
                      >
                        <option>Select DC No</option>
                        {list?.map((item, i) => (
                          <option value={item?.DCNNo}>{item?.DCNNo}</option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col xs={12} lg={6}>
                    <div className="input-box">
                      <label>KH Division</label>
                      <select
                        onChange={(e) => setKhDivision(e.target.value)}
                        value={khDivision}
                      >
                        <option>Select KH Division</option>
                        {lists?.map((item, i) => (
                          <option value={item?.FactoryID}>
                            {item?.FactoryName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Modal.Body>
                <div className="d-flex justify-content-end">
                  <button
                    className="cust-btn mb-2 bg-transparent border-1 border me-1 text-dark"
                    onClick={ClearFilter}
                  >
                    Clear
                  </button>
                  <button
                    className="cust-btn mb-2 bg-primary1 me-1 text-white"
                    onClick={getFilter}
                  >
                    Apply
                  </button>
                </div>
              </Modal>
            </div>
          </Tab>
          <Tab className="mb-2" eventKey="draft" title="Draft">
            <div className="enquiry-list-cont min-ht-80 py-2 position-relative">
              {/* <Col className="px-3 m-xxl-auto" xs={12} xxl={8}>  */}
              <Col className="px-5 m-xxl-auto" xs={12}>
                {/* <div className="d-sm-flex justify-content-between mb-2 align-items-center w-100">
                  <div className="action-page-cont">
                    <p>Showing</p>
                    <div className="page-status-cont">
                      <p
                        style={{
                          border: "1px solid #ced4da",
                          padding: "4px",
                          margin: "10px 0px",
                          textAlign: "center",
                          width: "35px",
                          height: "28px",
                        }}
                      >
                        {activePages?.asn_supplier_details?.length}
                      </p>
                    </div>
                    <p>of {activePages?.pagination?.total} Delivery Challans</p>
                  </div>
                  <div className="d-flex mt-2 mt-sm-0">
                    <div className="search-box d-flex gap-2 align-items-center px-2 rounded-1">
                      <input
                        placeholder="Search Title"
                        className="w-90 h-90"
                        type={"number"}
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                      />
                      <img className="search-icon" src={Search} />
                    </div>
                    <button
                      className="filter-btn bg-primary2"
                      // onClick={onClickFilterHandler}
                      onClick={dropDownMenuClickHandler}
                    >
                      <img src={Filter} className="wid-15px me-1" />
                      Filter
                    </button>
                  </div>
                </div> */}
                <div className="table-scroll-horizontal over-flow-x-scroll my-2 responsive-width min-ht-80 w-100">
                  <table className="mb-1 py-1 table">
                    <thead
                      style={{ background: "#ededed" }}
                      className="text-center"
                    >
                      <tr className="fnt-fam-f3 opac-85 border-0 text-center">
                        <th className="fs-14px">S.No</th>
                        <th className="text-nowrap fs-14px">DC No</th>
                        <th className="text-nowrap fs-14px">DC Date</th>
                        <th className="text-nowrap fs-14px">Invoice No</th>
                        <th className="fs-14px">Material Type</th>
                        <th className="fs-14px text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody className="fs-6 opac-80 text-center">
                      {listDraft?.map((item, ind) => {
                        return (
                          <tr key={ind}>
                            <td>
                              <p>
                                {" "}
                                {ind + 1 == 10 ? count + 1 : count}
                                {ind + 1 == 10 ? 0 : ind + 1}
                              </p>
                            </td>
                            <td>
                              <p>{item?.draft?.dc_no}</p>
                            </td>
                            <td>
                              {item?.draft?.dc_date
                                ? moment(item?.draft?.dc_date).format(
                                    "DD-MMM-YYYY"
                                  )
                                : ""}
                            </td>

                            <td>
                              <p>{item?.draft?.invoice_no}</p>
                            </td>
                            <td>
                              {item?.draft?.mat_type == "M"
                                ? "Material"
                                : item?.draft?.mat_type == "L"
                                ? "Leather"
                                : ""}
                            </td>
                            <td className="position-relative text-center">
                              <button
                                onClick={() => dropHandler(ind)}
                                className="cust-btn dropdown-container-tool pt-1"
                              >
                                <FaEllipsisV />
                              </button>
                              {ind == showInd && (
                                <div
                                  style={{ zIndex: 2 }}
                                  className="po-card-drop-down1 text-start d-flex flex-column p-3 drop-downs"
                                >
                                  {permission?.includes(
                                    "EDIT-DELIVERYCHALLAN"
                                  ) && (
                                    <button
                                      className="asn_views text-nowrap pb-1"
                                      onClick={() =>
                                        navigate("/dc", {
                                          state: {
                                            dcData: item,
                                            type: "draft",
                                          },
                                        })
                                      }
                                    >
                                      Edit
                                    </button>
                                  )}
                                  {item?.TransportedBy == "sp" &&
                                    permission?.includes(
                                      "EDIT-DELIVERYCHALLAN"
                                    ) && (
                                      <button
                                        className="asn_views text-nowrap pb-1"
                                        onClick={() =>
                                          navigate("/dc", {
                                            state: {
                                              dcData: item,
                                              TransportedBy:
                                                item?.TransportedBy,
                                              type: "draft",
                                            },
                                          })
                                        }
                                      >
                                        Update Shipping Tracker
                                      </button>
                                    )}
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/* <ModalFilter setShowModal={setShowModal} showModal={showModal} /> */}
                </div>
                <div className="page-inner-cont py-2 d-flex justify-content-end">
                  <ReactPaginate
                    nextLabel="›"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="‹"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </Col>
              <Modal
                id="#verify"
                className="prod-filter"
                show={showModal}
                centered
                onHide={() => setShowModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
              >
                <Modal.Header closeButton>
                  <h5 className="mb-0">DC Filter</h5>
                </Modal.Header>
                <Modal.Body className="d-flex flex-wrap modal-inputs px-0">
                  <Col xs={6} lg={6}>
                    <div className="input-box">
                      <label>Material Type</label>
                      <select
                        onChange={(e) => setMatType(e.target.value)}
                        value={matType}
                      >
                        <option>Select Material Type</option>
                        <option value={"M"}>Material</option>
                        <option value={"L"}>Leather</option>
                        <option value={"M&L"}>Material & Leather</option>
                      </select>
                    </div>
                  </Col>
                  <Col xs={6} lg={6}>
                    <div className="input-box">
                      <label>DC No</label>
                      <select
                        onChange={(e) => setDcNo(e.target.value)}
                        value={dcNo}
                      >
                        <option>Select DC No</option>
                        {list?.map((item, i) => (
                          <option value={item?.DCNNo}>{item?.DCNNo}</option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col xs={12} lg={6}>
                    <div className="input-box">
                      <label>KH Division</label>
                      <select
                        onChange={(e) => setKhDivision(e.target.value)}
                        value={khDivision}
                      >
                        <option>Select KH Division</option>
                        {lists?.map((item, i) => (
                          <option value={item?.FactoryID}>
                            {item?.FactoryName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Modal.Body>
                <div className="d-flex justify-content-end">
                  <button
                    className="cust-btn mb-2 bg-transparent border-1 border me-1 text-dark"
                    onClick={ClearFilter}
                  >
                    Clear
                  </button>
                  <button
                    className="cust-btn mb-2 bg-primary1 me-1 text-white"
                    onClick={getFilter}
                  >
                    Apply
                  </button>
                </div>
              </Modal>
            </div>
          </Tab>
        </Tabs>{" "}
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
};

export default DcListScreen;
