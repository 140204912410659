import React, { useEffect } from "react";
import { useState } from "react";
import { Image, Pagination } from "react-bootstrap";
import { FaEllipsisV } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import Radio_icon from "../../assets/Icons/Normal Icons/RadioButton.svg";
import { FavLogo, Check } from "../../assets/img";
import { api } from "../../constants/Services";
import ErrorModel from "../../constants/Services/Api/errorModel";
import ModalFilter from "../ASN/ModalFilter";
import Search from "../../assets/Icons/Normal Icons/Search.svg";
import Filter from "../../assets/Icons/Normal Icons/Filter.svg";
import Select from "react-select";
import { chooseOptions } from "../../layout/dummyData";
import { Col, Modal } from "react-bootstrap";
import moment from "moment";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const ASNList = () => {
  const navigate = useNavigate();
  const permission = localStorage.getItem("permissions");
  const [showInd, setShowInd] = useState();
  const [activeBtn, setActiveBtn] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [list, setList] = useState([]);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pageCount, setPageCount] = useState(10);
  const [activePages, setActivePages] = useState([]);
  const [btn, setbtn] = useState(false);
  const [ShowFilterContent, setShowFilterContent] = useState(false);
  const [count, setCount] = useState(0);
  const [listDraft, setListDraft] = useState([]);

  const [matType, setMatType] = useState();
  const [asnNo, setAsnNo] = useState();
  const [khDivision, setKhDivision] = useState();
  const [lists, setLists] = useState([]);

  const khdivision1 = [];

  const dropHandler = (e) => {
    setShowInd(e);
    if (e == showInd) {
      setShowInd();
    }
  };
  const dropDownMenuClickHandler = () => {
    setShowModal(!showModal);
    setShowInd();
  };

  const dummy = [
    {
      sNo: "01",
      kh: "GRUIO0987",
      ASN: "P00001",
      poId: "01",
      date: "01-12-2022",
      poRef: "PRF001",
      material_type: "Leather",
      poDate: "01-12-2022",
      status: "Partially Delivered",
    },
    {
      sNo: "02",
      ASN: "P00002",
      kh: "GKJUYO0987",
      poId: "02",
      date: "02-12-2022",
      poRef: "PRF002",
      material_type: "Material",
      poDate: "02-12-2022",
      status: "Yet to Dispatch",
    },
    {
      sNo: "02",
      kh: "GTRIO0987",
      ASN: "P00002",
      poId: "02",
      date: "02-12-2022",
      poRef: "PRF002",
      material_type: "Leather",
      poDate: "02-12-2022",
      status: "Completed",
    },
    {
      sNo: "02",
      kh: "GRUIOT7876",
      ASN: "P00002",
      poId: "02",
      date: "02-12-2022",
      poRef: "PRF002",
      material_type: "Material",
      poDate: "02-12-2022",
      status: "Completed",
    },
    {
      sNo: "02",
      kh: "GRUIOT7876",
      ASN: "P00002",
      poId: "02",
      date: "02-12-2022",
      poRef: "PRF002",
      material_type: "Material",
      poDate: "02-12-2022",
      status: "Completed",
    },
    {
      sNo: "02",
      kh: "GRUIOT7876",
      ASN: "P00002",
      poId: "02",
      date: "02-12-2022",
      poRef: "PRF002",
      material_type: "Material",
      poDate: "02-12-2022",
      status: "Completed",
    },
    {
      sNo: "02",
      kh: "GRUIOT7876",
      ASN: "P00002",
      poId: "02",
      date: "02-12-2022",
      poRef: "PRF002",
      material_type: "Material",
      poDate: "02-12-2022",
      status: "Completed",
    },
    {
      sNo: "02",
      kh: "GRUIOT7876",
      ASN: "P00002",
      poId: "02",
      date: "02-12-2022",
      poRef: "PRF002",
      material_type: "Material",
      poDate: "02-12-2022",
      status: "Completed",
    },
    {
      sNo: "02",
      kh: "GRUIOT7876",
      ASN: "P00002",
      poId: "02",
      date: "02-12-2022",
      poRef: "PRF002",
      material_type: "Material",
      poDate: "02-12-2022",
      status: "Completed",
    },
    {
      sNo: "02",
      kh: "GRUIOT7876",
      ASN: "P00002",
      poId: "02",
      date: "02-12-2022",
      poRef: "PRF002",
      material_type: "Material",
      poDate: "02-12-2022",
      status: "Completed",
    },
    {
      sNo: "02",
      kh: "GRUIOT7876",
      ASN: "P00002",
      poId: "02",
      date: "02-12-2022",
      poRef: "PRF002",
      material_type: "Material",
      poDate: "02-12-2022",
      status: "Completed",
    },
    {
      sNo: "02",
      kh: "GRUIOT7876",
      ASN: "P00002",
      poId: "02",
      date: "02-12-2022",
      poRef: "PRF002",
      material_type: "Material",
      poDate: "02-12-2022",
      status: "Completed",
    },
    {
      sNo: "02",
      kh: "GRUIOT7876",
      ASN: "P00002",
      poId: "02",
      date: "02-12-2022",
      poRef: "PRF002",
      material_type: "Material",
      poDate: "02-12-2022",
      status: "Completed",
    },
    {
      sNo: "02",
      kh: "GRUIOT7876",
      ASN: "P00002",
      poId: "02",
      date: "02-12-2022",
      poRef: "PRF002",
      material_type: "Material",
      poDate: "02-12-2022",
      status: "Completed",
    },
  ];
  const data = [
    {
      terms: "Accept",
    },
    {
      terms: "Accept with Comments",
    },
    {
      terms: "Decline with Comments",
    },
  ];

  const getList = () => {
    let params;

    if (search !== "") {
      // params = `?page=${page}&asn_no=${search}`;
      params = `?page=${page}&search=${search}`;
    } else {
      params = `?page=${pages}`;
    }

    api.asnList(params).then((res) => {
      if (res?.status == "success") {
        let temp = [];
        console.log(res?.asn_lists, 'res?.asn_lists');
        setList(res?.asn_lists);
        setActivePages(res);
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);
        res?.asn_lists?.map((item, ind) => {
          temp.push({
            FactoryID: item?.FactoryID,
            FactoryName: item?.FactoryName,
          });
        });
        const idsaward = temp.map(({ FactoryID }) => FactoryID);
        const filteredaward = temp.filter(
          ({ FactoryID }, index) => !idsaward.includes(FactoryID, index + 1)
        );
        setLists(filteredaward);
        // setPageCount(res?.asn_lists?.total / res?.asn_lists?.per_page);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });

    // LIST DRAFT
    api
      .listAsnDraft()
      .then((res) => {
        if (res?.status == "success") {
          let temp = [];
          res?.drafts?.map((item, ind) => {
            if (item?.draft?.list) {
              let json = JSON.parse(item?.draft?.list);
              // console.log("json", json);
              if (json) {
                temp.push({
                  ...item,
                  ...json,
                  created_at: item?.created_at,
                  id: item?.id,
                });
              }
            }
          });

          // console.log("temp", temp);

          setListDraft(temp);
        } else {
          setError(res);
          setErrorBox(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getFilter = () => {
    let params = `?material_type=${matType}`;

    if (khDivision) {
      params = params + `&kh_division_id=${khDivision}`;
    }

    if (asnNo) {
      params = params + `&search=${asnNo}`;
    }
    api.asnFilter(params).then((res) => {
      if (res?.status == "success") {
        setShowModal(!showModal);
        setList(res?.asn_lists);
        setActivePages(res);
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const getLists = () => {
    let params = `?page=${pages}`;

    if (search) {
      params = params + `&search=${search}`;
    }

    setbtn(true);
    api.purchaseOrderList(params).then((res) => {
      if (res?.status == "success") {
        setbtn(false);
        setShowFilterContent(false);
        setActivePages(res);
        setList(res?.purchase_orders);
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);
      } else {
        setError(res);
        setbtn(false);
        setErrorBox(true);
        setShowFilterContent(false);
      }
    });
  };

  const [pur_type, setPur_type] = useState();
  const [pur_status, setPur_status] = useState();
  const [pur_date, setPur_date] = useState();

  const getFilters = () => {
    let params = `?page=${pages}`;

    if (pur_type) {
      params = params + `&type=${pur_type}`;
    }

    if (pur_status) {
      params = params + `&status=${pur_status}`;
    }

    if (pur_date) {
      params = params + `&factory_name=${pur_date}`;
    }

    setbtn(true);
    api.purchaseOrderList(params).then((res) => {
      if (res?.status == "success") {
        setList(res?.purchase_orders);
        setbtn(false);
        setShowFilterContent(false);
        setActivePages(res?.pagination);
        // setList(res?.purchase_orders);
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);
      } else {
        setError(res);
        setbtn(false);
        setErrorBox(true);
        setShowFilterContent(false);
      }
    });
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const handlePageChange = (selectedObject) => {
    if (search !== "") {
      setPage(selectedObject.selected + 1);
    } else {
      setPages(selectedObject.selected + 1);
    }
    setCount(selectedObject.selected);
  };

  useEffect(() => {
    getList();
  }, [search, pages]);

  console.log("listDraft", listDraft);

  const renderedRadioButtons = data.map((item, index) => {
    return (
      <button
        key={index}
        onClick={() => setActiveBtn(index)}
        className="d-flex align-items-center border-0 bg-transparent p-0 text-nowrap po-card-radio-btn"
      >
        <div className="radio-btn">
          {activeBtn == index ? (
            <img src={Radio_icon} className="wid-15px" alt="radio-icon" />
          ) : (
            <div
              className="radio-btn-inner"
              style={{
                background: "#fff",
              }}
            ></div>
          )}
        </div>
        {item.terms}
      </button>
    );
  });

  // DELETE DRAFT
  const deleteDrafts = (id, resp) => {
    let data = {
      draft_id: id,
    };
    api.deleteDraft(data).then((res) => {
      if (res?.status === "success") {
        setShowInd();
        getList();
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };
  console.log(listDraft, "listDraft")
  return (
    // ->KH division, ASN number, date, po number, materail type, no of shipments, status.
    <div className="invoice_list_new">
      <Tabs
        defaultActiveKey="list"
        id="uncontrolled-tab-example"
        className="mx-3"
      // onSelect={(e) => console.log("e", e)}
      >
        <Tab className="mb-2" eventKey="list" title="Submitted">
          {" "}
          <div>
            <div
              className="d-sm-flex justify-content-between mb-2 align-items-center"
              style={{ minWidth: "80%" }}
            >
              <div className="d-flex-wrap align-items-center gap-2 mb-0">
                {/* <label>Show</label>
          <select
            name=""
            className="cont-border px-2 py-1"
            // onChange={setListItems}
          >
            <option value="6">6</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
          <label>
            1-{"10"} of {"20"}
          </label> */}
                <div className="action-page-cont">
                  <p>Showing</p>
                  <div className="page-status-cont">
                    <p
                      style={{
                        border: "1px solid #ced4da",
                        padding: "4px",
                        margin: "10px 0px",
                        textAlign: "center",
                        width: "35px",
                        height: "28px",
                      }}
                    >
                      {activePages?.asn_lists?.length}
                    </p>
                  </div>
                  <p>of {activePages?.pagination?.total} ASN</p>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-2 mt-sm-0">
                <div className="search-box d-flex gap-2 align-items-center px-2 rounded-1">
                  <input
                    placeholder="Search Title"
                    className="w-90 h-90"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    type={"number"}
                  />
                  <img className="search-icon" src={Search} />
                </div>
                <button
                  className="filter-btn bg-primary2"
                  onClick={() => setShowModal(true)}
                >
                  <img src={Filter} className="wid-15px me-1" />
                  Filter
                </button>
              </div>
            </div>
            <div
              style={{ minHeight: "70vh" }}
              className="table-scroll-horizontal over-flow-x-scroll my-2 responsive-width w-100"
            >
              {errorBox && (
                <ErrorModel
                  error={error}
                  errorBox={errorBox}
                  errorModalClose={errorModalClose}
                />
              )}
              <table className="mb-1 py-1 table">
                <thead style={{ background: "#ededed" }}>
                  <tr className="fnt-fam-f3 opac-85 border-0 text-center">
                    <th className="fs-14px">Sl.No.</th>
                    <th className="text-nowrap fs-14px">ASN Date</th>
                    <th className="fs-14px text-nowrap">KH Division</th>
                    {/* <th className="fs-14px">ASN Number</th> */}
                    <th className="text-nowrap fs-14px">PO. Ref. No.</th>
                    <th className="text-nowrap fs-14px">Material Type</th>
                    <th className="fs-14px text-nowrap">No. of Shipments</th>
                    {/* <th className="fs-14px">Status</th> */}
                    <th className="fs-14px">Action</th>
                  </tr>
                </thead>
                <tbody className="fs-6 opac-80">
                  {list?.map((item, ind) => {
                    return (
                      <tr key={ind} className="text-center">
                        <td>
                          <p>
                            {" "}
                            {ind + 1 == 10 ? count + 1 : count}
                            {ind + 1 == 10 ? 0 : ind + 1}
                          </p>
                        </td>
                        <td>
                          <p className="text-nowrap">
                            {item?.purchase_order_date
                              ? moment(item?.purchase_order_date).format(
                                "DD-MMM-YYYY"
                              )
                              : ""}
                          </p>
                        </td>
                        <td>
                          <p>{item?.FactoryName}</p>
                        </td>
                        {/* <td>
                    <p>{item?.ASNNo}</p>
                  </td> */}
                        <td>
                          {item?.MaterialPurchaseOrderNo ||
                            item?.LeatherPurchaseOrderNo ||
                            item?.purchase_order_no}
                        </td>
                        <td>
                          <p>
                            {item?.MaterialType
                              ? item?.MaterialType
                              : item?.type == "M"
                                ? "Material"
                                : "Leather"}
                          </p>
                        </td>
                        <td>{item?.shipping_count}</td>
                        {item?.status == "Yet to Dispatch" && (
                          <td className="text-center">
                            <p className="p-1 rounded-0 text-primary">
                              {item?.status}
                            </p>
                          </td>
                        )}
                        {item?.status == "Partially Delivered" && (
                          <td className="text-center">
                            <p className="p-1 rounded-0 text-danger">
                              {item?.status}
                            </p>
                          </td>
                        )}{" "}
                        {/* {item?.status == "Inprogress" && ( */}
                        {/* <td className="text-center">
                    <button
                      // style={{ border: "1px solid #0d6efd" }}
                      className={
                        item?.status == 1
                          ? "btn btn-outline-warning"
                          : item?.status == 2
                          ? "btn btn-outline-success"
                          : item?.status == 3
                          ? "btn btn-outline-primary"
                          : ""
                      }
                    >
                      {item?.status == 1
                        ? "Yet to Dispatch"
                        : item?.status == 2
                        ? "Completed"
                        : item?.status == 3
                        ? "Partially Delivered"
                        : ""}
                    </button>
                  </td> */}
                        {/* <td
                          className={
                            item?.Sup_Approval == "A "
                              ? "text-success"
                              : item?.Sup_Approval == "P "
                              ? "text-warning"
                              : item?.Sup_Approval == "D "
                              ? "text-danger"
                              : item?.Sup_Approval == "C "
                              ? "text-success"
                              : "text-warning"
                          }
                        >
                          {item?.Sup_Approval == "A "
                            ? "Accepted"
                            : item?.Sup_Approval == "P "
                            ? "Pending"
                            : item?.Sup_Approval == "D "
                            ? "Decline"
                            : item?.Sup_Approval == "C "
                            ? "Accepetd"
                            : "Yet to Acknowledge"}
                        </td> */}
                        <td className="position-relative text-center">
                          <button
                            onClick={() => dropHandler(ind)}
                            className="cust-btn dropdown-container-tool pt-1"
                          >
                            <FaEllipsisV />
                          </button>
                          {ind == showInd && (
                            <div
                              style={{ zIndex: 2 }}
                              className="po-card-drop-down1 text-start d-flex flex-column p-3 drop-downs"
                            >
                              {permission?.includes(
                                "VIEW-ADVANCESHIPPINGNOTE"
                              ) && (
                                  <button
                                    // to="/purchase-order/view"
                                    // onClick={dropDownMenuClickHandler}
                                    onClick={() =>
                                      navigate("/asn/view", {
                                        state: { table: item, type: "view" },
                                      })
                                    }
                                    className="asn_views text-nowrap pb-1"
                                  >
                                    View ASN
                                  </button>
                                )}
                              {permission?.includes(
                                "EDIT-ADVANCESHIPPINGNOTE"
                              ) && (
                                  <button
                                    className="asn_views text-nowrap pb-1"
                                    // onClick={dropDownMenuClickHandler}
                                    onClick={() =>
                                      navigate("/asn/view", {
                                        state: { table: item, type: "edit" },
                                      })
                                    }
                                  >
                                    Edit ASN
                                  </button>
                                )}
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="page-inner-cont py-2 d-flex justify-content-end">
              <ReactPaginate
                nextLabel="›"
                onPageChange={handlePageChange}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="‹"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
            {/* </div> */}
            <Modal
              id="#verify"
              className="prod-filter"
              show={showModal}
              centered
              onHide={() => {
                setShowModal(false);
                setKhDivision("");
                setAsnNo("");
                setMatType("");
                getList();
              }}
              aria-labelledby="contained-modal-title-vcenter"
            >
              <Modal.Header closeButton>
                <h5 className="mb-0">ASN Filter</h5>
              </Modal.Header>
              <Modal.Body className="d-flex flex-wrap modal-inputs px-0">
                <Col xs={6} lg={6}>
                  <div className="input-box">
                    <label>Material Type</label>
                    <select
                      onChange={(e) => setMatType(e.target.value)}
                      value={matType}
                    >
                      <option>Select Material Type</option>
                      <option value={"M"}>Material</option>
                      <option value={"L"}>Leather</option>
                      <option value={"M&L"}>Material & Leather</option>
                    </select>
                  </div>
                </Col>
                <Col xs={6} lg={6}>
                  <div className="input-box">
                    <label>ASN No</label>
                    <select
                      onChange={(e) => setAsnNo(e.target.value)}
                      value={asnNo}
                    >
                      <option>Select ASN No</option>
                      {list?.map((item, i) => (
                        <option value={item?.purchase_order_no}>
                          {item?.purchase_order_no}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col xs={12} lg={6}>
                  <div className="input-box">
                    <label>KH Division</label>
                    <select
                      onChange={(e) => setKhDivision(e.target.value)}
                      value={khDivision}
                    >
                      <option>Select KH Division</option>
                      {list?.map((item, i) => {
                        if (!khdivision1.includes(String(item?.FactoryID))) {
                          khdivision1.push(String(item?.FactoryID));
                          return (
                            <option value={item?.FactoryID}>
                              {item?.FactoryName}
                            </option>
                          )
                        }
                      })}
                    </select>
                  </div>
                </Col>
              </Modal.Body>
              <div className="d-flex justify-content-end">
                <button
                  className="cust-btn mb-2 bg-transparent border-1 border me-1 text-dark"
                  onClick={() => {
                    setShowModal(!showModal);
                    setKhDivision("");
                    setAsnNo("");
                    setMatType("");
                    getList();
                  }}
                >
                  Clear
                </button>
                <button
                  className="cust-btn mb-2 bg-primary1 me-1 text-white"
                  onClick={getFilter}
                >
                  Apply
                </button>
              </div>
            </Modal>
            <Modal
              id="#verify"
              className="prod-filter"
              show={ShowFilterContent}
              centered
              onHide={() => setShowFilterContent(false)}
              aria-labelledby="contained-modal-title-vcenter"
            >
              <Modal.Header closeButton>
                <h5 className="mb-0">ASN Filter</h5>
              </Modal.Header>
              <Modal.Body className="d-flex flex-wrap modal-inputs px-0">
                <Col xs={6} lg={6}>
                  <div className="input-box">
                    <label>Material Type</label>
                    <select
                      onChange={(e) => setPur_type(e.target.value)}
                      value={pur_type}
                    >
                      <option value="">Select Purchase Order Type</option>
                      <option value="M">Material</option>
                      <option value="L">Leather</option>
                    </select>
                  </div>
                </Col>
                <Col xs={6} lg={6}>
                  <div className="input-box">
                    <label>ASN Status</label>
                    <select
                      onChange={(e) => setPur_status(e.target.value)}
                      value={pur_status}
                    >
                      <option>Select ASN Status</option>
                      {list?.map((item, i) => (
                        <option value={item?.Approval}>
                          {item?.Approval == "A"
                            ? "Accepted"
                            : item?.Approval == "P"
                              ? "Pending"
                              : item?.Approval == "D"
                                ? "Declined"
                                : item?.Approval == "C"
                                  ? "Declined"
                                  : null}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col xs={12} lg={6}>
                  <div className="input-box">
                    <label>KH Division</label>
                    <select
                      onChange={(e) => setPur_date(e.target.value)}
                      value={pur_date}
                    >
                      <option>Select KH Division</option>
                      {lists?.map((item, i) => {
                        return (
                          <option value={item?.FactoryID} key={i}>
                            {item?.FactoryName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Col>
              </Modal.Body>
              <div className="d-flex justify-content-end">
                <button
                  className="cust-btn mb-2 bg-transparent border-1 border me-1 text-dark"
                  onClick={() => {
                    setPur_date("");
                    setPur_status("");
                    setPur_type("");
                    getList();
                    setShowFilterContent(false);
                  }}
                >
                  Clear
                </button>
                <button
                  className="cust-btn mb-2 bg-primary1 me-1 text-white"
                  onClick={getFilter}
                >
                  Apply
                </button>
              </div>
            </Modal>
          </div>
        </Tab>
        <Tab className="mb-2" eventKey="draft" title="Draft">
          {" "}
          <div>
            <div
              style={{ minHeight: "70vh" }}
              className="table-scroll-horizontal over-flow-x-scroll my-2 responsive-width w-100 mt-5"
            >
              {errorBox && (
                <ErrorModel
                  error={error}
                  errorBox={errorBox}
                  errorModalClose={errorModalClose}
                />
              )}
              <table className="mb-1 py-1 table">
                <thead style={{ background: "#ededed" }}>
                  <tr className="fnt-fam-f3 opac-85 border-0 text-center">
                    <th className="fs-14px">Sl.No</th>
                    <th className="text-nowrap fs-14px">PO. Ref. No</th>
                    <th className="text-nowrap fs-14px">Material Type</th>
                    <th className="text-nowrap fs-14px">PO Date</th>
                    <th className="text-nowrap fs-14px">Created Date</th>
                    <th className="fs-14px">Action</th>
                  </tr>
                </thead>
                <tbody className="fs-6 opac-80">
                  {listDraft?.map((item, ind) => {
                    return (
                      <tr key={ind} className="text-center">
                        <td>
                          <p>
                            {" "}
                            {ind + 1 == 10 ? count + 1 : count}
                            {ind + 1 == 10 ? 0 : ind + 1}
                          </p>
                        </td>
                        <td>
                          {item?.purchase_order?.MaterialPurchaseOrderNo ||
                            item?.purchase_order?.LeatherPurchaseOrderNo}
                        </td>
                        <td>
                          <p>
                            {item?.purchase_order?.MaterialPurchaseOrderNo
                              ? "Material"
                              : "Leather"}
                          </p>
                        </td>

                        <td>
                          <p className="text-nowrap">
                            {" "}
                            {item?.purchase_order?.MaterialPurchaseOrderDate
                              ? moment(
                                item?.purchase_order
                                  ?.MaterialPurchaseOrderDate
                              ).format("DD-MMM-YYYY")
                              : item?.purchase_order?.LeatherPurchaseOrderDate
                                ? moment(
                                  item?.purchase_order?.LeatherPurchaseOrderDate
                                ).format("DD-MMM-YYYY")
                                : item?.leather_purchase_order_detail_del_date
                                  ?.leather_purchase_order_detail
                                  ?.purchase_order?.LeatherPurchaseOrderDate
                                  ? moment(
                                    item?.leather_purchase_order_detail_del_date
                                      ?.leather_purchase_order_detail
                                      ?.purchase_order?.LeatherPurchaseOrderDate
                                  ).format("DD-MMM-YYYY")
                                  : item?.material_purchase_order_detail_del_date
                                    ?.material_purchase_order_detail
                                    ?.purchase_order?.MaterialPurchaseOrderDate
                                    ? moment(
                                      item?.material_purchase_order_detail_del_date
                                        ?.material_purchase_order_detail
                                        ?.purchase_order?.MaterialPurchaseOrderDate
                                    ).format("DD-MMM-YYYY")
                                    : ""}
                          </p>
                        </td>

                        <td>
                          <p className="text-nowrap">
                            {item?.created_at
                              ? moment(item?.created_at).format("DD-MMM-YYYY")
                              : ""}
                          </p>
                        </td>

                        <td className="position-relative text-center">
                          <button
                            onClick={() => dropHandler(ind)}
                            className="cust-btn dropdown-container-tool pt-1"
                          >
                            <FaEllipsisV />
                          </button>
                          {ind == showInd && (
                            <div
                              style={{ zIndex: 2 }}
                              className="po-card-drop-down1 text-start d-flex flex-column p-3 drop-downs"
                            >
                              {/* {permission?.includes(
                                "VIEW-ADVANCESHIPPINGNOTE"
                              ) && (
                                <button
                                  onClick={() =>
                                    navigate("/asn", {
                                      state: { table: item, type: "add" },
                                    })
                                  }
                                  className="asn_views text-nowrap pb-1"
                                >
                                  View ASN
                                </button>
                              )} */}
                              {permission?.includes(
                                "EDIT-ADVANCESHIPPINGNOTE"
                              ) && (
                                  <button
                                    className="asn_views text-nowrap pb-1"
                                    onClick={() =>
                                      navigate("/asn", {
                                        state: { table: item, type: "add" },
                                      })
                                    }
                                  >
                                    Edit ASN
                                  </button>
                                )}
                              <button
                                className="asn_views text-nowrap pb-1"
                                onClick={() => deleteDrafts(item?.id, item)}
                              >
                                Delete ASN
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="page-inner-cont py-2 d-flex justify-content-end">
              <ReactPaginate
                nextLabel="›"
                onPageChange={handlePageChange}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="‹"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ASNList;
