import TopHeader from "../../navbar/TopHeader";
import TopBar from "../../navbar/TopBar";
import { Col } from "react-bootstrap";
import Filter_icon from "../../assets/Icons/Normal Icons/Filter.svg";
import Search_icon from "../../assets/Icons/Normal Icons/Search.svg";
function BroadCastList() {
  return (
    <>
      <TopBar />
      <TopHeader type="broadcast" />
      <Col sm={12} lg={10} xxl={8} className="mx-auto m-3 p-3">
        <div className="d-flex justify-content-md-center flex-wrap flex-md-nowrap mt-2 mt-sm-0">
          <div
            className="search-box w-100 d-flex flex-wrap align-items-center px-2 rounded-1"
            style={{ maxWidth: "700px" }}>
            <input placeholder="Search BroadCast" className="w-90" />
            <img className="search-icon" src={Search_icon} alt="search-icon" />
          </div>
          <button
            className="filter-btn bg-primary2 my-2 my-md-0"
            // onClick={onClickFilterHandler}
          >
            <img
              src={Filter_icon}
              className="wid-15px me-1"
              alt="filter-icon"
            />
            Filter
          </button>
        </div>
        <h5 style={{ maxWidth: "807px" }} className="font-col-sec mt-2 mx-auto">
          BroadCast
        </h5>
        <div
          style={{ maxWidth: "807px" }}
          className="d-flex justify-content-md-center mx-auto my-3 flex-wrap">
          <Col xs={12} md={6} lg={4} className="lin-rit-profile p-2">
            <h5 className="primary2 my-2">Quotaion</h5>
            <h6>Lorem lipsum</h6>
            <p className="fs-13 fnt-fam-f1 py-1 ">
              The most underrated productive Techinique is also the simplest
            </p>
            <p className="opac_half fnt-fam-f1 fs-13 pt-1 pb-3 telephone-input-box ">
              Dec 10 2020
            </p>
            <h5 className="primary2 my-2">Quotaion</h5>
            <h6>Lorem lipsum</h6>
            <p className="fs-13 fnt-fam-f1 py-1 ">
              The most underrated productive Techinique is also the simplest
            </p>
            <p className="opac_half fnt-fam-f1 fs-13 pt-1 pb-3 telephone-input-box ">
              Dec 10 2020
            </p>
            <h5 className="primary2 my-2">Quotaion</h5>
            <h6>Lorem lipsum</h6>
            <p className="fs-13 fnt-fam-f1 py-1 ">
              The most underrated productive Techinique is also the simplest
            </p>
            <p className="opac_half fnt-fam-f1 fs-13 pt-1 pb-3 telephone-input-box ">
              Dec 10 2020
            </p>
          </Col>
          <Col xs={12} md={6} lg={4} className="lin-rit-profile p-2">
            <h5 className="primary2 my-2">Quotaion</h5>
            <h6>Lorem lipsum</h6>
            <p className="fs-13 fnt-fam-f1 py-1 ">
              The most underrated productive Techinique is also the simplest
            </p>
            <p className="opac_half fnt-fam-f1 fs-13 pt-1 pb-3 telephone-input-box ">
              Dec 10 2020
            </p>
            <h5 className="primary2 my-2">Quotaion</h5>
            <h6>Lorem lipsum</h6>
            <p className="fs-13 fnt-fam-f1 py-1 ">
              The most underrated productive Techinique is also the simplest
            </p>
            <p className="opac_half fnt-fam-f1 fs-13 pt-1 pb-3 telephone-input-box ">
              Dec 10 2020
            </p>
            <h5 className="primary2 my-2">Quotaion</h5>
            <h6>Lorem lipsum</h6>
            <p className="fs-13 fnt-fam-f1 py-1 ">
              The most underrated productive Techinique is also the simplest
            </p>
            <p className="opac_half fnt-fam-f1 fs-13 pt-1 pb-3 telephone-input-box ">
              Dec 10 2020
            </p>
          </Col>
          <Col xs={12} md={6} lg={4} className="p-2">
            <h5 className="primary2 my-2">Quotaion</h5>
            <h6>Lorem lipsum</h6>
            <p className="fs-13 fnt-fam-f1 py-1 ">
              The most underrated productive Techinique is also the simplest
            </p>
            <p className="opac_half fnt-fam-f1 fs-13 pt-1 pb-3 telephone-input-box ">
              Dec 10 2020
            </p>
            <h5 className="primary2 my-2">Quotaion</h5>
            <h6>Lorem lipsum</h6>
            <p className="fs-13 fnt-fam-f1 py-1 ">
              The most underrated productive Techinique is also the simplest
            </p>
            <p className="opac_half fnt-fam-f1 fs-13 pt-1 pb-3 telephone-input-box ">
              Dec 10 2020
            </p>
            <h5 className="primary2 my-2">Quotaion</h5>
            <h6>Lorem lipsum</h6>
            <p className="fs-13 fnt-fam-f1 py-1 ">
              The most underrated productive Techinique is also the simplest
            </p>
            <p className="opac_half fnt-fam-f1 fs-13 pt-1 pb-3 telephone-input-box ">
              Dec 10 2020
            </p>
          </Col>
        </div>
      </Col>
    </>
  );
}

export default BroadCastList;
