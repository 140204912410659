import LogInFooter from "../../logincomponent/LogInFooter";
import QuoLastSectionBtn from "../../component/QuoLasSecBtn";
import React, { useEffect, useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { FavLogo, Check, Error } from "../../assets/img";
import { Link, useLocation } from "react-router-dom";
import ErrorModel from "../../constants/Services/Api/errorModel";
// import InvoiceFilterSec from "./InvoiceFilterSec";
import InvoiceTableContent from "./InvoiceTablePage";
import Invoice2ndTableSection from "./InvoicePage2ndTableSec";
import { useNavigate } from "react-router-dom";
// import InvoicePaginationSection from "./InvoicePaginationSec";
import { Col, Image, Modal, Button } from "react-bootstrap";
import TopHeader from "../../navbar/TopHeader";
import TopBar from "../../navbar/TopBar";
import { api } from "../../constants/Services";
import Select from "react-select";
import DatePicker from "../../layout/datePicker";
import moment from "moment/moment";

function InvoicePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location?.state?.data;
  const type = location?.state?.type;
  const upload_details = location?.state?.checkboxs;
  const Proforma = location?.state?.Proforma;
  const commercial = location?.state?.commercial;

  // INVOCE TABLE TO VIEW

  const detail = location?.state?.item;
  const method = location?.state?.method;
  const [options, setoptions] = useState([]);
  const [optionChange, setOptionChange] = useState([]);
  const [option, setoption] = useState([]);
  const [optionChanges, setOptionChanges] = useState([]);
  const [noNames, setNoNames] = useState(false);
  const [input, setInput] = useState([]);
  const [currency, setCurrency] = useState("");
  const [noName, setNoName] = useState({});
  const [alreadyInvoiceAmount, setAlreadyInvoicedAmount] = useState("");
  const [qty, setQty] = useState([]);
  const [quantityError, setQuantityError] = useState(false);
  const [isGst, setIsgst] = useState(null);
  const [taxList, setTaxList] = useState([]);
  const [page, setPage] = useState({
    curren_page: 1,
    total_page: 0,
  });

  const [subcharge, setSubcharge] = useState("");
  const [totalTableAmount, setTotalTableAmount] = useState();
  const [numToWords, setNumToWords] = useState("");
  const [addcharge, setAddcharge] = useState("");
  const [percen, setPercen] = useState(100);
  const [invError, setInvError] = useState([]);
  const [bankModal, setBankModal] = useState(false);
  // const [orderId, setOrderID] = useState([]);
  const [bankDetails, setBankDetails] = useState([]);
  const [displayBank, setDisplayBank] = useState();
  const [selectedBank, setSelectedBank] = useState(null);
  const [inVRef, setInvRef] = useState("");
  const [poValue, setPoValue] = useState("");
  const [invDate, setInvDate] = useState(new Date());
  const [selTable, setSelTable] = useState([]);
  const [userTax, setUseTax] = useState([]);
  const [taxTotal, setTaxTotal] = useState(0);
  const [invAmount, setInvAmount] = useState("");
  const [invAmounts, setInvAmounts] = useState("");
  const [tableCheck, setTableCheck] = useState("");
  const [invState, setInvState] = useState("");
  const [terms, setTerms] = useState("");
  const [issuesOn, setIssuesOn] = useState(new Date());
  const [poCheck, setPoCheck] = useState([]);
  const [dcCheck, setDcCheck] = useState([]);
  const [dcDrop, setDcDrop] = useState(false);
  const [dcGetDetails, setDcGetDetails] = useState([]);
  const [dcDropDetails, setDcDropDetails] = useState([]);
  const [drop, setDrop] = useState(false);
  const [showInvModal, setShowInvModal] = useState(false);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [tableTwo, setTableTwo] = useState([]);
  const [poQuantity, setPoQuantity] = useState([]);
  const [finalTable, setFinalTable] = useState([]);
  const [currencyDisplay, setCurrencyDisplay] = useState({});
  const [per_centageFlag, setPer_centageFlag] = useState(false);
  const [invoicedDetails, setInvoicedDetails] = useState([]);

  // ARRAY NEW

  // console.log("option", option);

  // console.log("currencyDisplay", currencyDisplay);

  const [taxArr, setTaxArr] = useState([]);
  const [tax_total_amount, setTax_total_amount] = useState();

  const getLists = () => {
    api.createDetails().then((res) => {
      console.log(res, "ress");
      if (res?.status == "success") {
        setInput(res);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const get_tax_details = () => {
    api.is_igst().then((res) => {
      if (res.status === "success") {
        setIsgst(res?.igst);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });

    api.tax_list().then((res) => {
      if (res?.status === "success") {
        setTaxList(res?.tax_list);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    get_tax_details();
    getLists();
  }, []);

  // VIEW AND EDIT

  const getView = () => {
    api.invoiceView(detail?.id).then((resp) => {
      if (resp?.status == "success") {
        console.log(resp, 'resp');
        let res = resp?.invoice;
        let temp = [];
        let check = [];
        let option = [];
        let total_price = [];
        setTableTwo(resp?.invoice_details);
        setInvoicedDetails(resp?.invoiced_details);
        setInvRef(res?.invoice_ref_no);
        setInvDate(new Date(res?.invoice_date));
        setInvState(res?.invoice_stage);
        // setCurrency(res?.currency_id);
        // 12-7-2024
        setCurrency(1);
        setIssuesOn(new Date(res?.payment_due_date));
        setTerms(res?.terms_conditions);
        setPercen(res?.percentage);
        setTotalTableAmount(res?.invoice?.invoice_amount);
        console.log(resp?.invoice, 'resp?.invoice?');
        setInvAmounts(resp?.invoice?.invoice_amount);
        resp?.po_details?.map((item, ind) => {
          let data;
          if (item?.purchase_order?.leather_purchase_order_details_sum_price) {
            data =
              +item?.purchase_order?.leather_purchase_order_details_sum_price +
              " " +
              // item?.purchase_order?.currency?.CurrencyCode;
              "INR";
          } else {
            data =
              +item?.purchase_order?.material_purchase_order_details_sum_price +
              " " +
              // item?.purchase_order?.currency?.CurrencyCode;
              "INR";
          }

          total_price.push(data);
        });

        // console.log("total_price", total_price);

        if (total_price?.length > 0) {
          const separator = ",  ";
          const result = total_price.reduce(
            (accumulator, currentValue) =>
              accumulator + separator + currentValue
          );
          console.log("result", result);
          setPoValue(result);
        }

        resp?.po_details?.map((item, ind) => {
          option.push({
            value: {
              id:
                item?.purchase_order?.MaterialPurchaseOrderID ||
                item?.purchase_order?.LeatherPurchaseOrderID,
              type: item?.type,
              FactoryID: item?.FactoryID,
            },
            label:
              item?.purchase_order?.MaterialPurchaseOrderNo ||
              item?.purchase_order?.LeatherPurchaseOrderNo,
          });
        });
        setOptionChange(option);

        resp?.dc_details?.map((item, ind) => {
          check.push({
            value:
              item?.dc_detail?.MaterialASNSupplierID ||
              item?.dc_detail?.LeateherASNSupplierID,
            label: item?.dc_detail?.DCNNo,
          });
        });

        setOptionChanges(check);

        resp?.invoice_details?.map((item, ind) => {
          temp.push(item);

          setNoName((pre) => ({
            ...pre,
            [+item?.full_order_detail?.MaterialPurchaseOrderDetailID ||
              +item?.full_order_detail?.LeatherPurchaseOrderDetailID]: +item?.qty,
          }));
        });

        var temp1 = [];
        resp?.invoiced_details.map((item, ind) => {
          temp1.push(item);
        });
        setInvoicedDetails(temp1);

        setDcGetDetails(temp);
        const allCountries = temp.map((c, i) => i);
        setTableCheck(allCountries);
        setDisplayBank(resp?.accounts);
      } else {
        setError(resp);
        setErrorBox(true);
      }
    });
  };

  // console.log("invAmount", invAmount);

  const getDraftView = () => {
    console.log("dddd", detail?.draft);
    let res = detail?.draft;
    let temp = [];
    let check = [];
    let option = [];
    setInvRef(res?.invoice_ref_no);
    setInvDate(new Date(res?.invoice_date));
    setInvState(res?.invoice_stage);
    // setCurrency(res?.currency_id);
    // 12-7-2024
    setCurrency(1);
    setIssuesOn(new Date(res?.payment_due_date));
    setTerms(res?.terms_conditions);
    setCurrencyDisplay(res?.currency);
    setPercen(res?.percentage);
    setNumToWords(res?.amount_in_words);
    console.log(res?.poValue, "hh");
    setPoValue(res?.poValue);
    setAddcharge(res?.additional_charges);
    setSubcharge(res?.discount_amount);
    res?.po_ids?.map((item, ind) => {
      let parser = JSON.parse(item);
      option.push({
        value: {
          id: parser?.value?.id,
          type: parser?.value?.type,
          FactoryID: parser?.value?.FactoryID,
        },
        label: parser?.label,
      });
    });
    setOptionChange(option);

    res?.dc_ids?.map((item, ind) => {
      // console.log("item", item);
      let parser = JSON.parse(item);
      // console.log("parser", parser);
      check.push({
        value: {
          id: parser?.dc_id,
          type: parser?.type,
          FactoryID: parser?.factory_id,
        },
        label: parser?.dc_no,
      });
    });

    setOptionChanges(check);

    getDclist(option);

    res?.od_ids?.map((item, ind) => {
      let parser = JSON.parse(item);
      // console.log("ss", JSON.parse(item));
      temp.push(JSON.parse(item));

      setNoName((pre) => ({
        ...pre,
        [+parser?.full_order_detail?.MaterialPurchaseOrderDetailID ||
          +parser?.full_order_detail?.LeatherPurchaseOrderDetailID]:
          +parser?.typedQuantity,
      }));
    });

    setFinalTable(temp);
    setTableTwo(temp);
    const allCountries = temp.map((c, i) => i);
    setTableCheck(allCountries);
    setSelTable(temp);
    setDcGetDetails(temp);
    setDisplayBank(res?.accounts);
  };

  useEffect(() => {
    if (detail && location?.state?.type !== "draft") {
      getView();
    } else if (detail && location?.state?.type == "draft") {
      getDraftView();
    }
  }, []);

  useEffect(() => {
    let temp = [];
    let option = [];

    if (Proforma) {
      temp.push(Proforma);
      setOptionChange([
        {
          value: {
            id: Proforma?.purchase_order_id,
            type: Proforma?.type,
            FactoryID: Proforma?.FactoryID,
            total_price: Proforma?.total_price,
            CurrencyID: Proforma?.CurrencyID,
          },
          label: Proforma?.purchase_order_no,
        },
      ]);

      // setCurrency(Proforma?.CurrencyID);
      // 12-7-2024
      setCurrency(1);
    } else if (commercial) {
      temp.push(commercial);
      setOptionChange([
        {
          value: {
            id: commercial?.purchase_order_id,
            type: commercial?.type,
            FactoryID: commercial?.FactoryID,
            total_price: commercial?.total_price,
            CurrencyID: commercial?.CurrencyID,
          },
          label: commercial?.purchase_order_no,
        },
      ]);
      // setCurrency(commercial?.CurrencyID);
      // 12-7-2024
      setCurrency(1);
    } else if (upload_details) {
      upload_details.map((item, ind) => {
        temp.push(item);
      });
    }
    temp.map((item, ind) => {
      option.push({
        value: {
          id: item?.purchase_order_id,
          type: item?.type,
          FactoryID: item?.FactoryID,
          total_price: item?.total_price,
          CurrencyID: item?.CurrencyID,
        },
        label: item?.purchase_order_no,
      });
    });
    setoptions(option);
  }, [Proforma, commercial, upload_details]);

  // console.log("type", Proforma);
  // console.log("type2", commercial);

  useEffect(() => {
    if (
      Proforma &&
      type !== "view" &&
      type !== "edit" &&
      type !== "draft" &&
      input?.currencies?.length > 0
    ) {
      let currency_data = input?.currencies?.find(
        // (i) => i?.currency_id == Proforma?.CurrencyID
        (i) => i?.currency_id == 1
      );
      // setPoValue(Proforma.total_price + " " + currency_data?.currency_code);
      setPoValue(Proforma.total_price + " " + "INR");
      console.log(
        Proforma.total_price + " " + currency_data?.currency_code,
        "adda"
      );
      setCurrencyDisplay(currency_data);
    }
  }, [input]);

  // console.log("optionChange",optionChange);

  useEffect(() => {
    let temp = [];
    let temps = [];
    if (optionChange && input?.currencies?.length > 0) {
      console.log(optionChange?.value, 'optionChange?.value');
      temp.push(+optionChange?.value);
      setPoCheck(temp);

      let total_price = [];

      optionChange?.map((item, ind) => {
        // console.log("total_price5", input);
        let count = input?.currencies?.find(
          (i) => +i?.currency_id == +item.value?.CurrencyID
        );
        // console.log("total_price", count);
        // total_price.push(item?.value?.total_price + " " + count?.currency_code);
        total_price.push(item?.value?.total_price + " " + "INR");
      });

      if (type !== "edit" && type !== "view" && type !== "draft") {
        if (total_price?.length > 0) {
          const separator = ",  ";
          const result = total_price.reduce(
            (accumulator, currentValue) =>
              accumulator + separator + currentValue
          );
          // console.log(result,"if");
          setPoValue(result);
        } else {
          // console.log(result,"else");
          setPoValue("");
        }
      }
    }

    if (optionChanges) {
      optionChanges?.map((item, ind) => {
        temps.push(+item?.value);
      });
      setDcCheck(temps);
    }
  }, [optionChange, optionChanges, input]);

  useEffect(() => {
    let params;
    if (page?.curren_page !== null) {
      params = `?page=${page?.curren_page}`;
    } else {
      params = "";
    }

    if (page?.curren_page > 0) {
      api.paymentGet(params).then((res) => {
        if (res?.status === "success") {
          setBankDetails(res?.accounts);
          setPage({
            curren_page: res?.pagination?.curren_page,
            total_page: res?.pagination?.total,
          });
        }
      });
    }
  }, [page?.curren_page]);

  useEffect(() => {
    const selected_bank = bankDetails?.filter(
      (item) => item?.id === selectedBank
    );

    setDisplayBank(selected_bank);
  }, [selectedBank]);

  useEffect(() => {
    let temp = [];
    // dcGetDetails?.filter((item_1) =>
    // item_1?.map((item, ind) => {
    dcGetDetails?.map((item, ind) => {
      // let check_id =
      //   +item?.detail_delivery_details?.MaterialPurchaseOrderDetailID ||
      //   item?.detail_delivery_details?.LeatherPurchaseOrderDetailID;
      if (tableCheck?.includes(ind)) temp.push(item);
    });
    // );
    // setOrderID(temp);
    setSelTable(temp);
  }, [tableCheck]);

  // console.log("taxArr", taxArr);

  const checkData = (item, ind) => {
    let temp = [...tableTwo];

    const value = item;

    const index = temp.indexOf(item);

    if (temp.includes(value)) {
      temp.splice(index, 1);
    } else {
      temp.push(value);
    }

    setTableTwo(temp);
  };

  useEffect(() => {
    if (tableTwo?.length > 0) {
      let temp = [];
      tableTwo?.map((item, ind) => {
        temp.push({
          id:
            item?.detail_delivery_details
              ?.LeatherPurchaseOrderDetailDelDateID ||
            item?.detail_delivery_details?.MaterialPurchaseOrderDetailDelDateID,
          factory_id: item?.factory_id,
          type: item?.type,
          po_quantity: item?.po_quantity,
          invoice_quantity: item?.invoice_quantity,
          typedQuantity:
            noName[
            item?.full_order_detail?.MaterialPurchaseOrderDetailID ||
            item?.full_order_detail?.LeatherPurchaseOrderDetailID
            ],
          quantity: item?.detail_delivery_details?.Quantity,
          tax: item?.full_order_detail?.tax?.TaxPercentage
            ? Math.round(item?.full_order_detail?.tax?.TaxPercentage)
            : 0,
          detail_delivery_details: item?.detail_delivery_details,
          full_order_detail: item?.full_order_detail,
          amount:
            noName[
            item?.full_order_detail?.MaterialPurchaseOrderDetailID ||
            item?.full_order_detail?.LeatherPurchaseOrderDetailID
            ] * Number(item?.full_order_detail?.Price).toFixed(2),
        });
      });

      console.log(temp,'temp');

      // temp.map((item, ind) => {});

      setFinalTable(temp);
      setSelTable(temp);
    }
  }, [tableTwo, noName]);

  const invAddSubmitHandler = () => {
    const formData = new FormData();
    
    // if(poQuantity.length == 0){
    //   setQuantityError(true);
    //   return false;
    // }
    let error = [...invError];
    invDate == ""
      ? error.push("inv_date")
      : error.splice(error.indexOf("inv_date"), 1);
    inVRef == ""
      ? error.push("inv_ref")
      : error.splice(error.indexOf("inv_ref"), 1);
    issuesOn == ""
      ? error.push("pay_due")
      : error.splice(error.indexOf("pay_due"), 1);

    setInvError(error);

    formData.append("payment_due_date", moment(issuesOn).format("YYYY-MM-DD"));
    formData.append("invoice_type", method === "proforma" ? 1 : 2);
    formData.append("invoice_stage", invState);
    formData.append("invoice_date", moment(invDate).format("YYYY-MM-DD"));
    formData.append("currency_id", currency);
    formData.append("percentage", percen);
    formData.append("poQuantity", JSON.stringify(poQuantity));

    if (detail && type !== "draft") {
      formData.append(
        "invoice_amount",
        Number(detail?.invoice_amount).toFixed(2)
      );
      formData.append("invoice_id", detail?.id);

      api.invoiceEdit(formData).then((res) => {
        if (res?.status == "success") {
          setError(res);
          setErrorBox(true);
          setTimeout(() => {
            navigate("/invoice/print", {
              state: { id: detail?.id, inv_type: method },
            });
          }, 1000);
        } else {
          setError(res);
          setErrorBox(true);
        }
      });
    } else {
      if (selTable.length > 0) {
        selTable?.map((item, ind) => {
          let od_id =
            item?.detail_delivery_details
              ?.MaterialPurchaseOrderDetailDelDateID ||
            item?.detail_delivery_details?.LeatherPurchaseOrderDetailDelDateID;
          formData.append(`od_ids[${ind}][id]`, od_id);
          formData.append(`od_ids[${ind}][factory_id]`, item.factory_id);
          formData.append(`od_ids[${ind}][type]`, item.type);
          formData.append(
            `od_ids[${ind}][qty]`,
            noName[
            item?.full_order_detail?.MaterialPurchaseOrderDetailID ||
            item?.full_order_detail?.LeatherPurchaseOrderDetailID
            ]
          );
        });
      }

      // if (upload_details) {
      //   let sel_po = upload_details?.filter((item) =>
      //     poCheck?.includes(+item?.purchase_order_id)
      //   );
      //   sel_po?.map((item, ind) => {
      //     formData.append(`po_ids[${ind}][id]`, item?.purchase_order_id);
      //     formData.append(`po_ids[${ind}][factory_id]`, item?.FactoryID);
      //     formData.append(`po_ids[${ind}][type]`, item?.type.charAt(0));
      //   });
      // } else if (Proforma) {
      //   let temp = [Proforma];

      //   temp?.map((item, ind) => {
      //     formData.append(`po_ids[${ind}][id]`, item?.purchase_order_id);
      //     formData.append(`po_ids[${ind}][factory_id]`, item?.FactoryID);
      //     formData.append(`po_ids[${ind}][type]`, item?.type.charAt(0));
      //   });
      // } else {
      //   let temps = [commercial];

      //   temps?.map((item, ind) => {
      //     formData.append(`po_ids[${ind}][id]`, item?.purchase_order_id);
      //     formData.append(`po_ids[${ind}][factory_id]`, item?.FactoryID);
      //     formData.append(`po_ids[${ind}][type]`, item?.type.charAt(0));
      //   });
      // }

      optionChange?.map((item, ind) => {
        console.log(item);
        formData.append(`po_ids[${ind}][id]`, item?.value?.id);
        formData.append(
          `po_ids[${ind}][factory_id]`,
          item?.value?.FactoryID ?? 4
        );
        formData.append(`po_ids[${ind}][type]`, item?.value?.type);
      });

      let sel_dc = dcDropDetails?.filter((item) =>
        dcCheck?.includes(+item?.dc_id)
      );

      sel_dc?.map((item, ind) => {
        formData.append(`dc_ids[${ind}][id]`, item?.dc_id);
        formData.append(`dc_ids[${ind}][factory_id]`, item?.factory_id ?? 4);
        formData.append(`dc_ids[${ind}][type]`, item?.type);
      });

      formData.append("invoice_ref_no", inVRef);
      formData.append("additional_charges", Number(addcharge).toFixed(2) ?? 0); // Addtional Charges
      formData.append("sub_total", Number(totalTableAmount).toFixed(2)); // Sub Total
      // formData.append("igst_percentage", "18%");
      // formData.append("sgst_percentage", "9");
      // formData.append("cgst_percentage", "9");
      formData.append("amount_in_words", numToWords);
      formData.append("invoice_amount", Number(invAmount).toFixed(2)); // Grand Total
      formData.append("discount_amount", Number(subcharge).toFixed(2) ?? 0); //  Deduction
      formData.append("igst", isGst == true ? 1 : 0);
      // formData.append("gst_amount", isGst ? taxTotal : 0);
      // formData.append("cgst_amount", isGst ? +taxTotal / 2 : 0);
      // formData.append("sgst_amount", isGst ? +taxTotal / 2 : 0);
      formData.append("terms_conditions", terms);

      // ARRAY

      formData.append("tax_total_amount", Number(tax_total_amount).toFixed(2));

      if (taxArr?.length > 0) {
        taxArr?.map((item, ind) => {
          formData.append(
            `tax_details[${ind}][tax_pattern_id]`,
            item?.TaxPatternID ? item?.TaxPatternID : "-"
          );
          formData.append(
            `tax_details[${ind}][tax_name]`,
            item?.TaxPatternName ? item?.TaxPatternName : "-"
          );
          formData.append(
            `tax_details[${ind}][tax_perc]`,
            item?.TaxPercentage ? item?.TaxPercentage : "-"
          );
          formData.append(
            `tax_details[${ind}][tax_amount]`,

            // item?.Amount ? Number(item?.Amount).toFixed(2) : "-"
            item?.ex_rate_add_value
              ? Number(item?.ex_rate_add_value).toFixed(2)
              : "-"
          );
          formData.append(
            `tax_details[${ind}][tax_value]`,
            item?.total ? Number(item?.total).toFixed(2) : "-"
          );
        });
      }

      if (error?.length === 0) {
        api.purchaseOrderInvAdd(formData).then((res) => {
          if (res?.status == "success") {
            setError(res);
            setErrorBox(true);
            if (type == "draft") {
              deleteDrafts(detail?.id, res);
            } else {
              setTimeout(() => {
                navigate("/invoice/print", {
                  state: { id: res?.invoice?.id, inv_type: method },
                });
              }, 1000);
            }
          } else {
            setError(res);
            setErrorBox(true);
          }
        });
      }
    }
  };

  const saveDraftHandler = () => {
    const formData = new FormData();

    formData.append("payment_due_date", moment(issuesOn).format("YYYY-MM-DD"));
    formData.append("invoice_type", method === "proforma" ? 1 : 2);
    formData.append("invoice_stage", invState);
    formData.append("invoice_date", moment(invDate).format("YYYY-MM-DD"));
    formData.append("currency_id", currency);
    formData.append("type", 1);
    formData.append("method_type", "create");
    formData.append("method", method);
    formData.append("percentage", percen);
    formData.append("poValue", poValue);
    formData.append("currency", JSON.stringify(currencyDisplay));

    if (selTable.length > 0) {
      selTable?.map((item, ind) => {
        formData.append(`od_ids[${ind}]`, JSON.stringify(item));
      });
    }

    optionChange?.map((item, ind) => {
      // console.log(item);
      formData.append(`po_ids[${ind}]`, JSON.stringify(item));
    });

    let sel_dc = dcDropDetails?.filter((item) =>
      dcCheck?.includes(+item?.dc_id)
    );

    sel_dc?.map((item, ind) => {
      formData.append(`dc_ids[${ind}]`, JSON.stringify(item));
    });

    formData.append("invoice_ref_no", inVRef);
    formData.append("additional_charges", Number(addcharge).toFixed(2) ?? 0); // Addtional Charges
    formData.append(
      "sub_total",
      totalTableAmount ? Number(totalTableAmount).toFixed(2) : ""
    ); // Sub Total

    formData.append("amount_in_words", numToWords);
    formData.append(
      "invoice_amount",
      invAmount ? Number(invAmount).toFixed(2) : ""
    ); // Grand Total
    formData.append("discount_amount", Number(subcharge).toFixed(2) ?? 0); //  Deduction
    formData.append("igst", isGst == true ? 1 : 0);

    formData.append("terms_conditions", terms);

    // ARRAY

    formData.append(
      "tax_total_amount",
      tax_total_amount ? Number(tax_total_amount).toFixed(2) : ""
    );

    if (taxArr?.length > 0) {
      taxArr?.map((item, ind) => {
        formData.append(`tax_details[${ind}]`, JSON.stringify(item));
      });
    }

    api.saveDraft(formData).then((res) => {
      if (res?.status == "success") {
        setError(res);
        setErrorBox(true);
        setTimeout(() => {
          navigate("/invoice/list");
        }, 1000);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const invSelHandler = () => {
    setShowInvModal(true);
  };

  const handleCheckAllChange = (e) => {
    if (e.target.checked) {
      const allCountries = dcGetDetails.map((c, i) => i);
      setTableCheck(allCountries);
      setTableTwo(dcGetDetails);
    } else {
      setTableCheck([]);
      setTableTwo([]);
    }
  };

  const invTableSelectHandler = (val) => {
    // console.log("val", val);
    let temp = [];
    temp = [...tableCheck];
    if (temp?.includes(+val)) {
      temp?.splice(temp?.indexOf(+val), 1);
    } else {
      temp.push(+val);
    }
    setTableCheck(temp);
  };

  const invoiceCheckHandler = (val, target) => {
    let temp = [];
    if (target === "po") {
      console.log(val, 'val');
      temp = [...poCheck];
      if (temp?.includes(+val)) {
        temp?.splice(temp?.indexOf(+val), 1);
      } else {
        temp.push(+val);
      }
      setPoCheck(temp);
    } else {
      temp = [...dcCheck];
      if (temp?.includes(+val)) {
        temp?.splice(temp?.indexOf(+val), 1);
      } else {
        temp.push(+val);
      }
      setDcCheck(temp);
    }
  };

  const getList = () => {
    api.getRoles().then((res) => {
      if (res?.status == "success") {
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };
  const getDclist = (data) => {
    let temp = [];
    if (Proforma) {
      temp.push(Proforma);
    }

    if (commercial) {
      temp.push(commercial);
    }

    if (type == "draft") {
      data?.map((item, ind) => {
        temp.push({
          purchase_order_id: item?.value?.id,
          FactoryID: item?.value?.FactoryID,
          type: item?.value?.type,
        });
      });
    }

    const formData = new FormData();

    // Single PROFORMA

    temp.map((item, ind) => {
      formData.append(`po_ids[${ind}][id]`, item?.purchase_order_id);
      formData.append(`po_ids[${ind}][factory_id]`, item?.FactoryID);
      formData.append(`po_ids[${ind}][type]`, item?.type);
    });

    // SINGLE COMMERCIAL

    // temp.map((item, ind) => {
    //   console.log("temps", item);
    //   formData.append(`po_ids[${ind}][id]`, item?.purchase_order_id);
    //   formData.append(`po_ids[${ind}][factory_id]`, item?.FactoryID);
    //   formData.append(`po_ids[${ind}][type]`, item?.type);
    // });

    // Multiple PROFORMA AND COMMERICAL

    upload_details?.map((item, ind) => {
      formData.append(`po_ids[${ind}][id]`, item?.purchase_order_id);
      formData.append(`po_ids[${ind}][factory_id]`, item?.FactoryID);
      formData.append(`po_ids[${ind}][type]`, item?.type);
    });
    api.purchaseOrderUploadPageDetails(formData).then((res) => {
      if (res?.status === "success") {
        let temp = [];
        var temp1 = [];
        res?.dcs?.map((item, index) => {
          if (!temp1.includes(item?.dc_no)) {
            temp.push({
              value: item?.dc_id,
              label: item?.dc_no,
            });
            temp1.push(item?.dc_no);
          }
        });
        console.log(res?.dcs, 'dcs');
        setDcDropDetails(res?.dcs);
        setoption(temp);
        setAlreadyInvoicedAmount(Number(res?.invoice_amount).toFixed(2));
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    let requiredTax = taxList?.filter((item) => {
      if (isGst) {
        return item?.TaxCode === "IGST";
      } else {
        return item?.TaxCode === "CGST" || item?.TaxCode === "SGST";
      }
    });
    setUseTax(requiredTax);
  }, [taxList]);

  // DELETE DRAFT
  const deleteDrafts = (id, resp) => {
    let data = {
      draft_id: id,
    };
    api.deleteDraft(data).then((res) => {
      if (res?.status === "success") {
        setTimeout(() => {
          navigate("/invoice/print", {
            state: { id: resp?.invoice?.id, inv_type: method },
          });
        }, 1000);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    if (Proforma || commercial || upload_details) {
      getDclist();
    }
  }, []);

  useEffect(() => {
    if (data?.id) {
      getList();
    }
  }, []);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const onNoNameChange = (e, id, qty) => {
    let temp = [];
    if (type !== "view" && type !== "edit") {
      setNoNames(true);
    }
    const value = e.target.value;
    const value_qty = +value > qty ? qty : +value < 0 ? 0 : +value;

    temp.push({
      PurchaseOrderDetailID: id,
      ReceivedQuantity: +value_qty,
    });

    setQty(temp);
    setNoName((pre) => ({
      ...pre,
      [+id]: +value_qty,
    }));
    setPer_centageFlag(true);
  };

  const dropCloseHandler = (target) => {
    let parent = target.parentNode;
    let grant_parent = target.parentNode.parentNode;

    if (parent.id !== "dc-drop-cont" && grant_parent.id !== "dc-drop-cont")
      dcDrop && setDcDrop(false);

    if (parent.id !== "po-drop-cont" && grant_parent.id !== "po-drop-cont")
      drop && setDrop(false);
  };

  let invoice_header =
    method === "commercial" ? "commercial-invoice" : "proforma-invoice";

  // console.log("poValue", poValue);

  const dcCheckSubmitHandler = () => {
    let temp = [];

    const formData = new FormData();

    optionChange?.map((item, ind) => {
      // console.log(item);
      formData.append(`po_ids[${ind}][id]`, item?.value?.id);
      formData.append(
        `po_ids[${ind}][factory_id]`,
        item?.value?.FactoryID ?? 4
      );
      formData.append(`po_ids[${ind}][type]`, item?.value?.type);
    });

    dcDropDetails?.map((item, ind) => {
      if (dcCheck?.includes(+item?.dc_id)) {
        formData.append(`dc_ids[${ind}][id]`, item?.dc_id);
        formData.append(`dc_ids[${ind}][factory_id]`, item?.factory_id ?? 4);
        formData.append(`dc_ids[${ind}][type]`, item?.type);
      }
    });
    api.purchaseOrderInvDcItems(formData).then((res) => {
      if (res?.status === "success") {
        let quan = [];
        res?.item_details?.map((item, ind) => {
          temp.push(item);
          setNoName((pre) => ({
            ...pre,
            [item?.full_order_detail?.MaterialPurchaseOrderDetailID ||
              item?.full_order_detail?.LeatherPurchaseOrderDetailID]: Math.round(
                item?.detail_delivery_details?.ReceivedQuantity
              ),
          }));
          quan.push({
            PurchaseOrderDetailID: [
              item?.full_order_detail?.MaterialPurchaseOrderDetailID ||
              item?.full_order_detail?.LeatherPurchaseOrderDetailID,
            ],
            ReceivedQuantity: +item?.detail_delivery_details?.ReceivedQuantity,
          });
        });
        setQty(quan);
        // temp.push(res?.item_details);
        const allCountries = temp.map((c, i) => i);
        setTableCheck(allCountries);
        setTableTwo(temp);
        setDcGetDetails(temp);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const handleCurrency = (event) => {
    let currency_data = input?.currencies?.find((i) => i?.currency_id == event);
    setCurrencyDisplay(currency_data);
    setCurrency(event);
  };

  return (
    <>
      <TopBar />
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopHeader type={invoice_header} />
      <Col
        lg={11}
        md={12}
        sm={12}
        onClick={(e) => dropCloseHandler(e.target)}
        className="invoice-m-p-cont ms-5 px-3 my-3 py-2"
      >
        <div className="d-sm-flex flex-wrap justify-content-between">
          <h6 className="primary1 mb-2 fnt-fam-f2">
            {method === "commercial"
              ? "Commercial Invoice"
              : "Proforma Invoice"}
          </h6>
          {type == "view" && (
            <button
              className="bg-primary1 fnt-fam-f2  px-4 py-1 text-white border-0"
              onClick={() =>
                navigate("/invoice/print", {
                  state: { id: detail?.id, inv_type: method },
                })
              }
            >
              Print
            </button>
          )}
        </div>
        <div className="border-btm pb-3">
          <div className="d-flex flex-wrap gap-5">
            <div className="invoice-first-sec">
              <label className="my-1 fnt-fam-f2">
                {method == "commercial" ? "Commercial" : "Proforma"} Invoice
                Ref. No.{type !== "view" && <span className="red-text">*</span>}
              </label>
              <p className="border-btm py-1 fnt-fam-f2">
                <input
                  className="border-0 w-100"
                  value={inVRef}
                  onChange={(e) => setInvRef(e.target.value)}
                  disabled={type == "view" ? true : false}
                />
              </p>
              {invError.includes("inv_ref") && (
                <p className="err">Enter Inv Ref No</p>
              )}
            </div>
            <div className="invoice-first-sec">
              <label className="my-1">
                Invoice Date
                {type !== "view" && <span className="red-text">*</span>}
              </label>
              <p className="border-btm py-1">
                <div className="border-0 w-100">
                  <DatePicker
                    startDate={invDate}
                    setStartDate={setInvDate}
                    disabled={type == "view" ? true : false}
                  />
                </div>
              </p>
              {invError.includes("inv_date") && (
                <p className="err">Enter Inv Date</p>
              )}
            </div>
            <div className="invoice-first-sec fnt-fam-f2 border-btm">
              <label className="my-1">Invoice Stage</label>
              <div>
                <select
                  className={`border-0 w-100 ${type == "view" && "select_box_arrow_hider"
                    }`}
                  onChange={(e) => setInvState(e.target.value)}
                  value={invState}
                  disabled={type == "view" ? true : false}
                >
                  <option value={""}>Select Stage</option>
                  {/* <option value={1}>Advance</option>
                  <option value={2}>Partial</option>
                  <option value={3}>Final</option> */}
                  {/* <option value={1}>KT - Advance Payment</option> 11-04-2024 */}
                  {/* <option value={1}>Advance Payment</option>
                  <option value={2}>TT Payment</option>
                  <option value={3}>50% Advance</option> */}
                  {input?.stages?.map((item, ind) => {
                    return (
                      <option value={item?.ReferenceDetailsID} key={ind}>
                        {item?.ReferenceDetailsName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {/* <div className="invoice-first-sec">
              <label className="my-1 fnt-fam-f2">
                Proforma Date<span className="red-text">*</span>
              </label>
              <p className="border-btm py-1 fnt-fam-f2">12-03-2023</p>
            </div> */}
            <div className="invoice-first-sec fnt-fam-f2 border-btm">
              <label className="my-1">Currency</label>
              <div>
                <select
                  className={`border-0 w-100 ${type == "view" && "select_box_arrow_hider"
                    }`}
                  onChange={(e) => handleCurrency(e.target.value)}
                  value={currency}
                  // disabled={type == "view" ? true : false}
                  disabled
                >
                  <option value={""}>Select Currency</option>
                  {input?.currencies?.map((item, ind) => (
                    <option value={item?.currency_id} key={ind}>
                      {item?.currency_code}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="invoice-first-sec fnt-fam-f2 position-relative pb-2">
              <label className="my-1 py-1">
                PO Ref No.
                {type !== "view" && <span className="red-text">*</span>}
              </label>
              <Select
                closeMenuOnSelect={false}
                isMulti
                name="colors"
                options={options}
                value={optionChange}
                onChange={(e) => setOptionChange(e)}
                className="basic-multi-select"
                classNamePrefix="select"
                isDisabled={
                  type == "view" ? true : type == "edit" ? true : false
                }
              />
              {/* <p
                className="pointer border-btm d-flex "
                onClick={() => setDrop(!drop)}
              >
                Select Po Ref No
                <span className="ms-auto">
                  <AiOutlineDown />
                </span>
              </p>
              {drop && (
                <ul className="checkbox-drop-box" id="po-drop-cont">
                  {Proforma && (
                    <li
                      key={Proforma?.purchase_order_id}
                      className="d-flex gap-2 my-1"
                    >
                      <input
                        checked={poCheck.includes(+Proforma?.purchase_order_id)}
                        value={Proforma?.purchase_order_id}
                        type="checkbox"
                        onChange={(e) =>
                          invoiceCheckHandler(e.target.value, "po")
                        }
                      />
                      <p className="py-1">{Proforma?.purchase_order_no}</p>
                    </li>
                  )}
                  {commercial && (
                    <li
                      key={commercial?.purchase_order_id}
                      className="d-flex gap-2 my-1"
                    >
                      <input
                        checked={poCheck.includes(
                          +commercial?.purchase_order_id
                        )}
                        value={commercial?.purchase_order_id}
                        type="checkbox"
                        onChange={(e) =>
                          invoiceCheckHandler(e.target.value, "po")
                        }
                      />
                      <p className="py-1">{commercial?.purchase_order_no}</p>
                    </li>
                  )}
                  {upload_details &&
                    upload_details?.map((item) => (
                      <li
                        key={item?.purchase_order_id}
                        className="d-flex gap-2 my-1"
                      >
                        <input
                          checked={poCheck.includes(+item?.purchase_order_id)}
                          value={item?.purchase_order_id}
                          type="checkbox"
                          onChange={(e) =>
                            invoiceCheckHandler(e.target.value, "po")
                          }
                        />
                        <p className="py-1">{item?.purchase_order_no}</p>
                      </li>
                    ))}
                </ul>
              )} */}
            </div>
            <div className="d-flex gap-3">
              <div className="invoice-first-sec fnt-fam-f2 position-relative">
                <label className="my-1 py-1">
                  DC No.
                  {/* <span className="red-text">*</span> */}
                </label>
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  name="colors"
                  options={poCheck?.length > 0 ? option : ""}
                  value={optionChanges}
                  onChange={(e) => setOptionChanges(e)}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isDisabled={
                    type == "view" ? true : type == "edit" ? true : false
                  }
                />
                {/* <p
                className="pointer border-btm d-flex"
                onClick={() => setDcDrop(!dcDrop)}
              >
                Select DC No
                <span className="ms-auto">
                  <AiOutlineDown />
                </span>
              </p>
              {dcDrop && (
                <ul className="checkbox-drop-box" id="dc-drop-cont">
                  {dcDropDetails?.map(
                    (item, ind) =>
                      poCheck.includes(+item?.po_id) && (
                        <li key={ind} className="d-flex gap-2 my-1">
                          <input
                            checked={dcCheck?.includes(+item?.dc_id)}
                            value={item?.dc_id}
                            type="checkbox"
                            onChange={(e) =>
                              invoiceCheckHandler(e.target.value, "dc")
                            }
                          />
                          <p className="py-1">{item?.dc_no}</p>
                        </li>
                      )
                  )}
                </ul>setPoValue
              )} */}
              </div>
              {type == "view" ? null : (
                <div className="mt-3">
                  <button
                    className="bg-primary1 sub-app-btn text-white border-0 mt-4 fnt-fam-f1 px-2"
                    onClick={dcCheckSubmitHandler}
                  // disabled={dcCheck?.length > 0 ? false : true}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>

            <div className="invoice-first-sec">
              <label className="my-1 fnt-fam-f2">PO Value</label>
              <p className=" py-1 fnt-fam-f2">
                <input
                  className="border-0 w-100"
                  value={poValue}
                  onChange={(e) => setPoValue(e.target.value)}
                  readOnly
                />
              </p>
            </div>

            <div className="invoice-first-sec">
              <label className="my-1 fnt-fam-f2">Already Invoice Value</label>
              <p className=" py-1 fnt-fam-f2">
                <input
                  className="border-0 w-100"
                  value={alreadyInvoiceAmount}
                  onChange={(e) => setAlreadyInvoicedAmount(e.target.value)}
                  readOnly
                />
              </p>
            </div>
          </div>
          {/* <div className="invoice-first-sec fnt-fam-f2 border-btm">
            <label className="my-1">Invoice Stage</label>
            <div>
              <select
                className="border-0 w-100"
                onChange={(e) => setInvState(e.target.value)}>
                <option value={1}>Select Stage</option>
                <option value={1}>Advanced</option>
                <option value={2}>Partial</option>
                <option value={3}>Final</option>
              </select>
            </div>
          </div> */}
        </div>

        <h6 className="primary1 mb-2 mt-3 pt-3 fnt-fam-f2">
          Bill of Materials
        </h6>
        {/* <InvoiceFilterSec />  */}
        {dcGetDetails?.length > 0 && (
          <InvoiceTableContent
            invTableSelectHandler={invTableSelectHandler}
            checkData={checkData}
            tableTwo={tableTwo}
            setPoQuantity={setPoQuantity}
            tableCheck={tableCheck}
            handleCheckAllChange={handleCheckAllChange}
            dcGetDetails={dcGetDetails}
            invoicedDetails = {invoicedDetails}
            onNoNameChange={onNoNameChange}
            noName={noName}
            setNoNames={setNoNames}
            noNames={noNames}
            type={type}
            setPer_centageFlag={setPer_centageFlag}
            per_centageFlag={per_centageFlag}
          />
        )}
        {/* <InvoicePaginationSection />  */}
        {selTable?.length > 0 && (
          <Invoice2ndTableSection
            displayBank={displayBank}
            setBankModal={setBankModal}
            isGst={isGst}
            numToWords={numToWords}
            setNumToWords={setNumToWords}
            addcharge={addcharge}
            noName={noName}
            setAddcharge={setAddcharge}
            setPercen={setPercen}
            percen={percen}
            setTotalTableAmount={setTotalTableAmount}
            totalTableAmount={totalTableAmount}
            setSubcharge={setSubcharge}
            subcharge={subcharge}
            setTaxTotal={setTaxTotal}
            taxTotal={taxTotal}
            userTax={taxList}
            selTable={selTable}
            setInvAmount={setInvAmount}
            invAmount={invAmount}
            invAmounts={invAmounts}
            finalTable={finalTable}
            setTax_total_amount={setTax_total_amount}
            setTaxArr={setTaxArr}
            qty={qty}
            currencyDisplay={currencyDisplay}
            setPer_centageFlag={setPer_centageFlag}
            per_centageFlag={per_centageFlag}
          />
        )}
        <Col lg={4} md={6} sm={12} className="">
          <h6 className="primary1 fnt-fam-f2 pt-3">Terms & Condition</h6>
        </Col>
        <Col lg={4} md={6} sm={12} className="mt-2 mb-4">
          {/* <input
            className="form-control"
            onChange={(e) => setIssuesOn(e.target.value)}
            value={issuesOn}
          /> */}
          <label className="pt-3 pb-2">
            Payment Due Date
            {type !== "view" && <span className="red-text">*</span>}
          </label>
          {/* <input
            className="form-control"
            type="date"
            onChange={(e) => setIssuesOn(e.target.value)}
            value={issuesOn}
            disabled={type == "view" ? true : false}
          /> */}
          <div className="form-control">
            <DatePicker
              startDate={issuesOn}
              setStartDate={setIssuesOn}
              disabled={type == "view" ? true : false}
            />
          </div>
          {invError.includes("pay_due") && (
            <p className="err">Enter Pay Due Date</p>
          )}
        </Col>
        <Col lg={4} md={6} sm={12} className="mt-2 mb-4">
          <textarea
            className="w-100 cont-border p-3"
            value={terms}
            placeholder="Terms & Condition Enter Here..."
            onChange={(e) => setTerms(e.target.value)}
            disabled={type == "view" ? true : false}
          ></textarea>
        </Col>
        {/* <div
          style={{ height: "330px" }}
          className="d-md-flex justify-content-center cont-border rounded-1 mt-2 mb-3 align-items-center">
          Loream
        </div> */}
        {type == "view" ? null : (
          <QuoLastSectionBtn
            BtnText="Submit"
            BtnSize="wid-5r"
            customFunction={invAddSubmitHandler}
            saveDraftHandler={saveDraftHandler}
          />
        )}
        <div className="rg-footer">
          <LogInFooter />
        </div>
      </Col>
      <Modal
        id="#sucess"
        className="proforma-com-inv"
        show={showInvModal}
        size="md"
        centered
        onHide={() => setShowInvModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Image
          style={{ width: "10%" }}
          className="ps-2 ms-1 mt-2 pt-1"
          src={FavLogo}
        />
        <div className="sup-modal ">
          <div>
            <div className="d-flex-as-jc img_tick ">
              {/* <Image src={Check} />  */}
              <div className="px-3 mb-2 pb-1">
                <p>Do you to Upload the Proforma Commercial invoice?</p>
              </div>
            </div>
            <div className="d-flex flex-row-reverse">
              <div className="row">
                <div class="col-sm">
                  <Link
                    style={{
                      width: "70px",
                      height: "30px",
                    }}
                    className="my-3 modal_btn"
                    to="/invoice/create"
                  >
                    Upload
                  </Link>
                </div>
                <div class="col-sm">
                  <Link
                    style={{
                      width: "70px",
                      height: "30px",
                    }}
                    className="my-3 modal_btn"
                    to="/invoice"
                  >
                    Create
                  </Link>
                </div>
                <div className="col-sm">
                  <Link
                    style={{
                      backgroundColor: "#D1D3D4",
                      width: "70px",
                      height: "30px",
                    }}
                    className="my-3 modal_btn"
                    to="#"
                    onClick={() => {
                      setShowInvModal(false);
                    }}
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal centered show={bankModal} onHide={() => setBankModal(!bankModal)}>
        <Modal.Header className="border-0" closeButton>
          <div className="w-100">
            <h5 className="w-50 mx-auto text-center">Bank Details</h5>
          </div>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "scroll" }}>
          {bankDetails?.map((supplier) => (
            <Col className="w-90">
              <input
                type="checkbox"
                checked={selectedBank == supplier?.id}
                className="pointer"
                onChange={() => setSelectedBank(+supplier?.id)}
              />
              <div className="d-flex py-1">
                <label style={{ width: "150px" }}>Beneficiary Name</label>
                <p>
                  <span className="me-1 pe-1">:</span>
                  <span className="fw-bold">
                    {supplier?.account_holder_name}
                  </span>
                </p>
              </div>
              <div className="d-flex py-1">
                <label style={{ width: "150px" }}>Bank Name</label>
                <p>
                  <span className="me-1 pe-1">:</span>
                  <span className="fw-bold">{supplier?.bank_name}</span>
                </p>
              </div>
              <div className="d-flex py-1">
                <label style={{ width: "150px" }}>Account No</label>
                <p>
                  <span className="me-1 pe-1">:</span>
                  <span className="fw-bold">{supplier?.account_no}</span>
                </p>
              </div>
              <div className="d-flex py-1">
                <label style={{ width: "150px" }}>IFSC Code</label>
                <p>
                  <span className="me-1 pe-1">:</span>
                  <span className="fw-bold">{supplier?.ifsc_code}</span>
                </p>
              </div>
            </Col>
          ))}
        </Modal.Body>

        <Modal.Footer className="border-0 invoice_bbtn">
          <div className="d-flex justify-content-between">
            {" "}
            <div className="">
              <Button
                className="me-3"
                variant="secondary"
                onClick={() => setPage(page?.curren_page - 1)}
              >
                Previous
              </Button>
              <Button
                variant="primary"
                onClick={() => setPage(page?.curren_page + 1)}
              >
                Next
              </Button>
            </div>
            <div className="">
              <Button
                variant="success"
                onClick={() => setBankModal(!bankModal)}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        id="#sucess"
        className="otp-modal"
        show={quantityError}
        size="md"
        centered
        onHide={() => setQuantityError(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="sup-modal ">
          <Image src={FavLogo} />
          <div className="mt-4">
            <div className="img_tick ">
              <Image src={Error} />
              <div className="px-3">
                <h5>Error</h5>
                <p>Please enter the quantity</p>
              </div>
            </div>
            <Link
              className="my-3 modal_btn"
              to="#"
              style={{
                width: "70px",
                height: "30px",
              }}
              onClick={() => {
                setQuantityError(false);
                // localStorage.clear();
                window.location.reload();
              }}
            >
              OK
            </Link>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default InvoicePage;
