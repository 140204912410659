import React, { useState } from "react";
import { Col, Table, Modal } from "react-bootstrap";
import SpTable from "../../../component/PurchaseOrder/ShippingTracker/SpTable";
import SpTop from "../../../component/PurchaseOrder/ShippingTracker/SpTop";
import SpOverallDelivery from "../../../component/PurchaseOrder/ShippingTracker/SpOverallDelivery";
import SpPackageDetails from "../../../component/PurchaseOrder/ShippingTracker/SpPackageDelivery";
import LeftSp from "../../../component/PurchaseOrder/ShippingTracker/LeftSp";
import TopBar from "../../../navbar/TopBar";
import TopHeader from "../../../navbar/TopHeader";
import LogInFooter from "../../../logincomponent/LogInFooter";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import SpFilterSec from "../../../component/PurchaseOrder/ShippingTracker/SpFilterSec";
import ViewDetails from "../../../assets/Icons/Normal Icons/ViewDetails.svg";
import { BsExclamationCircle } from "react-icons/bs";
import { FavLogo } from "../../../assets/img";
import { AiOutlineFileJpg } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaCheck } from "react-icons/fa";
import { BsChevronDown } from "react-icons/bs";
import { TbEdit } from "react-icons/tb";
import { shippmentCate } from "../../../layout/dummyData";

// TEST

import {
  FaAngleDown,
  FaArrowDown,
  FaCaretDown,
  FaFilter,
  FaSlidersH,
  FaTicketAlt,
  FaTimes,
} from "react-icons/fa";

import {
  factoryList,
  filterCate,
  materialCat,
  materialGroupCat,
  materialSubGroupCat,
} from "../../../layout/dummyData";

function LogisticsTracker() {
  const [showRightDc, setShowRightDc] = useState(true);
  const [openCard, setOpenCard] = useState(false);
  const grnSupplierSubmitHandler = (supres) => setOpenCard(supres);

  const [showModals, setShowModals] = useState(false);

  const dcClickHandler = () => setShowModals(true);
  const dcModalYesHandler = () => {
    setShowModals(false);
    setShowRightDc(true);
  };

  const [editShow, setEditShow] = useState(false);
  const [catIndexs, setCatIndexs] = useState();
  const [certificate, setCertificate] = useState();
  const [showModal, setShowModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const spSaveClickHandler = () => setShowModal(true);
  const spUpdateClickHandler = () => setUpdateModal(true);

  const spUpdateModalYesHandler = () => {
    setUpdateModal(false);
  };
  const spSaveModalYesHandler = () => {
    setShowModal(false);
    setEditShow(false);
  };

  const spEditHandler = () => setEditShow(true);

  const checkBoxs = (e) => {
    setCatIndexs(e);
  };

  // TEST

  const [catList, setCatList] = useState([]);
  const [catIndex, setCatIndex] = useState();
  const [showList, setShowList] = useState(1);
  const [filterShowHandlers, setfilterShowHandlers] = useState(true);

  const newfilterShowHandlers = () =>
    setfilterShowHandlers(!filterShowHandlers);
  const checkBox = (e) => {
    setCatIndex(e);
  };

  const showListHandler = (data) => {
    if (data == showList) {
      setShowList();
    } else {
      setShowList(data);
    }
    // setShowList(!showList);
  };

  return (
    <>
      <TopBar />
      <TopHeader type="shipping-tracker" />
      <Col
        style={{ overflow: "hidden" }}
        className="d-md-flex flex-row-reverse flex-wrap"
      >
        <Col xs={12} md={7} lg={7} xl={8} style={{ overflow: "hidden" }}>
          {/* <SpTop /> */}
          <Col xs={12} lg={9} className="d-flex flex-wrap px-3">
            <Col className="input-box px-2 mb-2" xs={12} sm={4} lg={4}>
              <p>
                <span className="po-sp-span-wid">DC No</span>: SD8978945
              </p>
            </Col>
            <Col className="input-box px-2 mb-2" xs={12} sm={4} lg={4}>
              <p>
                <span className="po-sp-span-wid">DC Date</span>: 20/01/2023
              </p>
            </Col>
            <Col className="input-box px-2 mb-2" xs={12} sm={4} lg={4}>
              <p>
                <span className="po-sp-span-wid">Shipment Stage</span>: partial
              </p>
            </Col>
            <Col className="input-box px-2 mb-2" xs={12} sm={6} md={4} lg={4}>
              <p>
                <span className="po-sp-span-wid">ASN No</span>: 589UI578964
              </p>
            </Col>
            <Col className="input-box px-2 mb-2" xs={12} sm={6} md={4} xxl={4}>
              <p>
                <span className="po-sp-span-wid">Invoice No</span>: GT89UI578964
              </p>
            </Col>
            <Col className="input-box px-2 mb-2" xs={12} sm={6} md={4} xxl={4}>
              <p>
                <span className="po-sp-span-wid">Invoice Date</span>:
                GT89UI578964
              </p>
            </Col>
            <Col className="input-box px-2 mb-2 bor-right" xs={12} sm={6}>
              <label className="d-flex align-items-center my-1">
                Consignee Details:
              </label>
              <div>
                <p>37JG+VJC, NGO Annexe, George Town, Chennai,</p>
                <p>Tamil Nadu 600003</p>
                <p>Contact Person: Karthick M</p>
                <p>Mobile No: 7802030551</p>
                <p>GST No:FR8956K1235</p>
              </div>
            </Col>
            <Col className="input-box px-2 mb-2" xs={12} sm={6}>
              <label className="d-flex align-items-center my-1">
                Notify Party:
              </label>
              <div>
                <p>37JG+VJC, NGO Annexe, George Town, Chennai,</p>
                <p>Tamil Nadu 600003</p>
                <p>Contact Person: Karthick M</p>
                <p>Mobile No: 7802030551</p>
                <p>GST No:FR8956K1235</p>
              </div>
            </Col>
          </Col>
          {/* <SpTable showRightDc={showRightDc} /> */}
          <div className="px-4">
            <div>
              <div className="d-flex flex-wrap">
                <p className="fnt-fam-f2 me-3 fs-14">
                  <span className="opac-85">Purchase Order Ref No:</span>1234782
                </p>
                <p className="fnt-fam-f2 fs-14">
                  <span className="opac-85">Purchase Date:</span>1234782
                </p>
              </div>
              <p className="pt-1 mb-0 pb-0 fw-bold">Shoe Accessories</p>
              <h6 className="primary1 pt-2 fnt-fam-f2">Bill of Materials</h6>
              <SpFilterSec
                grnSupplierSubmitHandler={grnSupplierSubmitHandler}
                showRightDc={showRightDc}
              />
            </div>
            <div
              className={
                showRightDc === false
                  ? "prduct-page dc-prod-table wid-150perc"
                  : "prduct-page dc-prod-table"
              }
            >
              <div className=" product-table scroll-100">
                <Table className="mb-0">
                  <thead>
                    <tr>
                      <th className="text-nowrap">
                        <label>
                          <span className="text-dark">SI.No</span>
                        </label>
                      </th>
                      <th className="text-nowrap">Supplier Mat Code</th>
                      <th className="text-nowrap" style={{ minWidth: "209px" }}>
                        KH Material SKU
                      </th>
                      <th className="text-nowrap">HSN Code</th>
                      <th>UOM</th>
                      <th className="text-nowrap">
                        Award Qty
                        <button className="cust-btn ms-0 ps-2">
                          <MdOutlineKeyboardArrowDown />
                        </button>
                      </th>
                      <th className="text-nowrap">
                        Delivered Qty
                        <button className="cust-btn ms-0 ps-2">
                          <MdOutlineKeyboardArrowDown />
                        </button>
                      </th>
                      <th>DC Qty</th>
                    </tr>
                  </thead>
                  <tbody className="border-0">
                    <tr>
                      <td>1</td>
                      <td>0009</td>
                      <td>
                        <p className=" my-1" style={{ fontSize: "14px" }}>
                          R95CH456789
                          <span className="font-col-sec">
                            <img src={ViewDetails} className="wid-15px ms-1" />
                          </span>
                        </p>
                        <p
                          className="text-wrap my-1"
                          style={{ fontSize: "14px" }}
                        >
                          text. It has roots in a piece of classical Latin
                          literature from 45 BC, making it over 2000 years old.
                          Richard McClintock, a
                        </p>
                      </td>
                      <td>B$5678945</td>
                      <td>Nos</td>
                      <td>10,000</td>
                      <td>90,000</td>
                      <td>
                        <span className="dc-qty-data">50,000</span>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>0009</td>
                      <td>
                        <p className=" my-1" style={{ fontSize: "14px" }}>
                          R95CH456789
                          <span className="font-col-sec">
                            <img src={ViewDetails} className="wid-15px ms-1" />
                          </span>
                        </p>
                        <p
                          className="text-wrap my-1"
                          style={{ fontSize: "14px" }}
                        >
                          text. It has roots in a piece of classical Latin
                          literature from 45 BC, making it over 2000 years old.
                          Richard McClintock, a
                        </p>
                      </td>
                      <td>B$5678945</td>
                      <td>Nos</td>
                      <td>10,000</td>
                      <td>90,000</td>
                      <td>
                        <span className="dc-qty-data">50,000</span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          {/* <SpPackageDetails /> */}
          <div>
            <div className="package-detail pt-3 pb-2 mt-2 mb-1 px-4">
              <h5 className="my-1 pb-2">Package Details</h5>
              <div className="d-flex flex-wrap  align-items-end ">
                <p className="pe-2 me-2 mb-2">Package Type: Boxes </p>
                <p className="pe-2 me-2 mb-2">Width: 20 </p>
                <p className="pe-2 me-2 mb-2">Length: 15 </p>
                <p className="pe-2 me-2 mb-2">Height: 10 Meter </p>
                <p className="pe-2 me-2 mb-2">Count: 5</p>
              </div>
              <div className="d-flex flex-wrap  align-items-end ">
                <p className="pe-2 me-2 mb-2">No of Packages: 500 </p>
                <p className="pe-2 me-2 mb-2">Total Gross Weight: 1000Kgs</p>
                <p className="pe-2 me-2 mb-2">Total Net Weight: 900Kgs</p>
                <p className="pe-2 me-2 mb-2">Total Volume: 2000 Ft3 </p>
              </div>
            </div>
          </div>
          {/* <SpOverallDelivery setShowRightDc={setShowRightDc} /> */}
          <div className="package-detail px-4 mt-2">
            <div>
              <h5 className="my-1 pb-2">Overall Delivery schedule</h5>
              <p className="my-1 py-1">
                Expected Time of Dispatch(ETD): 10.01.2023
              </p>
              <p className="my-1 py-1">
                Expected Time of Arrival(ETA): 12.01.2023
              </p>
            </div>

            <h5 className="my-1 pb-2">Overall Delivery schedule</h5>
            <div className="d-sm-flex gap-3">
              <p className="my-1 py-1">Inco Term: FCA- FREE CARRIER</p>
              <p className="my-1 py-1">Delivery Place: Chennai-PORT</p>
              <p className="my-1 py-1">Transport By: Supplier</p>
            </div>
            <Modal
              className="border-0 border"
              id="#Save-terms"
              show={showModals}
              size="sm"
              centered
              onHide={() => setShowModals(false)}
              aria-labelledby="contained-modal-title-vcenter"
            >
              <div className="sup-modal pt-3">
                <img className="fav-icon ms-2" src={FavLogo} />
                <div className="mt-0">
                  <div className="d-flex-as-jc img_tick mt-3">
                    {/* <img src={Check} /> */}
                    <BsExclamationCircle className="primary2" />
                    <div className="px-1 ms-2 mb-3">
                      <h5>Create shipping stages</h5>
                      <p>Now you can update the shipping stages ?</p>
                    </div>
                  </div>
                  <div className="d-flex bg-light-blue px-2 justify-content-end">
                    <button
                      className="my-2 cust-btn bg-lit-primary4 me-2"
                      onClick={() => {
                        setShowModal(false);
                      }}
                    >
                      No
                    </button>
                    <button
                      className="my-2 cust-btn bg-primary1 text-white"
                      onClick={dcModalYesHandler}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </Col>
        <Col style={{ overflow: "hidden" }}>
          {showRightDc && (
            <>
              {/* <LeftSp /> */}
              <div className="mode-shippmet trans-x-10px cont-border">
                <h4 className="stage-1" onClick={() => showListHandler(1)}>
                  Stage 1 <MdOutlineKeyboardArrowDown />
                </h4>
                {/* {showContent} */}
                {showList == 1 && (
                  <>
                    {editShow == true ? (
                      <div className="p-2">
                        <div className="px-2 mode-shippmet-inner">
                          <h5>Mode of shippment</h5>
                          <div className="d-flex flex-wrap px-1">
                            {shippmentCate?.map((item, ind) => {
                              return (
                                <button
                                  key={ind}
                                  onClick={() => {
                                    checkBoxs(ind);
                                  }}
                                  className="d-flex align-items-center mt-1 border-0 bg-transparent me-5"
                                >
                                  <div
                                    className={
                                      catIndexs == ind
                                        ? "check-Btn bg-primary1"
                                        : "check-Btn"
                                    }
                                  >
                                    {catIndexs == ind && <FaCheck />}
                                  </div>
                                  <p className="mb-0">{item?.cat1}</p>
                                </button>
                              );
                            })}
                          </div>
                          <div className="w-100 d-sm-flex flex-wrap input-box-border">
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">09/02/2023</p>
                              <input type="date" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">
                                Venture leather, Chennai
                              </p>
                              <input type="text" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">12/02/2023</p>
                              <input type="date" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">Port-Chennai</p>
                              <input type="text" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">BG8956K789</p>
                              <input type="text" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">LI5674D456</p>
                              <input type="text" />
                            </Col>
                            <div className="w-100 px-1 my-1 position-relative">
                              <p className="dc-date-cont-text">
                                Corporate Courier and Cargo LTD
                              </p>
                              <input type="text" />
                            </div>
                            <div className="w-100 px-1 my-1 position-relative">
                              <p className="dc-date-cont-text">Govindhan</p>
                              <input type="text" />
                            </div>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">+91 895878956</p>
                              <input type="text" />
                            </Col>
                            <Col
                              lg={6}
                              md={8}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">
                                govindhaancourier12@gmail.com
                              </p>
                              <input type="text" />
                            </Col>
                            <div className="w-100 px-1 my-1">
                              <textarea
                                style={{ height: "60px" }}
                                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed nunc condimentum, elementum erat ut, convallis velit. Proin lobortis risus vitae mauris placerat vestibulum."
                              />
                            </div>
                          </div>
                          <Col
                            className="rit-ad-prod"
                            xs={12}
                            style={{ paddingLeft: "3px" }}
                          >
                            <h4 className="relat-doc">Related Documents</h4>
                            <p className="primary1">{certificate}</p>
                            <input
                              className="custom-file-input"
                              onChange={(e) => setCertificate(e.target.value)}
                              type="file"
                            />
                            <h5 className="note">
                              Note: Maximum attachments 3, file jpg, jpeg, pdf.
                            </h5>
                          </Col>
                          <p className="d-flex justify-content-between px-2 py-2 my-2 align-items-center file-upload-cont-bg">
                            <span className="d-flex gap-2">
                              <AiOutlineFileJpg className="text-success fs-4" />
                              Invoice.jpeg
                            </span>
                            <span className="create-inv-delete-icon-cont">
                              <RiDeleteBin6Line />
                            </span>
                          </p>
                          <p className="d-flex justify-content-between px-2 py-2 my-2 align-items-center file-upload-cont-bg">
                            <span className="d-flex gap-2">
                              <AiOutlineFileJpg className="text-success fs-4" />
                              Invoice.jpeg
                            </span>
                            <span className="create-inv-delete-icon-cont">
                              <RiDeleteBin6Line />
                            </span>
                          </p>
                          <div className="d-flex justify-content-end">
                            <button className="cust-btn bg-primary2 text-white me-2">
                              Cancel
                            </button>
                            <button
                              className="cust-btn bg-primary1 text-white me-2"
                              onClick={spSaveClickHandler}
                            >
                              Save
                            </button>
                          </div>
                          <div style={{ height: "70px" }} />
                        </div>
                        <div className="dc-rs-btm-drop-cont my-2 px-2">
                          <span>Stage</span> <BsChevronDown />
                        </div>
                        <div className="dc-rs-btm-drop-cont my-2 px-2">
                          <span>Stage</span> <BsChevronDown />
                        </div>
                        <div className="dc-rs-btm-drop-cont my-2 px-2">
                          <span>Stage</span> <BsChevronDown />
                        </div>
                        <div className="d-sm-flex flex-wrap justify-content-between py-1 my-2">
                          <button className="cust-btn bg-dark  my-2 text-white">
                            Add Stage
                          </button>
                          <div>
                            <button className="cust-btn primary1 my-2 me-2">
                              cancel
                            </button>
                            <button
                              style={{ width: "126px" }}
                              className="primary1 blue-trans-button my-2 me-2"
                            >
                              Save as Draft
                            </button>
                            <button
                              className="cust-btn bg-primary1 text-white my-2 me-2"
                              onClick={spUpdateClickHandler}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div style={{ maxWidth: "434px", overflow: "hidden" }}>
                        <div className="px-2 mode-shippmet-inner">
                          <h5 className="fw-bold  fnt-fam-f2 d-sm-flex gap-1 align-items-center">
                            <span className="primary1 fs-13px">
                              {" "}
                              shippment Details
                            </span>
                            <span
                              className="opac_half fs-14px d-flex text-center pointer"
                              onClick={spEditHandler}
                            >
                              <TbEdit />
                            </span>
                          </h5>
                          <p className="fs-12px py-1 my-1">
                            Mode of Shipment:
                            <span className="fw-bold">Road</span>
                          </p>
                          <div className="d-sm-flex gap-3">
                            <p className="text-nowrap py-1 fs-12px wid-212px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Planned Start Date
                              </span>
                              : 09/02/2023
                            </p>

                            <p className="py-1 text-wrap fs-12px wid-212px">
                              <span className="opac-85">Start Point</span>:
                              Venture Leather, Chennai
                            </p>
                          </div>
                          <div className="d-sm-flex gap-3">
                            <p className="text-nowrap py-1 fs-12px wid-212px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Planned End Date
                              </span>
                              : 12/02/2023
                            </p>
                            <p className="text-nowrap py-1  fs-12px wid-212px">
                              <span className="opac-85">End Point</span>:
                              Port-Chennai
                            </p>
                          </div>
                          <div className="d-sm-flex gap-3">
                            <p className="text-nowrap py-1 fs-12px wid-212px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Way Bill Ref No
                              </span>
                              : BG8956K789
                            </p>
                            <p className="text-nowrap py-1  fs-12px wid-212px">
                              <span className="opac-85">Tracker ID</span>:
                              LI5674D456
                            </p>
                          </div>
                          <div className="">
                            <p className="py-1 text-wrap fs-12px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Transport Company Name
                              </span>
                              : Corporate Courier and Cargo LTD
                            </p>
                            <p className="py-1 text-wrap fs-12px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Contact Person Name
                              </span>
                              : Govindhan Courier and Cargo LTD
                            </p>
                          </div>
                          <div className="d-sm-flex gap-3">
                            <p className="text-nowrap py-1 fs-12px wid-212px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Mobile
                              </span>
                              : 895623589
                            </p>
                            <p className="text-nowrap py-1 fs-12px">
                              <span className="opac-85">Email</span>:
                              govindhancourier12@gmail.com
                            </p>
                          </div>
                          <div className="d-sm-flex flex-column flex-wrap">
                            <label className="py-1 fs-12px po-sp-left-ship-details-span-wid ">
                              Any Comments:
                            </label>
                            <Col
                              xs={12}
                              sm={12}
                              className="py-1 fs-12px text-wrap"
                            >
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nullam sed nunc condimentum, elementum erat
                              ut, convallis velit. Proin lobortis risus vitae
                              mauris placerat vestibulum.
                            </Col>
                          </div>
                          <p className="py-1 fs-12px">Uploaded Document</p>
                          <p className="d-flex gap-2 px-2 py-2 my-2 align-items-center file-upload-cont-bg">
                            <AiOutlineFileJpg className="text-success fs-4" />
                            Delivery Document1.jpeg
                          </p>
                          <p className="d-flex gap-2 px-2 py-2 my-2 align-items-center file-upload-cont-bg">
                            <AiOutlineFileJpg className="text-success fs-4" />
                            Delivery Document2.jpeg
                          </p>
                        </div>
                      </div>
                    )}{" "}
                  </>
                )}
              </div>
              <div className="mode-shippmet trans-x-10px cont-border">
                <h4 className="stage-1" onClick={() => showListHandler(2)}>
                  Stage 2 <MdOutlineKeyboardArrowDown />
                </h4>
                {/* {showContent} */}
                {showList == 2 && (
                  <>
                    {editShow == true ? (
                      <div className="p-2">
                        <div className="px-2 mode-shippmet-inner">
                          <h5>Mode of shippment</h5>
                          <div className="d-flex flex-wrap px-1">
                            {shippmentCate?.map((item, ind) => {
                              return (
                                <button
                                  key={ind}
                                  onClick={() => {
                                    checkBoxs(ind);
                                  }}
                                  className="d-flex align-items-center mt-1 border-0 bg-transparent me-5"
                                >
                                  <div
                                    className={
                                      catIndexs == ind
                                        ? "check-Btn bg-primary1"
                                        : "check-Btn"
                                    }
                                  >
                                    {catIndexs == ind && <FaCheck />}
                                  </div>
                                  <p className="mb-0">{item?.cat1}</p>
                                </button>
                              );
                            })}
                          </div>
                          <div className="w-100 d-sm-flex flex-wrap input-box-border">
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">09/02/2023</p>
                              <input type="date" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">
                                Venture leather, Chennai
                              </p>
                              <input type="text" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">12/02/2023</p>
                              <input type="date" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">Port-Chennai</p>
                              <input type="text" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">BG8956K789</p>
                              <input type="text" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">LI5674D456</p>
                              <input type="text" />
                            </Col>
                            <div className="w-100 px-1 my-1 position-relative">
                              <p className="dc-date-cont-text">
                                Corporate Courier and Cargo LTD
                              </p>
                              <input type="text" />
                            </div>
                            <div className="w-100 px-1 my-1 position-relative">
                              <p className="dc-date-cont-text">Govindhan</p>
                              <input type="text" />
                            </div>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">+91 895878956</p>
                              <input type="text" />
                            </Col>
                            <Col
                              lg={6}
                              md={8}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">
                                govindhaancourier12@gmail.com
                              </p>
                              <input type="text" />
                            </Col>
                            <div className="w-100 px-1 my-1">
                              <textarea
                                style={{ height: "60px" }}
                                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed nunc condimentum, elementum erat ut, convallis velit. Proin lobortis risus vitae mauris placerat vestibulum."
                              />
                            </div>
                          </div>
                          <Col
                            className="rit-ad-prod"
                            xs={12}
                            style={{ paddingLeft: "3px" }}
                          >
                            <h4 className="relat-doc">Related Documents</h4>
                            <p className="primary1">{certificate}</p>
                            <input
                              className="custom-file-input"
                              onChange={(e) => setCertificate(e.target.value)}
                              type="file"
                            />
                            <h5 className="note">
                              Note: Maximum attachments 3, file jpg, jpeg, pdf.
                            </h5>
                          </Col>
                          <p className="d-flex justify-content-between px-2 py-2 my-2 align-items-center file-upload-cont-bg">
                            <span className="d-flex gap-2">
                              <AiOutlineFileJpg className="text-success fs-4" />
                              Invoice.jpeg
                            </span>
                            <span className="create-inv-delete-icon-cont">
                              <RiDeleteBin6Line />
                            </span>
                          </p>
                          <p className="d-flex justify-content-between px-2 py-2 my-2 align-items-center file-upload-cont-bg">
                            <span className="d-flex gap-2">
                              <AiOutlineFileJpg className="text-success fs-4" />
                              Invoice.jpeg
                            </span>
                            <span className="create-inv-delete-icon-cont">
                              <RiDeleteBin6Line />
                            </span>
                          </p>
                          <div className="d-flex justify-content-end">
                            <button className="cust-btn bg-primary2 text-white me-2">
                              Cancel
                            </button>
                            <button
                              className="cust-btn bg-primary1 text-white me-2"
                              onClick={spSaveClickHandler}
                            >
                              Save
                            </button>
                          </div>
                          <div style={{ height: "70px" }} />
                        </div>
                        <div className="dc-rs-btm-drop-cont my-2 px-2">
                          <span>Stage</span> <BsChevronDown />
                        </div>
                        <div className="dc-rs-btm-drop-cont my-2 px-2">
                          <span>Stage</span> <BsChevronDown />
                        </div>
                        <div className="dc-rs-btm-drop-cont my-2 px-2">
                          <span>Stage</span> <BsChevronDown />
                        </div>
                        <div className="d-sm-flex flex-wrap justify-content-between py-1 my-2">
                          <button className="cust-btn bg-dark  my-2 text-white">
                            Add Stage
                          </button>
                          <div>
                            <button className="cust-btn primary1 my-2 me-2">
                              cancel
                            </button>
                            <button
                              style={{ width: "126px" }}
                              className="primary1 blue-trans-button my-2 me-2"
                            >
                              Save as Draft
                            </button>
                            <button
                              className="cust-btn bg-primary1 text-white my-2 me-2"
                              onClick={spUpdateClickHandler}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div style={{ maxWidth: "434px", overflow: "hidden" }}>
                        <div className="px-2 mode-shippmet-inner">
                          <h5 className="fw-bold  fnt-fam-f2 d-sm-flex gap-1 align-items-center">
                            <span className="primary1 fs-13px">
                              {" "}
                              shippment Details
                            </span>
                            <span
                              className="opac_half fs-14px d-flex text-center pointer"
                              onClick={spEditHandler}
                            >
                              <TbEdit />
                            </span>
                          </h5>
                          <p className="fs-12px py-1 my-1">
                            Mode of Shipment:
                            <span className="fw-bold">Road</span>
                          </p>
                          <div className="d-sm-flex gap-3">
                            <p className="text-nowrap py-1 fs-12px wid-212px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Planned Start Date
                              </span>
                              : 09/02/2023
                            </p>

                            <p className="py-1 text-wrap fs-12px wid-212px">
                              <span className="opac-85">Start Point</span>:
                              Venture Leather, Chennai
                            </p>
                          </div>
                          <div className="d-sm-flex gap-3">
                            <p className="text-nowrap py-1 fs-12px wid-212px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Planned End Date
                              </span>
                              : 12/02/2023
                            </p>
                            <p className="text-nowrap py-1  fs-12px wid-212px">
                              <span className="opac-85">End Point</span>:
                              Port-Chennai
                            </p>
                          </div>
                          <div className="d-sm-flex gap-3">
                            <p className="text-nowrap py-1 fs-12px wid-212px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Way Bill Ref No
                              </span>
                              : BG8956K789
                            </p>
                            <p className="text-nowrap py-1  fs-12px wid-212px">
                              <span className="opac-85">Tracker ID</span>:
                              LI5674D456
                            </p>
                          </div>
                          <div className="">
                            <p className="py-1 text-wrap fs-12px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Transport Company Name
                              </span>
                              : Corporate Courier and Cargo LTD
                            </p>
                            <p className="py-1 text-wrap fs-12px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Contact Person Name
                              </span>
                              : Govindhan Courier and Cargo LTD
                            </p>
                          </div>
                          <div className="d-sm-flex gap-3">
                            <p className="text-nowrap py-1 fs-12px wid-212px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Mobile
                              </span>
                              : 895623589
                            </p>
                            <p className="text-nowrap py-1 fs-12px">
                              <span className="opac-85">Email</span>:
                              govindhancourier12@gmail.com
                            </p>
                          </div>
                          <div className="d-sm-flex flex-column flex-wrap">
                            <label className="py-1 fs-12px po-sp-left-ship-details-span-wid ">
                              Any Comments:
                            </label>
                            <Col
                              xs={12}
                              sm={12}
                              className="py-1 fs-12px text-wrap"
                            >
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nullam sed nunc condimentum, elementum erat
                              ut, convallis velit. Proin lobortis risus vitae
                              mauris placerat vestibulum.
                            </Col>
                          </div>
                          <p className="py-1 fs-12px">Uploaded Document</p>
                          <p className="d-flex gap-2 px-2 py-2 my-2 align-items-center file-upload-cont-bg">
                            <AiOutlineFileJpg className="text-success fs-4" />
                            Delivery Document1.jpeg
                          </p>
                          <p className="d-flex gap-2 px-2 py-2 my-2 align-items-center file-upload-cont-bg">
                            <AiOutlineFileJpg className="text-success fs-4" />
                            Delivery Document2.jpeg
                          </p>
                        </div>
                      </div>
                    )}{" "}
                  </>
                )}
              </div>
              <div className="mode-shippmet trans-x-10px cont-border">
                <h4 className="stage-1" onClick={() => showListHandler(3)}>
                  Stage 3 <MdOutlineKeyboardArrowDown />
                </h4>
                {/* {showContent} */}
                {showList == 3 && (
                  <>
                    {editShow == true ? (
                      <div className="p-2">
                        <div className="px-2 mode-shippmet-inner">
                          <h5>Mode of shippment</h5>
                          <div className="d-flex flex-wrap px-1">
                            {shippmentCate?.map((item, ind) => {
                              return (
                                <button
                                  key={ind}
                                  onClick={() => {
                                    checkBoxs(ind);
                                  }}
                                  className="d-flex align-items-center mt-1 border-0 bg-transparent me-5"
                                >
                                  <div
                                    className={
                                      catIndexs == ind
                                        ? "check-Btn bg-primary1"
                                        : "check-Btn"
                                    }
                                  >
                                    {catIndexs == ind && <FaCheck />}
                                  </div>
                                  <p className="mb-0">{item?.cat1}</p>
                                </button>
                              );
                            })}
                          </div>
                          <div className="w-100 d-sm-flex flex-wrap input-box-border">
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">09/02/2023</p>
                              <input type="date" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">
                                Venture leather, Chennai
                              </p>
                              <input type="text" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">12/02/2023</p>
                              <input type="date" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">Port-Chennai</p>
                              <input type="text" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">BG8956K789</p>
                              <input type="text" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">LI5674D456</p>
                              <input type="text" />
                            </Col>
                            <div className="w-100 px-1 my-1 position-relative">
                              <p className="dc-date-cont-text">
                                Corporate Courier and Cargo LTD
                              </p>
                              <input type="text" />
                            </div>
                            <div className="w-100 px-1 my-1 position-relative">
                              <p className="dc-date-cont-text">Govindhan</p>
                              <input type="text" />
                            </div>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">+91 895878956</p>
                              <input type="text" />
                            </Col>
                            <Col
                              lg={6}
                              md={8}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">
                                govindhaancourier12@gmail.com
                              </p>
                              <input type="text" />
                            </Col>
                            <div className="w-100 px-1 my-1">
                              <textarea
                                style={{ height: "60px" }}
                                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed nunc condimentum, elementum erat ut, convallis velit. Proin lobortis risus vitae mauris placerat vestibulum."
                              />
                            </div>
                          </div>
                          <Col
                            className="rit-ad-prod"
                            xs={12}
                            style={{ paddingLeft: "3px" }}
                          >
                            <h4 className="relat-doc">Related Documents</h4>
                            <p className="primary1">{certificate}</p>
                            <input
                              className="custom-file-input"
                              onChange={(e) => setCertificate(e.target.value)}
                              type="file"
                            />
                            <h5 className="note">
                              Note: Maximum attachments 3, file jpg, jpeg, pdf.
                            </h5>
                          </Col>
                          <p className="d-flex justify-content-between px-2 py-2 my-2 align-items-center file-upload-cont-bg">
                            <span className="d-flex gap-2">
                              <AiOutlineFileJpg className="text-success fs-4" />
                              Invoice.jpeg
                            </span>
                            <span className="create-inv-delete-icon-cont">
                              <RiDeleteBin6Line />
                            </span>
                          </p>
                          <p className="d-flex justify-content-between px-2 py-2 my-2 align-items-center file-upload-cont-bg">
                            <span className="d-flex gap-2">
                              <AiOutlineFileJpg className="text-success fs-4" />
                              Invoice.jpeg
                            </span>
                            <span className="create-inv-delete-icon-cont">
                              <RiDeleteBin6Line />
                            </span>
                          </p>
                          <div className="d-flex justify-content-end">
                            <button className="cust-btn bg-primary2 text-white me-2">
                              Cancel
                            </button>
                            <button
                              className="cust-btn bg-primary1 text-white me-2"
                              onClick={spSaveClickHandler}
                            >
                              Save
                            </button>
                          </div>
                          <div style={{ height: "70px" }} />
                        </div>
                        <div className="dc-rs-btm-drop-cont my-2 px-2">
                          <span>Stage</span> <BsChevronDown />
                        </div>
                        <div className="dc-rs-btm-drop-cont my-2 px-2">
                          <span>Stage</span> <BsChevronDown />
                        </div>
                        <div className="dc-rs-btm-drop-cont my-2 px-2">
                          <span>Stage</span> <BsChevronDown />
                        </div>
                        <div className="d-sm-flex flex-wrap justify-content-between py-1 my-2">
                          <button className="cust-btn bg-dark  my-2 text-white">
                            Add Stage
                          </button>
                          <div>
                            <button className="cust-btn primary1 my-2 me-2">
                              cancel
                            </button>
                            <button
                              style={{ width: "126px" }}
                              className="primary1 blue-trans-button my-2 me-2"
                            >
                              Save as Draft
                            </button>
                            <button
                              className="cust-btn bg-primary1 text-white my-2 me-2"
                              onClick={spUpdateClickHandler}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div style={{ maxWidth: "434px", overflow: "hidden" }}>
                        <div className="px-2 mode-shippmet-inner">
                          <h5 className="fw-bold  fnt-fam-f2 d-sm-flex gap-1 align-items-center">
                            <span className="primary1 fs-13px">
                              {" "}
                              shippment Details
                            </span>
                            <span
                              className="opac_half fs-14px d-flex text-center pointer"
                              onClick={spEditHandler}
                            >
                              <TbEdit />
                            </span>
                          </h5>
                          <p className="fs-12px py-1 my-1">
                            Mode of Shipment:
                            <span className="fw-bold">Road</span>
                          </p>
                          <div className="d-sm-flex gap-3">
                            <p className="text-nowrap py-1 fs-12px wid-212px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Planned Start Date
                              </span>
                              : 09/02/2023
                            </p>

                            <p className="py-1 text-wrap fs-12px wid-212px">
                              <span className="opac-85">Start Point</span>:
                              Venture Leather, Chennai
                            </p>
                          </div>
                          <div className="d-sm-flex gap-3">
                            <p className="text-nowrap py-1 fs-12px wid-212px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Planned End Date
                              </span>
                              : 12/02/2023
                            </p>
                            <p className="text-nowrap py-1  fs-12px wid-212px">
                              <span className="opac-85">End Point</span>:
                              Port-Chennai
                            </p>
                          </div>
                          <div className="d-sm-flex gap-3">
                            <p className="text-nowrap py-1 fs-12px wid-212px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Way Bill Ref No
                              </span>
                              : BG8956K789
                            </p>
                            <p className="text-nowrap py-1  fs-12px wid-212px">
                              <span className="opac-85">Tracker ID</span>:
                              LI5674D456
                            </p>
                          </div>
                          <div className="">
                            <p className="py-1 text-wrap fs-12px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Transport Company Name
                              </span>
                              : Corporate Courier and Cargo LTD
                            </p>
                            <p className="py-1 text-wrap fs-12px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Contact Person Name
                              </span>
                              : Govindhan Courier and Cargo LTD
                            </p>
                          </div>
                          <div className="d-sm-flex gap-3">
                            <p className="text-nowrap py-1 fs-12px wid-212px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Mobile
                              </span>
                              : 895623589
                            </p>
                            <p className="text-nowrap py-1 fs-12px">
                              <span className="opac-85">Email</span>:
                              govindhancourier12@gmail.com
                            </p>
                          </div>
                          <div className="d-sm-flex flex-column flex-wrap">
                            <label className="py-1 fs-12px po-sp-left-ship-details-span-wid ">
                              Any Comments:
                            </label>
                            <Col
                              xs={12}
                              sm={12}
                              className="py-1 fs-12px text-wrap"
                            >
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nullam sed nunc condimentum, elementum erat
                              ut, convallis velit. Proin lobortis risus vitae
                              mauris placerat vestibulum.
                            </Col>
                          </div>
                          <p className="py-1 fs-12px">Uploaded Document</p>
                          <p className="d-flex gap-2 px-2 py-2 my-2 align-items-center file-upload-cont-bg">
                            <AiOutlineFileJpg className="text-success fs-4" />
                            Delivery Document1.jpeg
                          </p>
                          <p className="d-flex gap-2 px-2 py-2 my-2 align-items-center file-upload-cont-bg">
                            <AiOutlineFileJpg className="text-success fs-4" />
                            Delivery Document2.jpeg
                          </p>
                        </div>
                      </div>
                    )}{" "}
                  </>
                )}
              </div>
              <div className="mode-shippmet trans-x-10px cont-border">
                <h4 className="stage-1" onClick={() => showListHandler(4)}>
                  Stage 4 <MdOutlineKeyboardArrowDown />
                </h4>
                {/* {showContent} */}
                {showList == 4 && (
                  <>
                    {editShow == true ? (
                      <div className="p-2">
                        <div className="px-2 mode-shippmet-inner">
                          <h5>Mode of shippment</h5>
                          <div className="d-flex flex-wrap px-1">
                            {shippmentCate?.map((item, ind) => {
                              return (
                                <button
                                  key={ind}
                                  onClick={() => {
                                    checkBoxs(ind);
                                  }}
                                  className="d-flex align-items-center mt-1 border-0 bg-transparent me-5"
                                >
                                  <div
                                    className={
                                      catIndexs == ind
                                        ? "check-Btn bg-primary1"
                                        : "check-Btn"
                                    }
                                  >
                                    {catIndexs == ind && <FaCheck />}
                                  </div>
                                  <p className="mb-0">{item?.cat1}</p>
                                </button>
                              );
                            })}
                          </div>
                          <div className="w-100 d-sm-flex flex-wrap input-box-border">
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">09/02/2023</p>
                              <input type="date" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">
                                Venture leather, Chennai
                              </p>
                              <input type="text" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">12/02/2023</p>
                              <input type="date" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">Port-Chennai</p>
                              <input type="text" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">BG8956K789</p>
                              <input type="text" />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">LI5674D456</p>
                              <input type="text" />
                            </Col>
                            <div className="w-100 px-1 my-1 position-relative">
                              <p className="dc-date-cont-text">
                                Corporate Courier and Cargo LTD
                              </p>
                              <input type="text" />
                            </div>
                            <div className="w-100 px-1 my-1 position-relative">
                              <p className="dc-date-cont-text">Govindhan</p>
                              <input type="text" />
                            </div>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">+91 895878956</p>
                              <input type="text" />
                            </Col>
                            <Col
                              lg={6}
                              md={8}
                              sm={12}
                              className="px-1 my-1 position-relative"
                            >
                              <p className="dc-date-cont-text">
                                govindhaancourier12@gmail.com
                              </p>
                              <input type="text" />
                            </Col>
                            <div className="w-100 px-1 my-1">
                              <textarea
                                style={{ height: "60px" }}
                                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed nunc condimentum, elementum erat ut, convallis velit. Proin lobortis risus vitae mauris placerat vestibulum."
                              />
                            </div>
                          </div>
                          <Col
                            className="rit-ad-prod"
                            xs={12}
                            style={{ paddingLeft: "3px" }}
                          >
                            <h4 className="relat-doc">Related Documents</h4>
                            <p className="primary1">{certificate}</p>
                            <input
                              className="custom-file-input"
                              onChange={(e) => setCertificate(e.target.value)}
                              type="file"
                            />
                            <h5 className="note">
                              Note: Maximum attachments 3, file jpg, jpeg, pdf.
                            </h5>
                          </Col>
                          <p className="d-flex justify-content-between px-2 py-2 my-2 align-items-center file-upload-cont-bg">
                            <span className="d-flex gap-2">
                              <AiOutlineFileJpg className="text-success fs-4" />
                              Invoice.jpeg
                            </span>
                            <span className="create-inv-delete-icon-cont">
                              <RiDeleteBin6Line />
                            </span>
                          </p>
                          <p className="d-flex justify-content-between px-2 py-2 my-2 align-items-center file-upload-cont-bg">
                            <span className="d-flex gap-2">
                              <AiOutlineFileJpg className="text-success fs-4" />
                              Invoice.jpeg
                            </span>
                            <span className="create-inv-delete-icon-cont">
                              <RiDeleteBin6Line />
                            </span>
                          </p>
                          <div className="d-flex justify-content-end">
                            <button className="cust-btn bg-primary2 text-white me-2">
                              Cancel
                            </button>
                            <button
                              className="cust-btn bg-primary1 text-white me-2"
                              onClick={spSaveClickHandler}
                            >
                              Save
                            </button>
                          </div>
                          <div style={{ height: "70px" }} />
                        </div>
                        <div className="dc-rs-btm-drop-cont my-2 px-2">
                          <span>Stage</span> <BsChevronDown />
                        </div>
                        <div className="dc-rs-btm-drop-cont my-2 px-2">
                          <span>Stage</span> <BsChevronDown />
                        </div>
                        <div className="dc-rs-btm-drop-cont my-2 px-2">
                          <span>Stage</span> <BsChevronDown />
                        </div>
                        <div className="d-sm-flex flex-wrap justify-content-between py-1 my-2">
                          <button className="cust-btn bg-dark  my-2 text-white">
                            Add Stage
                          </button>
                          <div>
                            <button className="cust-btn primary1 my-2 me-2">
                              cancel
                            </button>
                            <button
                              style={{ width: "126px" }}
                              className="primary1 blue-trans-button my-2 me-2"
                            >
                              Save as Draft
                            </button>
                            <button
                              className="cust-btn bg-primary1 text-white my-2 me-2"
                              onClick={spUpdateClickHandler}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div style={{ maxWidth: "434px", overflow: "hidden" }}>
                        <div className="px-2 mode-shippmet-inner">
                          <h5 className="fw-bold  fnt-fam-f2 d-sm-flex gap-1 align-items-center">
                            <span className="primary1 fs-13px">
                              {" "}
                              shippment Details
                            </span>
                            <span
                              className="opac_half fs-14px d-flex text-center pointer"
                              onClick={spEditHandler}
                            >
                              <TbEdit />
                            </span>
                          </h5>
                          <p className="fs-12px py-1 my-1">
                            Mode of Shipment:
                            <span className="fw-bold">Road</span>
                          </p>
                          <div className="d-sm-flex gap-3">
                            <p className="text-nowrap py-1 fs-12px wid-212px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Planned Start Date
                              </span>
                              : 09/02/2023
                            </p>

                            <p className="py-1 text-wrap fs-12px wid-212px">
                              <span className="opac-85">Start Point</span>:
                              Venture Leather, Chennai
                            </p>
                          </div>
                          <div className="d-sm-flex gap-3">
                            <p className="text-nowrap py-1 fs-12px wid-212px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Planned End Date
                              </span>
                              : 12/02/2023
                            </p>
                            <p className="text-nowrap py-1  fs-12px wid-212px">
                              <span className="opac-85">End Point</span>:
                              Port-Chennai
                            </p>
                          </div>
                          <div className="d-sm-flex gap-3">
                            <p className="text-nowrap py-1 fs-12px wid-212px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Way Bill Ref No
                              </span>
                              : BG8956K789
                            </p>
                            <p className="text-nowrap py-1  fs-12px wid-212px">
                              <span className="opac-85">Tracker ID</span>:
                              LI5674D456
                            </p>
                          </div>
                          <div className="">
                            <p className="py-1 text-wrap fs-12px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Transport Company Name
                              </span>
                              : Corporate Courier and Cargo LTD
                            </p>
                            <p className="py-1 text-wrap fs-12px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Contact Person Name
                              </span>
                              : Govindhan Courier and Cargo LTD
                            </p>
                          </div>
                          <div className="d-sm-flex gap-3">
                            <p className="text-nowrap py-1 fs-12px wid-212px">
                              <span className="opac-85 po-sp-left-ship-details-span-wid ">
                                Mobile
                              </span>
                              : 895623589
                            </p>
                            <p className="text-nowrap py-1 fs-12px">
                              <span className="opac-85">Email</span>:
                              govindhancourier12@gmail.com
                            </p>
                          </div>
                          <div className="d-sm-flex flex-column flex-wrap">
                            <label className="py-1 fs-12px po-sp-left-ship-details-span-wid ">
                              Any Comments:
                            </label>
                            <Col
                              xs={12}
                              sm={12}
                              className="py-1 fs-12px text-wrap"
                            >
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nullam sed nunc condimentum, elementum erat
                              ut, convallis velit. Proin lobortis risus vitae
                              mauris placerat vestibulum.
                            </Col>
                          </div>
                          <p className="py-1 fs-12px">Uploaded Document</p>
                          <p className="d-flex gap-2 px-2 py-2 my-2 align-items-center file-upload-cont-bg">
                            <AiOutlineFileJpg className="text-success fs-4" />
                            Delivery Document1.jpeg
                          </p>
                          <p className="d-flex gap-2 px-2 py-2 my-2 align-items-center file-upload-cont-bg">
                            <AiOutlineFileJpg className="text-success fs-4" />
                            Delivery Document2.jpeg
                          </p>
                        </div>
                      </div>
                    )}{" "}
                  </>
                )}
              </div>
            </>
          )}
        </Col>
        <Modal
          className="border-0 border"
          id="#Save-terms"
          show={showModal}
          size="sm"
          centered
          onHide={() => setShowModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className="sup-modal pt-3">
            <img className="fav-icon ms-2" src={FavLogo} />
            <div className="mt-0">
              <div className="d-flex-as-jc img_tick">
                <BsExclamationCircle className="primary2" />
                <div className="px-1 ms-2 mb-3">
                  <h5>Do you want to Cancel</h5>
                  <p>If yes any unsaved data will be lost</p>
                </div>
              </div>
              <div className="d-flex bg-light-blue px-2 justify-content-end">
                <button
                  className="my-2 cust-btn bg-lit-primary4 me-2"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Back
                </button>
                <button
                  className="my-2 cust-btn bg-primary1 text-white"
                  onClick={spSaveModalYesHandler}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="border-0 border update-sp-modal"
          id="#update-sp-terms"
          show={updateModal}
          size="md"
          centered
          onHide={() => setUpdateModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className="pt-3">
            <img
              className="fav-icon ms-2"
              src={FavLogo}
              style={{ width: "41px" }}
            />
            <div className="mt-0">
              <div className="d-flex-as-jc img_tick">
                <BsExclamationCircle className="primary2" />
                <div className="px-1 ms-2 mb-3">
                  <h5 className="fs-14px">
                    Do you want to Cancel Logistics Tracker Updation?
                  </h5>
                  <p className="fs-12px">
                    If yes any unsaved data will be lost
                  </p>
                </div>
              </div>
              <div className="d-flex bg-light-blue px-2 justify-content-end">
                <button
                  className="my-2 cust-btn bg-lit-primary4 me-2"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  No
                </button>
                <button
                  className="my-2 cust-btn bg-primary1 text-white"
                  onClick={spUpdateModalYesHandler}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </Col>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default LogisticsTracker;
