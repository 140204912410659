import TopHeader from "../../../navbar/TopHeader";
import React, { useEffect } from "react";
import TopBar from "../../../navbar/TopBar";
import LogInFooter from "../../../logincomponent/LogInFooter";
import { Col, Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReturnNoticeFilterSec from "./RNFilterSec";
import ReturnNoticeTC from "./ReturnNoticeTable";
import { useState } from "react";
import Pagination from "react-bootstrap/Pagination";
import { RiDeleteBack2Line } from "react-icons/ri";
import { BsFillReplyAllFill } from "react-icons/bs";
import { FaEllipsisV } from "react-icons/fa";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { HiDocumentSearch } from "react-icons/hi";
import ViewDetails from "../../../assets/Icons/Normal Icons/ViewDetails.svg";
import Filter from "../../../assets/Icons/Normal Icons/Filter.svg";
import Search from "../../../assets/Icons/Normal Icons/Search.svg";
import ReactPaginate from "react-paginate";
import ErrorModel from "../../../constants/Services/Api/errorModel";
import { api } from "../../../constants/Services";
import moment from "moment/moment";

function ReturnNoticePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  const [supplierCard, setOpenSupplierCard] = useState(false);
  const [btn, setBtn] = useState(false);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [list, setList] = useState([]);
  const [listTable, setListTable] = useState([]);
  const [remark, setRemark] = useState();
  const [remarkList, setRemarkList] = useState();
  const [remarkBox, setRemarkBox] = useState(true);

  const supplierResShowHandler = () => setOpenSupplierCard(true);

  const rnSuplierResHandler = () => setOpenSupplierCard(false);

  // console.log("data", data);

  const getList = () => {
    let params = `?return_id=${
      data?.LeatherReturnToSupplierID ||
      data?.MaterialReturnToSupplierID ||
      data?.ReturnToSupplierID
    }&factory_id=${data?.FactoryID}&material_type=${
      data?.LeatherReturnToSupplierID
        ? "L"
        : data?.MaterialReturnToSupplierID
        ? "L"
        : data?.type
        ? data?.type
        : ""
    }`;
    api.returnNoteView(params).then((res) => {
      if (res?.status == "success") {
        setList(res?.return_note);
        setListTable(res?.return_details);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const getRemarks = (remarks) => {
    setRemark(remarks?.Remarks);
    if (remarks?.Remarks) {
      setRemarkBox(false);
    }
    setRemarkList(remarks);
    setOpenSupplierCard(true);
  };

  const remarkSubmit = () => {
    let params = `?return_detail_id=${
      remarkList?.LeatherReturnToSupplierDetailID ||
      remarkList?.MaterialReturnToSupplierDetailID
    }&factory_id=${remarkList?.FactoryID}&material_type=${
      remarkList?.LeatherReturnToSupplierID
        ? "L"
        : remarkList?.LeatherReturnToSupplierID
        ? "M"
        : data?.type
        ? data?.type
        : ""
    }&remarks=${remark}`;
    setBtn(true);
    api.returnRemarks(params).then((res) => {
      if (res?.status == "success") {
        setBtn(false);
        setOpenSupplierCard(false);
        setError(res);
        setErrorBox(true);
        setRemarkBox(true);
        setTimeout(() => {
          getList();
          errorModalClose();
        }, 800);
      } else {
        setBtn(false);
        setRemarkBox(true);
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    if (data) {
      getList();
    } else {
      navigate("/purchase-order/return-notice-table");
    }
  }, []);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  return (
    <>
      <TopBar />
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopHeader type="return-notice" />
      <div className="m-3 p-3 d-sm-flex flex-column flex-wrap">
        <h6 className="primary1 mb-2 fnt-fam-f2">Return Note</h6>
        <div className="fnt-fam-f2 my-3 d-flex flex-column flex-wrap gap-2 opac-85 border-btm">
          <p className="d-flex flex-wrap gap-3">
            <span className="wid-11r">
              Return Note:{" "}
              {list?.LeatherReturnToSupplierDate
                ? moment(list?.LeatherReturnToSupplierDate).format(
                    "DD-MMM-YYYY"
                  )
                : list?.MaterialReturnToSupplierDate
                ? moment(list?.MaterialReturnToSupplierDate).format(
                    "DD-MMM-YYYY"
                  )
                : ""}
            </span>
            <span className="wid-11r">
              Return date:{" "}
              {list?.LeatherReturnToSupplierDate
                ? moment(list?.LeatherReturnToSupplierDate).format(
                    "DD-MMM-YYYY"
                  )
                : list?.MaterialReturnToSupplierDate
                ? moment(list?.MaterialReturnToSupplierDate).format(
                    "DD-MMM-YYYY"
                  )
                : ""}
            </span>
            <span className="wid-11r">Return Type: {list?.ReturnTypeID}</span>
          </p>
          <div className="py-3 d-sm-flex gap-1">
            <p>Remarks:</p>
            <p>{list?.Remarks}</p>
          </div>
        </div>
        <h6 className="primary1 mb-2 pt-2 fnt-fam-f2">Bill of Materials</h6>
        <div className="d-md-flex d-flex justify-content-between flex-wrap mb-3 align-items-center">
          <div className="d-flex-wrap align-items-center gap-1 mb-0">
            <label>No of Items</label>
            <label>{listTable?.length}</label>
          </div>
          {/* <div className="d-flex flex-wrap mt-2 mt-sm-0">
            <div
              style={{ height: "41px", width: "250px" }}
              className="search-box grn-search-bar d-flex gap-2 align-items-center px-2 rounded-1"
            >
              <input placeholder="Search Title" />
              <img className="search-icon" src={Search} />
            </div>
            <div className="d-flex gap-2 flex-wrap me-2 pe-1">
              <button className="grn-filter-btn bg-primary2">
                <img src={Filter} className="wid-15px me-1" />
                Filter
              </button>
            </div>
          </div> */}
        </div>
        <div className="table-scroll-horizontal over-flow-x-scroll  mb-2">
          <table className="my-3 py-2 table">
            <thead style={{ background: "#ededed" }}>
              <tr className="text-center text-nowrap fnt-fam-f3 opac-85 ">
                <th>Sl.No </th>
                <th className="text-nowrap">GRN NO</th>
                <th className="">DC NO</th>
                <th className="text-nowrap">Supplier Material Code</th>
                <th className="text-nowrap">KH Material SKU</th>
                <th>HSN Code</th>
                <th>UOM</th>
                <th>Return Qty</th>
                <th>Supplier Response</th>
              </tr>
            </thead>
            <tbody className="fs-6 opac-80">
              {listTable?.map((item, ind) => {
                return (
                  <tr key={ind} className="text-center">
                    <td>{ind + 1}</td>
                    <td>
                      {item?.material_goods_receipt_note_detail
                        ?.material_goods_receipt_note
                        ?.MaterialGoodsReceiptNoteNo
                        ? item?.material_goods_receipt_note_detail
                            ?.material_goods_receipt_note
                            ?.MaterialGoodsReceiptNoteNo
                        : item?.leather_goods_receipt_note_detail
                            ?.leather_goods_receipt_note
                            ?.LeatherGoodsReceiptNoteNo
                        ? item?.leather_goods_receipt_note_detail
                            ?.leather_goods_receipt_note
                            ?.LeatherGoodsReceiptNoteNo
                        : ""}
                    </td>
                    <td>
                      {" "}
                      {item?.material_goods_receipt_note_detail
                        ?.material_goods_receipt_note?.DCNo
                        ? item?.material_goods_receipt_note_detail
                            ?.material_goods_receipt_note?.DCNo
                        : item?.leather_goods_receipt_note_detail
                            ?.leather_goods_receipt_note?.DCNo
                        ? item?.leather_goods_receipt_note_detail
                            ?.leather_goods_receipt_note?.DCNo
                        : ""}
                    </td>
                    <td>
                      {item?.sku?.LeatherSKUCode || item?.sku?.MaterialSKUCode}
                    </td>
                    <td>
                      <p className=" my-1">
                        {item?.sku?.LeatherSKUName ||
                          item?.sku?.MaterialSKUName}
                        {/* <span className="font-col-sec">
                          <img src={ViewDetails} className="wid-15px ms-1" />
                        </span> */}
                      </p>
                      {/* <p className="text-wrap my-1  wid_15">
                        text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                        Richard McClintock, a
                      </p> */}
                    </td>
                    <td>
                      {item?.sku?.leather_header?.hsn?.HSNCode ||
                        item?.sku?.material_header?.hsn?.HSNCode}
                    </td>
                    <td>
                      {item?.sku?.leather_header?.uom?.UomDescription ||
                        item?.sku?.material_header?.uom?.UomDescription}
                    </td>
                    <td>{Math.round(item?.ReturnQuantity)}</td>
                    <td>
                      <div className="enq-drop-whole-container">
                        <button
                          className="cust-btn dropdown-container-tool p-0"
                          onClick={() => getRemarks(item)}
                        >
                          <BsFillReplyAllFill />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
              {/* <tr>
                <td>05</td>
                <td>16</td>
                <td>GR89561897</td>
                <td>Ju2356J5656</td>
                <td>
                  <p className=" my-1">
                    R95CH456789
                    <span className="font-col-sec">
                      <img src={ViewDetails} className="wid-15px ms-1" />
                    </span>
                  </p>
                  <p className="text-wrap my-1  wid_15">
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a
                  </p>
                </td>
                <td>GT4569k2415</td>
                <td>Box</td>
                <td>25</td>
                <td>
                  <div className="enq-drop-whole-container">
                    <button
                      className="cust-btn dropdown-container-tool p-0"
                      onClick={supplierResShowHandler}
                    >
                      <BsFillReplyAllFill />
                    </button>
                  </div>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
        <div className="d-sm-flex justify-content-between mb-3 align-items-center">
          {/* <div className="d-flex-wrap align-items-center gap-2 mb-0">
            <label>Items Per Page</label>
            <select
              name=""
              className="dropdown-btm-grey px-2 py-1"
              onChange={setListItems}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
            <label>1-10 of 35</label>
          </div> */}
        </div>
        <div className="pe-3 my-5  d-md-flex justify-content-end flex-wrap gap-1 align-items-center">
          <button
            onClick={() => navigate("/dashboard")}
            className="fnt-blue-col  fnt-fam-f2 thin-light-grey-bg rounded-1 border-0 rn-reply-btn"
          >
            Cancel
          </button>
          {/* <button className="fnt-blue-col rounded-1 fnt-fam-f2 rn-reply-btn blue-btn-bor-thick">
            Reply
          </button> */}
          <button
            className="btn rounded-1 bg-primary1 rn-sub-btn fnt-fam-f2 text-white mt-1 sub-btn"
            onClick={() => navigate("/dc-list")}
          >
            {/* Reply & DC Creation */}
            Submit
          </button>
        </div>
        <Modal
          id="#grn-card-supplier"
          className="po-acknoweld-card-cont px-3 fnt-fam-f2 pt-3 pb-1"
          show={supplierCard}
          size="md"
          centered
          onHide={() => setOpenSupplierCard(false)}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className=" d-sm-flex  flex-wrap justify-content-between align-items-center mt-3">
            <h6 className="primary1 fnt-fam-f2">Supplier Response</h6>
            <p
              className="fnt-fam-f3 fs-5"
              onClick={() => {
                rnSuplierResHandler();
                setRemarkBox(true);
              }}
            >
              <RiDeleteBack2Line />
            </p>
          </div>

          <label className="my-3 fnt-fam-f2">
            Remarks<span className="red-text">*</span>
          </label>
          <textarea
            className="border 0 border-btm"
            maxLength={200}
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            disabled={remarkBox ? false : true}
          />
          <p className="d-flex justify-content-end opac-85">Max 200</p>
          <div className="d-flex mt-4 mb-1 justify-content-end gap-3">
            <button
              className="clear-can-btn border-0 rounded-1 fnt-fam-f2"
              style={{ color: "black" }}
              onClick={() => {
                rnSuplierResHandler();
                setRemarkBox(true);
              }}
              disabled={btn ? true : false}
            >
              Clear
            </button>
            {remarkBox && (
              <button
                className="bg-primary1 sub-app-btn text-white rounded-1 border-0 fnt-fam-f2"
                onClick={remarkSubmit}
                disabled={btn ? true : false}
              >
                Submit
              </button>
            )}
          </div>
        </Modal>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default ReturnNoticePage;
