import React from "react";
import { Col } from "react-bootstrap";
import TableASN from "../../../component/ASN/TableASN";
import TopASN from "../../../component/ASN/TopASN";
import GeneralPagination from "../../../component/GeneralPagination";
import ASNList from "../../../component/PurchaseOrder/ASNList";
import POFilter from "../../../component/PurchaseOrder/POFilter";
import LogInFooter from "../../../logincomponent/LogInFooter";
import TopBar from "../../../navbar/TopBar";
import TopHeader from "../../../navbar/TopHeader";

const ASNListScreen = () => {
  return (
    <>
      <TopBar />
      <TopHeader type="asn" />
      <div
        style={{ minHeight: "80vh" }}
        className="enquiry-list-cont my-3 py-2 position-relative"
      >
        {/* <Col className="px-3 m-xxl-auto" xs={12} xxl={8}>  */}
        <Col className="px-5 m-xxl-auto" xs={12}>
          {/* <POFilter /> */}
          {/* <TopASN /> */}
          <ASNList />
          {/* <TableASN /> */}
          {/* <GeneralPagination /> */}
        </Col>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
};

export default ASNListScreen;
