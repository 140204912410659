import React, { useState, useEffect } from "react";
import TopHeader from "../../navbar/TopHeader";
import TopBar from "../../navbar/TopBar";
import { HiArrowNarrowLeft } from "react-icons/hi";
import ErrorModel from "../../constants/Services/Api/errorModel";

import { AiOutlineDown } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../constants/Services";
import { useLocation } from "react-router-dom";
import { AiOutlineFileJpg, AiOutlineFilePdf } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FileUploader } from "react-drag-drop-files";
import { FaExclamationCircle } from "react-icons/fa";
import { Col } from "react-bootstrap";
import Select from "react-select";

import LogInFooter from "../../logincomponent/LogInFooter";
import moment from "moment/moment";
import DatePicker from "../../layout/datePicker";

function CreateCommercialInvoice() {
  const fileTypes = ["JPEG", "PNG", "GIF"];
  const location = useLocation();
  const navigate = useNavigate();
  const upload_details = location?.state?.checkboxs;
  const Commercial = location?.state?.commercial;
  const view = location?.state?.item;
  const type = location?.state?.type;
  const [inVRef, setInvRef] = useState("");
  const [invDate, setInvDate] = useState(new Date());
  const [invAmount, setInvAmount] = useState("");
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [invState, setInvState] = useState("");
  const [drop, setDrop] = useState(false);
  const [currency, setCurrency] = useState("");
  const [poCheck, setPoCheck] = useState([]);
  const [dcCheck, setDcCheck] = useState([]);
  const [dcDrop, setDcDrop] = useState(false);
  const [dcDropDetails, setDcDropDetails] = useState([]);
  const [supFile, setSupFile] = useState(null);
  const [supFiles, setSupFiles] = useState(null);
  const [grnDrop, setGrnDrop] = useState(false);
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState(null);
  const [copyInvoiceDoc, setCopyInvoiceDoc] = useState(0);
  const [copyInvoiceFile, setCopyInvoiceFile] = useState('');
  const [copySupInvoiceDoc, setCopySupInvoiceDoc] = useState(0);
  const [copySupInvoiceFile, setCopySupInvoiceFile] = useState('');
  const [supInvoiceUrl, setSupInvoiceUrl] = useState('');
  const [copyInvoiceUrl, setCopyInvoiceUrl] = useState('');
  const [copyInvoiceType, setCopyInvoiceType] = useState('');
  const [supInvoiceType, setSupInvoiceType] = useState('');
  // SELECT BOX
  const [option, setoption] = useState([]);
  const [input, setInput] = useState([]);
  const [optionChange, setOptionChange] = useState();
  const [options, setoptions] = useState([]);
  const [optionChanges, setOptionChanges] = useState([]);

  const getList = () => {
    setCopyInvoiceUrl(view.invoice_url);
    api.createDetails().then((res) => {
      setCopyInvoiceFile(view.copy_invoice && view.copy_invoice.split('/').pop());
      if (res?.status == "success") {
        setInput(res);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    let temp = [];
    let option = [];

    if (Commercial) {
      temp.push(Commercial);
      console.log(Commercial, 'Commercial');
      setOptionChange([
        {
          value: {
            id: Commercial?.purchase_order_id,
            type: Commercial?.type,
            factory_id: Commercial?.FactoryID,
          },
          label: Commercial?.purchase_order_no,
        },
      ]);
    } else if (upload_details) {
      upload_details.map((item, ind) => {
        temp.push(item);
      });
    }

    temp.map((item, ind) => {
      option.push({
        value: {
          id: item?.purchase_order_id,
          type: item?.type,
          factory_id: item?.FactoryID,
        },
        label: item?.purchase_order_no,
      });
    });

    setoption(option);
  }, [Commercial, upload_details]);

  // console.log("option", option);

  // console.log("optionChange", optionChange);
  // console.log("optionChanges", optionChanges);

  useEffect(() => {
    let temp = [];
    let temps = [];
    if (optionChange) {
      temp.push(+optionChange?.value);
      setPoCheck(temp);
    }

    if (optionChanges) {
      optionChanges?.map((item, ind) => {
        temps.push(+item?.value);
      });
      setDcCheck(temps);
    }
  }, [optionChange, optionChanges]);

  const handleChange = (file) => {
    setFile(file);
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const comId = () => {
    let temp = [Commercial];
    const formData = new FormData();

    if (Commercial) {
      // SINGLE
      temp?.map((item, ind) => {
        formData.append(`po_ids[${ind}][id]`, item?.purchase_order_id);
        formData.append(`po_ids[${ind}][factory_id]`, item?.FactoryID);
        formData.append(`po_ids[${ind}][type]`, item?.type);
      });
    } else {
      // MULTIPLE

      upload_details?.map((item, ind) => {
        formData.append(`po_ids[${ind}][id]`, item?.purchase_order_id);
        formData.append(`po_ids[${ind}][factory_id]`, item?.FactoryID);
        formData.append(`po_ids[${ind}][type]`, item?.type);
      });
    }

    api.purchaseOrderUploadPageDetails(formData).then((res) => {
      if (res?.status === "success") {
        let temp = [];
        let temps = [];
        setDcDropDetails(res?.dcs);
        res?.dcs?.map((item, ind) => {
          temp.push({
            value: {
              id: item?.dc_id,
              factory_id: item?.factory_id,
              type: item?.type,
            },
            label: item?.dc_no,
          });
        });
        setoptions(temp);

        res?.po_details?.map((item, ind) => {
          // console.log(item);
          temps.push({
            value:
              item?.purchase_order?.MaterialPurchaseOrderID ||
              item?.purchase_order?.LeatherPurchaseOrderID,
            label:
              item?.purchase_order?.MaterialPurchaseOrderID ||
              item?.purchase_order?.LeatherPurchaseOrderID,
          });
        });
        // setoption(temps);
      }
    });
  };

  useEffect(() => {
    if (Commercial || upload_details) {
      comId();
    }
    getList();
  }, []);

  const invoiceCheckHandler = (val, target) => {
    let temp = [];
    if (target === "po") {
      temp = [...poCheck];
      if (temp?.includes(+val)) {
        temp?.splice(temp?.indexOf(+val), 1);
      } else {
        temp.push(+val);
      }
      setPoCheck(temp);
    } else {
      temp = [...dcCheck];
      if (temp?.includes(+val)) {
        temp?.splice(temp?.indexOf(+val), 1);
      } else {
        temp.push(+val);
      }
      setDcCheck(temp);
    }
  };

  const dropCloseHandler = (event) => {
    event.stopPropagation();
    dcDrop &&
      event.target?.parentElement?.parentElement?.id !== "dc-drop-cont" &&
      setDcDrop(!dcDrop);
    drop &&
      event.target?.parentElement?.parentElement?.id !== "po-drop-cont" &&
      setDrop(!drop);
  };

  const createInvoiceUploadHandler = () => {
    let temp = [Commercial];
    const formData = new FormData();

    formData.append("invoice_type", 2);
    formData.append("invoice_ref_no", inVRef);
    formData.append("invoice_date", moment(invDate).format("YYYY-MM-DD"));
    formData.append("invoice_amount", invAmount);
    formData.append("invoice_stage", invState);
    formData.append("currency_id", currency);

    if (file) {
      formData.append("copy_invoice", file);
    }

    if (supFile) {
      formData.append("support_document", supFile);
    }

    if (view) {
      formData.append("invoice_id", view?.id);
      api.invoiceUploadEdit(formData).then((res) => {
        if (res?.status == "success") {
          setError(res);
          setErrorBox(true);
          setTimeout(() => {
            navigate("/commercial-invoice/list");
          }, 800);
        } else {
          setError(res);
          setErrorBox(true);
        }
      });
    } else {
      if (Commercial) {
        // SINGLE
        optionChange?.map((item, ind) => {
          // if (poCheck?.includes(+item?.purchase_order_id)) {
          formData.append(`po_ids[${ind}][id]`, item?.value?.id);
          formData.append(
            `po_ids[${ind}][factory_id]`,
            item?.value?.factory_id
          );
          formData.append(`po_ids[${ind}][type]`, item?.value?.type);
          // }
        });
      } else {
        // MULTIPLE
        optionChange?.map((item, ind) => {
          // if (poCheck?.includes(+item?.purchase_order_id)) {
          formData.append(`po_ids[${ind}][id]`, item?.value?.id);
          formData.append(
            `po_ids[${ind}][factory_id]`,
            item?.value?.factory_id
          );
          formData.append(`po_ids[${ind}][type]`, item?.value?.type);
          // }
        });
      }
      optionChanges?.map((item, ind) => {
        // if (dcCheck?.includes(+item?.dc_id)) {
        formData.append(`dc_ids[${ind}][id]`, item?.value?.id);
        formData.append(
          `dc_ids[${ind}][factory_id]`,
          item?.value?.factory_id ?? ""
        );
        formData.append(`dc_ids[${ind}][type]`, item?.value?.type);
        // }
      });

      api.purchaseOrderUploadSave(formData).then((res) => {
        if (res?.status == "success") {
          setError(res);
          setErrorBox(true);
          // setTimeout(() => {
          //   navigate("/product/productList");
          // }, 800);
          if (type == "draft") {
            deleteDrafts(view?.id);
          } else {
            setTimeout(() => {
              navigate("/commercial-invoice/list");
            }, 800);
          }
        } else {
          setError(res);
          setErrorBox(true);
        }
      });
    }
  };

  // DELETE DRAFT
  const deleteDrafts = (id) => {
    let data = {
      draft_id: id,
    };
    api.deleteDraft(data).then((res) => {
      if (res?.status === "success") {
        setTimeout(() => {
          setTimeout(() => {
            navigate("/commercial-invoice/list");
          }, 800);
        }, 1000);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  // SAVE DRAFT
  const saveDraftHandler = () => {
    let temp = [Commercial];

    const formData = new FormData();

    formData.append("invoice_type", 2);
    formData.append("invoice_ref_no", inVRef);
    formData.append("invoice_date", moment(invDate).format("YYYY-MM-DD"));
    formData.append("invoice_amount", invAmount);
    formData.append("invoice_stage", invState);
    formData.append("currency_id", currency);
    formData.append("type", 1);
    formData.append("method_type", "upload");
    formData.append("method", "commercial");

    if (file) {
      formData.append("copy_invoice", file);
    }

    if (supFile) {
      formData.append("support_document", supFile);
    }

    option?.map((item, ind) => {
      formData.append(`po_ids[${ind}]`, JSON.stringify(item));
    });

    options?.map((item, ind) => {
      formData.append(`dc_ids[${ind}]`, JSON.stringify(item));
    });

    optionChange?.map((item, ind) => {
      formData.append(`po[${ind}]`, JSON.stringify(item));
    });

    optionChanges?.map((item, ind) => {
      formData.append(`dc[${ind}]`, JSON.stringify(item));
    });

    api.saveDraft(formData).then((res) => {
      if (res?.status == "success") {
        setError(res);
        setErrorBox(true);
        if (type == "draft") {
          deleteDrafts(view?.id);
        } else {
          setTimeout(() => {
            navigate("/commercial-invoice/list");
          }, 800);
        }
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const getView = () => {

    api.invoiceView(view?.id).then((res) => {
      if (res?.status == "success") {
        let temp = [];
        let temps = [];
        let resp = res?.invoice;
        console.log(res.invoice.support_document, 'res');
        console.log(resp.support_document, 'resp');
        setCopySupInvoiceFile(resp.support_document && resp.support_document.split('/').pop());
        setInvRef(resp?.invoice_ref_no);
        setInvDate(new Date(resp?.invoice_date));
        setInvAmount(resp?.invoice_amount);
        setInvState(resp?.invoice_stage);
        setSupFiles(resp.support_document);
        // console.log(supFiles, 'supFiles');
        setCurrency(resp?.currency_id);
        setFiles(resp?.copy_invoice);
        setSupInvoiceUrl(resp.document_url);
        console.log(resp, 'resp.document_url');
        console.log(supInvoiceUrl, "setInvoiceUrl");

        res?.po_details?.map((item, ind) => {
          temps.push({
            value:
              item?.purchase_order?.MaterialPurchaseOrderNo ||
              item?.purchase_order?.LeatherPurchaseOrderNo,
            label:
              item?.purchase_order?.MaterialPurchaseOrderNo ||
              item?.purchase_order?.LeatherPurchaseOrderNo,
          });
        });
        setoption(temps);
        res?.dc_details?.map((item, ind) => {
          temp.push({
            value: {
              id: item?.dc_detail?.DCNNo,
              factory_id: item?.factory_id,
              type: item?.type,
            },
            label: item?.dc_detail?.DCNNo,
          });
        });
        // console.log("temp", temp);
        setoptions(temp);

        let option_1 = [];
        temps.map((item, ind) => {
          option_1.push({ label: item?.label, value: item?.value });
        });
        setOptionChange(option_1);
        let option_2 = [];
        temp.map((item, ind) => {
          option_2.push({ label: item?.label, value: item?.value });
        });
        setOptionChanges(option_2);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const getDraftView = () => {
    let res = view?.draft;
    let temp = [];
    let temps = [];
    let resp = res?.invoice;
    setInvRef(res?.invoice_ref_no);
    setInvDate(new Date(res?.invoice_date));
    setInvAmount(res?.invoice_amount);
    setInvState(res?.invoice_stage);
    setSupFiles(res?.support_document);
    setFiles(res?.copy_invoice);
    setCurrency(+res?.currency_id);

    res?.po_ids?.map((item, ind) => {
      let persar = JSON.parse(item);
      temps.push({
        value: persar?.value,
        label: persar?.label,
      });
    });
    setoption(temps);

    res?.dc_ids?.map((item, ind) => {
      let persar = JSON.parse(item);
      temp.push({
        value: persar?.value,
        label: persar?.label,
      });
    });
    setoptions(temp);

    let select = [];
    let selectDc = [];

    res?.po?.map((item, ind) => {
      let persar = JSON.parse(item);
      select.push({
        value: persar?.value,
        label: persar?.label,
      });
    });
    setOptionChange(select);

    res?.dc?.map((item, ind) => {
      let persar = JSON.parse(item);
      selectDc.push({
        value: persar?.value,
        label: persar?.label,
      });
    });
    setOptionChanges(selectDc);
  };

  useEffect(() => {
    if (view && type !== "draft") {
      getView();
    } else if (view && type == "draft") {
      getDraftView();
    }
  }, []);

  const openInvoice = () => {
    window.open(copyInvoiceUrl, '_blank');
  };

  const openSupInvoice = () => {
    window.open(supInvoiceUrl, '_blank');
  };

  useEffect(() => {
    // console.log("copyInvoiceDoc updated:", copyInvoiceDoc);
  }, [copyInvoiceDoc]);

  useEffect(() => {
    // console.log(supInvoiceUrl, "setInvoiceUrl after update");
  }, [supInvoiceUrl, copyInvoiceType, supInvoiceType]);

  const hideInvoice = () => {
    setCopyInvoiceDoc(1);
  };

  const hideSupInvoice = () => {
    setCopySupInvoiceDoc(1);
  };

  return (
    <Col
    //  onClick={(e) => dropCloseHandler(e)}
    >
      <TopBar />
      <TopHeader type="create-commercial-invoice" />
      <Col
        lg={8}
        md={9}
        sm={10}
        xs={11}
        xxl={5}
        xl={5}
        style={{ minHeight: "80vh" }}
        className="create-inv-cont m-auto"
      >
        <div
          className="d-sm-flex gap-2 align-items-center fnt-fam-f2 my-4 py-2 primary1"
          onClick={() => navigate(-1)}
        >
          <HiArrowNarrowLeft />
          <h6 className="m-0">
            Commercial Invoice{" "}
            {type == "view" ? "View" : type == "edit" ? "Edit" : "Create"}
          </h6>
        </div>
        <div className="d-sm-flex gap-3 mb-2">
          <Col lg={6} md={6} sm={12} xs={12}>
            <label className="my-1">
              Invoice Reference No<span className="red-text">*</span>
            </label>
            <p className="border-btm py-1">
              <input
                className="border-0 w-100"
                value={inVRef}
                type="number"
                onChange={(e) => setInvRef(e.target.value)}
                disabled={type == "view" ? true : false}
              />
            </p>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <label className="my-1">
              Invoice Date<span className="red-text">*</span>
            </label>
            <p className="border-btm py-1">
              <DatePicker
                setStartDate={setInvDate}
                startDate={invDate}
                disabled={type == "view" ? true : false}
                placeholder={"Select Date"}
              />
            </p>
          </Col>
        </div>
        <div className="d-sm-flex gap-3 mb-2">
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className="d-flex w-100 gap-3">
              <div>
                <label className="my-1">
                  Invoice Amount<span className="red-text">*</span>
                </label>
                <p className="border-btm py-1">
                  <input
                    className="border-0 w-100"
                    value={invAmount}
                    type="number"
                    onChange={(e) => setInvAmount(e.target.value)}
                    disabled={type == "view" ? true : false}
                  />
                </p>
              </div>
              <div>
                <label className="my-1">
                  Currency<span className="red-text">*</span>
                </label>
                <p className="border-btm py-1">
                  <select
                    onChange={(e) => setCurrency(e.target.value)}
                    disabled={type == "view" ? true : false}
                    className="border-0 w-100 pointer"
                    value={currency}
                  >
                    <option hidden>Select Currency</option>
                    {input?.currencies?.map((item, ind) => (
                      <option value={item?.currency_id} key={ind}>
                        {item?.currency_code}
                      </option>
                    ))}
                  </select>
                </p>
              </div>{" "}
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <label className="my-1">
              Invoice Stage<span className="red-text">*</span>
            </label>
            <p className="border-btm py-1">
              <select
                onChange={(e) => setInvState(e.target.value)}
                disabled={type == "view" ? true : false}
                className="border-0 w-100 pointer"
                value={invState}
              >
                <option>Select Stage</option>
                {/* <option value={1}>Advance</option>
                <option value={2}>Partial</option>
                <option value={3}>Final</option> */}
                {/* <option value={1}>Advance Payment</option>
                <option value={2}>TT Payment</option>
                <option value={3}>50% Advance</option> */}
                {input?.stages?.map((item, ind) => {
                  return (
                    <option value={item?.ReferenceDetailsID} key={ind}>
                      {item?.ReferenceDetailsName}
                    </option>
                  );
                })}
              </select>
            </p>
          </Col>
        </div>
        <div className="d-sm-flex gap-3 mb-2 ">
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="checkbox-drop-parent-box"
          >
            <label className="my-1">
              PO Ref No<span className="red-text">*</span>
            </label>
            <Select
              closeMenuOnSelect={false}
              name="colors"
              options={option}
              value={optionChange}
              isMulti
              onChange={(e) => setOptionChange(e)}
              className="basic-multi-select"
              classNamePrefix="select"
              isDisabled={type == "view" ? true : false}
            // isDisabled={selectBoxBtn ? true : false}
            />
            {/* <p
              className="pointer border-btm d-flex"
              onClick={() => setDrop(!drop)}
            >
              Select Po Ref No
              <span className="ms-auto">
                <AiOutlineDown />
              </span>
            </p>
            {drop && (
              <ul className="checkbox-drop-box" id="po-drop-cont">
                {Commercial && (
                  <li
                    key={Commercial?.purchase_order_id}
                    className="d-flex gap-2 my-1"
                  >
                    <input
                      value={Commercial?.purchase_order_id}
                      type="checkbox"
                      onClick={(e) => invoiceCheckHandler(e.target.value, "po")}
                    />
                    <p className="py-1">{Commercial?.purchase_order_no}</p>
                  </li>
                )}

                {upload_details?.map((item) => (
                  <li
                    key={item?.purchase_order_id}
                    className="d-flex gap-2 my-1"
                  >
                    <input
                      value={item?.purchase_order_id}
                      type="checkbox"
                      onClick={(e) => invoiceCheckHandler(e.target.value, "po")}
                    />
                    <p className="py-1">{item?.purchase_order_no}</p>
                  </li>
                ))}
              </ul>
            )} */}
          </Col>
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="checkbox-drop-parent-box"
          >
            <label className="my-1">DC No</label>
            <Select
              closeMenuOnSelect={false}
              isMulti
              name="colors"
              options={poCheck?.length > 0 ? options : ""}
              value={optionChanges}
              onChange={(e) => setOptionChanges(e)}
              className="basic-multi-select"
              classNamePrefix="select"
              isDisabled={type == "view" ? true : false}
            // isDisabled={selectBoxBtn ? true : false}
            />
            {/* <p
              className="pointer border-btm d-flex"
              onClick={() => setDcDrop(!dcDrop)}
            >
              Select DC No
              <span className="ms-auto">
                <AiOutlineDown />
              </span>
            </p>
            {dcDrop && (
              <ul className="checkbox-drop-box" id="dc-drop-cont">
                {dcDropDetails?.map((item) => (
                  <li key={item?.dc_id} className="d-flex gap-2 my-1">
                    <input
                      value={item?.dc_id}
                      type="checkbox"
                      onClick={(e) => invoiceCheckHandler(e.target.value, "dc")}
                    />
                    <p className="py-1">{item?.dc_no}</p>
                  </li>
                ))}
              </ul>
            )} */}
          </Col>
          {/* <Col lg={6} md={6} sm={12} xs={12}>
            <label className="my-1">
              PO Date<span className="red-text">*</span>
            </label>
            <p className="border-btm py-1">20/02/2023</p>
          </Col> */}
        </div>
        <div className="d-sm-flex gap-3 mb-2">
          {/* <Col lg={6} md={6} sm={12} xs={12}>
            <label className="my-1">DC Date</label>
            <p className="border-btm py-1">20/02/2023</p>
          </Col> */}
        </div>
        <div className="d-sm-flex gap-3 mb-2 ">
          {/* <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="checkbox-drop-parent-box">
            <label className="my-1">GRN No</label>
            <p
              className="pointer border-btm d-flex"
              onClick={() => setGrnDrop(!grnDrop)}>
              Select GRN No
              <span className="ms-auto">
                <AiOutlineDown />
              </span>
            </p>
            {grnDrop && (
              <ul className="checkbox-drop-box">
                <li className="d-flex gap-2">
                  <input type="checkbox" />
                  <p className="py-1">GR89561897</p>
                </li>
                <li className="d-flex gap-2">
                  <input type="checkbox" />
                  <p className="py-1">GR823423</p>
                </li>
                <li className="d-flex gap-2">
                  <input type="checkbox" />
                  <p className="py-1">GR895434537</p>
                </li>
                <li className="d-flex gap-2">
                  <input type="checkbox" />
                  <p className="py-1">GR98561897</p>
                </li>
                <li className="d-flex gap-2">
                  <input type="checkbox" />
                  <p className="py-1">GR123461897</p>
                </li>
              </ul>
            )}
          </Col> */}
          {/* <Col lg={6} md={6} sm={12} xs={12}>
            <label className="my-1">GRN Date</label>
            <p className="border-btm py-1">20/02/2023</p>
          </Col> */}
        </div>

        <Col xs={12} className="pe-3 my-2 file_upload">
          <p className="primary1">
            <label>
              Upload Copy Invoice <span className="red-text">*</span>
            </label>
          </p>
          <input
            className="custom-file-tech-bank-new"
            type="file"
            onChange={(e) => {
              setFile(e.target.files[0]);
              setFiles(e.target.files[0]);
              var invoiceDoc = e.target.files[0];
              setCopyInvoiceUrl(URL.createObjectURL(invoiceDoc));
              setCopyInvoiceFile(e.target.files[0].name);
              setCopyInvoiceType(e.target.files[0].type);
              setCopyInvoiceDoc(0);
            }}
          // disabled={type == "view" ? true : false}
          // value={file}
          />
          <p className="blue-fnt opac-half">
            Note:Format jpg,jpeg,pdf upload 5.0MB each file ,Allowed to upload
            maximum of 1 upload
          </p>
        </Col>
        {files && copyInvoiceDoc == 0 && (
          <p className="d-flex justify-content-between px-2 py-2 my-2 align-items-center file-upload-cont-bg wid-97-perc">
            <span className="d-flex gap-2" onClick={openInvoice} style={{ cursor: 'pointer' }}>
              {copyInvoiceType == "application/pdf" ?
                <AiOutlineFilePdf className="text-success fs-4" /> :
                <AiOutlineFileJpg className="text-success fs-4" />
              }
              {copyInvoiceFile}
            </span>
            <span
              className="create-inv-delete-icon-cont"
              onClick={() =>
                type == "view" ? null : setFile(null) && setFiles(null)
              }
            >
              <span onClick={hideInvoice} style={{ cursor: 'pointer' }}>
                <RiDeleteBin6Line />
              </span>
            </span>
          </p>
        )}

        <Col xs={12} className="pe-3 my-2 file_upload">
          <p className="primary1">
            <label>Supported Document</label>
          </p>
          <input
            className="custom-file-tech-bank-new"
            type="file"
            onChange={(e) => {
              setSupFile(e.target.files[0]);
              setSupFiles(e.target.files[0]);
              var supportedDoc = e.target.files[0];
              setSupInvoiceUrl(URL.createObjectURL(supportedDoc));
              setCopySupInvoiceFile(e.target.files[0].name);
              setSupInvoiceType(e.target.files[0].type);
              setCopySupInvoiceDoc(0);
            }}
          // disabled={type == "view" ? true : false}
          // value={supFile}
          />
          <p className="blue-fnt opac-half">
            Note:Format jpg,jpeg,pdf upload 5.0MB each file ,Allowed to upload
            maximum of 1 upload
          </p>
        </Col>
        {supFiles && copySupInvoiceDoc == 0 && (
          <p className="d-flex justify-content-between px-2 my-2 py-2 align-items-center file-upload-cont-bg wid-97-perc">
            <span className="d-flex gap-2" onClick={openSupInvoice} style={{ cursor: 'pointer' }}>
              {supInvoiceType == "application/pdf" ?
                <AiOutlineFilePdf className="text-success fs-4" /> :
                <AiOutlineFileJpg className="text-success fs-4" />
              }
              {copySupInvoiceFile}
            </span>
            <span
              className="create-inv-delete-icon-cont"
              onClick={() =>
                type == "view" ? null : setSupFile(null) && setSupFiles(null)
              }>
              <span onClick={hideSupInvoice} style={{ cursor: 'pointer' }}>
                <RiDeleteBin6Line />
              </span>
            </span>
          </p>
        )}

        {type == "view" ? null : (
          <div className="d-flex mt-2 mb-4 justify-content-end gap-3 my-2 wid-105-perc">
            <button className="clear-btn  px-4 py-1 fnt-fam-f2 border-0 rounded-1 opac_half">
              Clear
            </button>
            <button
              className="clear-btn  px-4 py-1 fnt-fam-f2 border-0 rounded-1 opac_half"
              onClick={() => saveDraftHandler()}
            >
              Save as Draft
            </button>
            <button
              className="bg-primary1 bg-primary1 fnt-fam-f2 px-4 py-1 text-white rounded-1 border-0"
              onClick={createInvoiceUploadHandler}
            >
              Submit
            </button>
          </div>
        )}
      </Col>
      <div className="rg-footer">
        <LogInFooter />
      </div>
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
    </Col>
  );
}

export default CreateCommercialInvoice;
