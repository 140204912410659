// @ts-nocheck
import POFilter from "../../component/PurchaseOrder/POFilter";
import GeneralPagination from "../../component/GeneralPagination";
import POCard from "../../component/PurchaseOrder/POCard";
import Filter from "../../assets/Icons/Normal Icons/Filter.svg";
import Search from "../../assets/Icons/Normal Icons/Search.svg";
import TopHeader from "../../navbar/TopHeader";
import TopBar from "../../navbar/TopBar";
import { Col, Modal, Image, Button } from "react-bootstrap";
import LogInFooter from "../../logincomponent/LogInFooter";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GRNTableContent from "../../routes/PurchaseOrder/GRN/GRNTableContent";
import POList from "../../component/PurchaseOrder/POList";
import Select from "react-select";
import { api } from "../../constants/Services";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Radio_icon from "../../assets/Icons/Normal Icons/RadioButton.svg";
import { FavLogo, Check } from "../../assets/img";
import { FaEllipsisV } from "react-icons/fa";
import ErrorModel from "../../constants/Services/Api/errorModel";
// import DatePicker from "../../layout/datePicker";
import Datepicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Calendar, Remarks } from "../../assets/img";
import { faCalendarPlus } from "@fortawesome/free-regular-svg-icons";
import useUser from "../../constants/Storage/userData";

function PriceEnquiryList() {
    const navigate = useNavigate();
    const { user } = useUser();
    const [showFilterContent, setShowFilterContent] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [count, setCount] = useState(0);
    const permission = localStorage.getItem("permissions");
    const [dropShow, setDropShow] = useState(false);
    const [showInd, setShowInd] = useState();
    const [openCard, setOpenCard] = useState(false);
    const [openremarkpopup, setOpenRemarkPopup] = useState(false);
    const [activeBtn, setActiveBtn] = useState(1);
    const [submitAck, setSubmitAck] = useState(false);
    const [showInvModal, setShowInvModal] = useState(false);
    const [showInvModals, setShowInvModals] = useState(false);
    const [showComInvModal, setShowComInvModal] = useState(false);
    const [showComInvModals, setShowComInvModals] = useState(false);
    const [priceList, setPriceList] = useState([]);
    const [priceMatrixList, setPriceMatrixList] = useState([]);
    const [error, setError] = useState();
    const [errorBox, setErrorBox] = useState(false);
    const [btn, setbtn] = useState(false);
    const [checkboxs, setcheckboxs] = useState([]);
    const [pages, setPages] = useState(1);
    const [pageCount, setPageCount] = useState(10);
    const [search, setSearch] = useState();
    const [activePages, setActivePages] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);
    const [awardedByFilter, setAwardedByFilter] = useState([]);
    const [remarks, setRemarks] = useState([]); // State to hold remarks
    const [remarkContent, setRemarkContent] = useState('');
    const [currentIndex, setCurrentIndex] = useState(null); // Index of the remark being edited
    const [startDate, setStartDate] = useState(null);
    const [inputValues, setInputValues] = useState({});
    const [priceupto, setpriceupto] = useState([]);
    const [suppliercategory, setSupplierCategory] = useState();


    // NEW FILTER

    const [pur_type, setPur_type] = useState();
    const [pur_status, setPur_status] = useState();
    const [pur_date, setPur_date] = useState();
    const [Proforma, setProforma] = useState([]);
    const [commercial, setCommercial] = useState([]);
    const [isDisabled, setIsDisabled] = useState([]);
    const [success, setSuccess] = useState(false);


    const remarkpopup = (value, index) => {
        console.log(value, 'value');
        setRemarkContent(value);
        setCurrentIndex(index);
        // setShowInd();
        setOpenRemarkPopup(true);
    };

    const RemarkCloseModal = () => setOpenRemarkPopup(false);

    const ackSubmitHandler = () => {
        if (currentIndex !== null) {
            const updatedRemarks = [...remarks];
            updatedRemarks[currentIndex] = remarkContent; // Update the remark at the current index
            setRemarks(updatedRemarks);
        } else {
            // Optionally handle adding new remarks if required
            setRemarks([...remarks, remarkContent]);
        }

        setOpenRemarkPopup(false); // Close the modal
        setRemarkContent(''); // Clear the remark content
        setCurrentIndex(null);
        // setSubmitAck(true);
    };


    const getList = () => {
        var params = `?suppliercategory=${user.supplier.SupplierCategory}&supplierId=${user.supplier.SupplierID}`;
        // var params = `?search=''`;
        if (search) {
            params += `&search=${search}`;
        }
        api.getListData(params).then((res) => {
            // let status = [];
            // let awardedBy = [];
            // console.log(res?.status, 'res?.status');
            if (res?.status == "success") {
                //     // setActivePages(res);
                // console.log(res?.price_matrix, 'res?.price_matrixsss');
                setPriceList(res?.material_list);
                setPriceMatrixList(res?.price_matrix);
                //     setPageCount(res?.pagination?.total / res?.pagination?.per_page);
                //     setActivePages(res);
                //     // setList(res?.purchase_orders);
                //     setPageCount(res?.pagination?.total / res?.pagination?.per_page);

                //     res?.purchase_orders?.map((item, ind) => {
                //         status.push(item?.Sup_Approval);
                //         awardedBy.push({
                //             FactoryID: item?.FactoryID,
                //             FactoryName: item?.FactoryName,
                //         });
                //     });
                //     // status
                //     const idstatus = status.map((Sup_Approval) => Sup_Approval);
                //     const filteredstatus = status.filter(
                //         (Sup_Approval, index) => !idstatus.includes(Sup_Approval, index + 1)
                //     );
                //     setStatusFilter(filteredstatus);
                //     // award by
                //     const idsaward = awardedBy.map(({ FactoryID }) => FactoryID);
                //     const filteredaward = awardedBy.filter(
                //         ({ FactoryID }, index) => !idsaward.includes(FactoryID, index + 1)
                //     );
                //     setAwardedByFilter(filteredaward);
                //     setbtn(false);
                //     setShowFilterContent(false);
            } else {
                console.log('ljhgfdxcgvhbn');
                //     setError(res);
                //     setbtn(false);
                //     setErrorBox(true);
                //     setShowFilterContent(false);
            }
        });
    };

    const handlePageChange = (selectedObject) => {
        setPages(selectedObject.selected + 1);
        setCount(selectedObject.selected);
    };

    const [unique, setUnique] = useState([]);

    const changestatus = (event, ind) => {
        let temps = [...checkboxs];
        const value = event;

        const index = temps.indexOf(event);

        if (temps.includes(value)) {
            temps.splice(index, 1);
            setcheckboxs(temps);
        } else {
            temps.push(value);
            setcheckboxs(temps);
        }

        // setUnique(temps[0]?.sub_type);
        setUnique("L");

        console.log(temps);
    };

    const errorModalClose = () => {
        setErrorBox(!errorBox);
    };


    const handleDateChange = (date, ind) => {
        const newDates = [...priceupto];
        newDates[ind] = date; // Update the specific index with the new date
        setpriceupto(newDates);
    };


    const clearData = () => {
        setRemarkContent("");
        // setPur_status("");
        // setPur_type("");
        // getList();
        setOpenRemarkPopup(false);
    };


    useEffect(() => {
        setSupplierCategory(user.supplier.SupplierCategory);
        getList(); // If getList modifies priceList, this could cause re-renders
    }, [search]);

    useEffect(() => {
        if (priceList && priceList.length > 0) { // Ensure priceList has valid content
            const disabledStates = priceList.map((item) =>
                moment(new Date()).isAfter(moment(item.EndDate))
            );
            setIsDisabled(disabledStates); // Set the disabled states
        }
    }, [priceList]);

    const POFilterSubmitHandler = () => setShowFilterContent(false);
    const onClickFilterHandler = () => setShowFilterContent(true);

    const handleInputChange = (ind, field, value) => {
        setInputValues(prev => ({
            ...prev,
            [ind]: {
                ...prev[ind],
                [field]: value,
            },
        }));
    };

    const submitHandle = () => {
        let formdata = new FormData();
        if (Object.keys(inputValues).length > 0) {
            const selectedValues = Object.entries(inputValues).map(([ind, values]) => ({
                remarks: remarks[ind] && remarks[ind] != '' ? remarks[ind] : priceList[ind]?.Remarks,
                startDate: priceList[ind]?.StartDate || null,
                endDate: priceList[ind]?.EndDate || null,
                sizeId: priceList[ind]?.SizeID || null, // Assuming SizeID is a property of the item
                buyerId: priceList[ind]?.BuyerID || null, // Assuming BuyerID is a property of the item
                colorId: priceList[ind]?.ColorID || null,
                materialskuId: priceList[ind]?.MaterialSKUID || null,
                leatherskuId: priceList[ind]?.LeatherSKUID || null,
                bmoq: values.bmoq || null, // Get bmoq from inputValues
                bmoqprice: values.bmoqprice || null, // Get bmoqprice from inputValues
                ltid: values.ltid || null, // Get ltid from inputValues
                smoq: values.smoq || null, // Get smoq from inputValues
                priceupto: moment(priceupto[ind]).format('DD-MM-YYYY'),
                smoqprice: values.smoqprice || null, // Get smoqprice from inputValues
                materialenquiryid: priceList[ind]?.MaterialEnquiryID || null,
                leatherenquiryid: priceList[ind]?.LeatherEnquiryID || null,
                supplierId: user.supplier.SupplierID,
            }));
            // console.log(selectedValues, 'selectedValues');
            // return false;
            formdata.append("selected_values", JSON.stringify(selectedValues));
            api.pricenquiryadd(formdata).then((res) => {
                if (res?.status == "success") {
                    setSuccess(true);
                    // setError(res);
                    // setErrorBox(true);
                    // setTimeout(() => {
                    //     navigate("/product/productList");
                    // }, 800);
                } else {
                    // setError(res);
                    // setErrorBox(true);
                }
            });
        }
    };

    return (
        <>
            <TopBar />
            {errorBox && (
                <ErrorModel
                    error={error}
                    errorBox={errorBox}
                    errorModalClose={errorModalClose}
                />
            )}
            <TopHeader type="price-enquiry" />
            <div
                style={{ minHeight: "70vh" }}
                className="enquiry-list-cont py-2 position-relative"
            >
                <div className="d-flex flex-wrap">
                    {/* <POFilter setShowFilterContent={setShowFilterContent} /> */}
                    <div className="d-sm-flex justify-content-end mb-2 align-items-end w-100">
                        <Button className="btn sub-btn custom-sub-enq-btn" onClick={submitHandle}>Submit Enquiry</Button>

                        <div className="d-flex mt-2 ms-2">
                            <div className="search-box d-flex gap-2 align-items-center px-2 rounded-1">
                                <input
                                    placeholder="Search Title"
                                    className="w-90 h-90"
                                    type={"text"}
                                    onChange={(e) => setSearch(e.target.value)}
                                    value={search}
                                />
                                <img className="search-icon" src={Search} alt="search" />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{ minHeight: "70vh" }}
                    className="table-scroll-horizontal over-flow-x-scroll my-2">
                    <div className="min-ht-80">
                        <table className="mb-1 py-1 table">
                            {(suppliercategory == 'M ' || suppliercategory == 'M') ?
                                <thead>
                                    <tr className="text-center price-enquiry-cls" style={{ background: "#ededed" }}>
                                        <th className="text-center wid-200px" > S.No
                                        </th>
                                        <th className="text-nowrap wid-200px">Material Type</th>
                                        <th className="text-nowrap wid-200px">Material Name</th>
                                        <th className="text-nowrap fs-14px wid-200px">Material SKU</th>
                                        <th className="text-nowrap fs-14px wid-200px">Size</th>
                                        <th className="text-nowrap fs-14px wid-200px">Buyer Name</th>
                                        <th className="fs-14px text-nowrap wid-200px">Lead Times in Days</th>
                                        <th className="fs-14px text-nowrap wid-200px">Quotation No</th>
                                        <th className="fs-14px text-nowrap wid-200px">Quotation Date</th>
                                        <th className="fs-14px text-nowrap text-center wid-200px">Due Date</th>
                                        <th className="fs-14px text-nowrap text-center wid-200px">UOM</th>
                                        <th className="fs-14px text-nowrap text-center wid-200px">Purchase Quantity</th>
                                        <th className="fs-14px text-nowrap text-center wid-200px"></th>
                                        <th className="fs-14px text-nowrap text-center wid-200px"></th>
                                        <th className="fs-14px text-nowrap text-center wid-200px"></th>
                                        {/* <th className="fs-14px text-nowrap text-center wid-200px"></th> */}
                                        {/* <th className="fs-14px text-nowrap text-center wid-"> */}
                                        <th className="fs-14px text-nowrap text-center wid-200px">Price Valid Upto</th>
                                        <th className="fs-14px text-nowrap text-center wid-200px">Remarks</th>
                                    </tr>
                                    <tr className="text-center price-enquiry-cls">
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="fs-10px text-nowrap" style={{ padding: ".5rem 12px" }}>Sample MOQ</th>
                                        <th className="fs-10px text-nowrap" style={{ padding: ".5rem 12px" }}>Price (INR)</th>
                                        <th className="fs-10px text-nowrap" style={{ padding: ".5rem 12px" }}>Bulk MOQ</th>
                                        <th className="fs-10px text-nowrap" style={{ padding: ".5rem 12px" }}>Price (INR)</th>
                                    </tr>
                                </thead>
                                :
                                <thead>
                                    <tr className="text-center price-enquiry-cls" style={{ background: "#ededed" }}>
                                        <th className="text-center wid-200px" > S.No
                                        </th>
                                        <th className="text-nowrap wid-200px">Species</th>
                                        <th className="text-nowrap wid-200px">Color</th>
                                        <th className="text-nowrap fs-14px wid-200px">Leather SKU</th>
                                        <th className="text-nowrap fs-14px wid-200px">SKU Name</th>
                                        <th className="text-nowrap fs-14px wid-200px">Buyer Name</th>
                                        <th className="fs-14px text-nowrap wid-200px">Lead Times in Days</th>
                                        <th className="fs-14px text-nowrap wid-200px">Quotation No</th>
                                        <th className="fs-14px text-nowrap wid-200px">Quotation Date</th>
                                        <th className="fs-14px text-nowrap text-center wid-200px">Due Date</th>
                                        <th className="fs-14px text-nowrap text-center wid-200px">UOM</th>
                                        <th className="fs-14px text-nowrap text-center wid-200px">Purchase Quantity</th>
                                        <th className="fs-14px text-nowrap text-center wid-200px"></th>
                                        <th className="fs-14px text-nowrap text-center wid-200px"></th>
                                        <th className="fs-14px text-nowrap text-center wid-200px"></th>
                                        {/* <th className="fs-14px text-nowrap text-center wid-200px"></th> */}
                                        {/* <th className="fs-14px text-nowrap text-center wid-"> */}
                                        <th className="fs-14px text-nowrap text-center wid-200px">Price Valid Upto</th>
                                        <th className="fs-14px text-nowrap text-center wid-200px">Remarks</th>
                                    </tr>
                                    <tr className="text-center price-enquiry-cls">
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="wid-200px"></th>
                                        <th className="fs-10px text-nowrap" style={{ padding: ".5rem 12px" }}>Sample MOQ</th>
                                        <th className="fs-10px text-nowrap" style={{ padding: ".5rem 12px" }}>Price (INR)</th>
                                        <th className="fs-10px text-nowrap" style={{ padding: ".5rem 12px" }}>Bulk MOQ</th>
                                        <th className="fs-10px text-nowrap" style={{ padding: ".5rem 12px" }}>Price (INR)</th>
                                    </tr>
                                </thead>
                            }
                            <tbody className="fs-6 opac-80 text-center">
                                {priceList?.map((item, ind) => {
                                    // console.log(item.LeatherEnquiryID, 'remarks[ind]');
                                    console.log(suppliercategory, 'suppliercategory');
                                    // console.log(priceMatrixList[item.LeatherEnquiryID], 'priceMatrixList[item.LeatherEnquiryID] != undefined')
                                    return (
                                        <tr key={ind}>
                                            <td>
                                                <div className="d-flex gap-1">
                                                    {(suppliercategory == 'M' || suppliercategory == 'M ') ?
                                                        <div>
                                                            {priceMatrixList[item.MaterialEnquiryID] != undefined ?
                                                                <div className="custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="me-1 custom-data-drpdn"
                                                                        disabled={true}
                                                                        checked={true}
                                                                        onClick={() => changestatus(item, ind)}
                                                                    />
                                                                    <span class="checkmark"></span>
                                                                </div>
                                                                :
                                                                <input
                                                                    type="checkbox"
                                                                    className="me-1"
                                                                    disabled={isDisabled[ind]}
                                                                    onClick={() => changestatus(item, ind)}
                                                                />
                                                            }
                                                        </div> :
                                                        <div>
                                                            {priceMatrixList[item.LeatherEnquiryID] != undefined ?
                                                                <div className="custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="me-1 custom-data-drpdn"
                                                                        disabled={true}
                                                                        checked={true}
                                                                        onClick={() => changestatus(item, ind)}
                                                                    />
                                                                    <span class="checkmark"></span>
                                                                </div>
                                                                :
                                                                <input
                                                                    type="checkbox"
                                                                    className="me-1"
                                                                    disabled={isDisabled[ind]}
                                                                    onClick={() => changestatus(item, ind)}
                                                                />
                                                            }
                                                        </div>
                                                    }
                                                    <div>
                                                        {ind + 1 == 10 ? count + 1 : count}
                                                        {ind + 1 == 10 ? 0 : ind + 1}
                                                    </div>
                                                </div>
                                            </td>
                                            {(suppliercategory == 'M' || suppliercategory == 'M ') ?
                                                <>
                                                    <td>
                                                        {item.MaterialTypeName ? item.MaterialTypeName : '-'}
                                                    </td>
                                                    <td>
                                                        {item.MaterialSKUName ? item.MaterialSKUName : '-'}
                                                    </td>
                                                    <td>
                                                        {item.MaterialSKUCode ? item.MaterialSKUCode : '-'}
                                                    </td>
                                                    <td>
                                                        {item.SizeName ? item.SizeName : '-'}
                                                    </td>
                                                </> :
                                                <>
                                                    <td>
                                                        {item.SpeciesName ? item.SpeciesName : '-'}
                                                    </td>
                                                    <td>
                                                        {item.ColorName ? item.ColorName : '-'}
                                                    </td>
                                                    <td>
                                                        {item.LeatherSKUCode ? item.LeatherSKUCode : '-'}
                                                    </td>
                                                    <td>
                                                        {item.LeatherSKUName ? item.LeatherSKUName : '-'}
                                                    </td>
                                                </>
                                            }
                                            <td>{item.BuyerName ? item.BuyerName : '-'}</td>


                                            {(suppliercategory == 'M' || suppliercategory == 'M ') ?
                                                <>

                                                    <td>
                                                        {priceMatrixList[item.MaterialEnquiryID] != undefined ?
                                                            <input
                                                                className="w-90 h-90 custom-input-dsg"
                                                                type={"number"}
                                                                value={priceMatrixList[item.MaterialEnquiryID] != undefined ? priceMatrixList[item.MaterialEnquiryID][140].LeadTime : ''}
                                                                onChange={(e) => handleInputChange(ind, 'ltid', e.target.value)}
                                                            /> :
                                                            <input
                                                                className="w-90 h-90 custom-input-dsg"
                                                                type={"number"}
                                                                onChange={(e) => handleInputChange(ind, 'ltid', e.target.value)}
                                                            />
                                                        }

                                                    </td>
                                                    <td>
                                                        {item.MaterialEnquiryNo ? item.MaterialEnquiryNo : "-"}
                                                    </td>
                                                    <td>{item.StartDate ? moment(item.StartDate).format('DD-MM-YYYY') : '-'}</td>
                                                    <td>{item.EndDate ? moment(item.EndDate).format('DD-MM-YYYY') : '-'}</td>
                                                    <td className="text-center">{item.UOM ? item.UOM : '-'}</td>
                                                    <td className="text-center">
                                                        {priceMatrixList[item.MaterialEnquiryID] != undefined ?
                                                            <input
                                                                className="h-90 custom-input-smoq"
                                                                type={"number"}
                                                                value={priceMatrixList[item.MaterialEnquiryID] != undefined ? priceMatrixList[item.MaterialEnquiryID][140].MOQ : ''}
                                                                onChange={(e) => handleInputChange(ind, 'smoq', e.target.value)}
                                                            />
                                                            :
                                                            <input
                                                                className="h-90 custom-input-smoq"
                                                                type={"number"}
                                                                onChange={(e) => handleInputChange(ind, 'smoq', e.target.value)}
                                                            />

                                                        }
                                                    </td>
                                                    <td>
                                                        {priceMatrixList[item.MaterialEnquiryID] != undefined ?
                                                            <input
                                                                className="w-90 h-90 custom-input-dsg"
                                                                type={"number"}
                                                                value={priceMatrixList[item.MaterialEnquiryID] != undefined ? (parseInt(priceMatrixList[item.MaterialEnquiryID][140].Price)).toFixed(3) : ''}
                                                                onChange={(e) => handleInputChange(ind, 'smoqprice', e.target.value)}
                                                            /> :
                                                            <input
                                                                className="w-90 h-90 custom-input-dsg"
                                                                type={"number"}
                                                                onChange={(e) => handleInputChange(ind, 'smoqprice', e.target.value)}
                                                            />

                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {priceMatrixList[item.MaterialEnquiryID] != undefined ?
                                                            <input
                                                                className="h-90 custom-input-smoq"
                                                                type={"number"}
                                                                value={priceMatrixList[item.MaterialEnquiryID] != undefined ? (priceMatrixList[item.MaterialEnquiryID][141].MOQ) : ''}
                                                                onChange={(e) => handleInputChange(ind, 'bmoq', e.target.value)}
                                                            /> :
                                                            <input
                                                                className="h-90 custom-input-smoq"
                                                                type={"number"}
                                                                onChange={(e) => handleInputChange(ind, 'bmoq', e.target.value)}
                                                            />
                                                        }
                                                    </td>
                                                    <td>
                                                        {priceMatrixList[item.MaterialEnquiryID] != undefined ?
                                                            <input
                                                                className="w-90 h-90 custom-input-dsg"
                                                                type={"text"}
                                                                value={priceMatrixList[item.MaterialEnquiryID] != undefined ? (parseInt(priceMatrixList[item.MaterialEnquiryID][141].Price)).toFixed(3) : ''}
                                                                onChange={(e) => handleInputChange(ind, 'bmoqprice', e.target.value)}
                                                            /> :
                                                            <input
                                                                className="w-90 h-90 custom-input-dsg"
                                                                type={"text"}
                                                                onChange={(e) => handleInputChange(ind, 'bmoqprice', e.target.value)}
                                                            />
                                                        }
                                                    </td>
                                                    <td>
                                                        <div className="date-picker-container">
                                                            <Datepicker
                                                                selected={priceupto[ind] || null}
                                                                onChange={(date) => handleDateChange(date, ind)}
                                                                dateFormat="dd-MMM-yyyy"
                                                            />
                                                            <span className="calendar-icon"><Image src={Calendar} /></span>
                                                        </div>
                                                    </td>
                                                </>
                                                :
                                                <>

                                                    <td>
                                                        {priceMatrixList[item.LeatherEnquiryID] != undefined ?
                                                            <input
                                                                className="w-90 h-90 custom-input-dsg"
                                                                type={"number"}
                                                                value={priceMatrixList[item.LeatherEnquiryID] != undefined ? priceMatrixList[item.LeatherEnquiryID][140].LeadTime : ''}
                                                                onChange={(e) => handleInputChange(ind, 'ltid', e.target.value)}
                                                            /> :
                                                            <input
                                                                className="w-90 h-90 custom-input-dsg"
                                                                type={"number"}
                                                                onChange={(e) => handleInputChange(ind, 'ltid', e.target.value)}
                                                            />
                                                        }

                                                    </td>
                                                    <td>
                                                        {item.LeatherEnquiryNo ? item.LeatherEnquiryNo : "-"}
                                                    </td>
                                                    <td>{item.StartDate ? moment(item.StartDate).format('DD-MM-YYYY') : '-'}</td>
                                                    <td>{item.EndDate ? moment(item.EndDate).format('DD-MM-YYYY') : '-'}</td>
                                                    <td className="text-center">{item.UOM ? item.UOM : '-'}</td>
                                                    <td className="text-center">
                                                        {priceMatrixList[item.LeatherEnquiryID] != undefined ?
                                                            <input
                                                                className="h-90 custom-input-smoq"
                                                                type={"number"}
                                                                value={priceMatrixList[item.LeatherEnquiryID] != undefined ? priceMatrixList[item.LeatherEnquiryID][140].MOQ : ''}
                                                                onChange={(e) => handleInputChange(ind, 'smoq', e.target.value)}
                                                            />
                                                            :
                                                            <input
                                                                className="h-90 custom-input-smoq"
                                                                type={"number"}
                                                                onChange={(e) => handleInputChange(ind, 'smoq', e.target.value)}
                                                            />

                                                        }
                                                    </td>
                                                    <td>
                                                        {priceMatrixList[item.LeatherEnquiryID] != undefined ?
                                                            <input
                                                                className="w-90 h-90 custom-input-dsg"
                                                                type={"number"}
                                                                value={priceMatrixList[item.LeatherEnquiryID] != undefined ? (parseInt(priceMatrixList[item.LeatherEnquiryID][140].Price)).toFixed(3) : ''}
                                                                onChange={(e) => handleInputChange(ind, 'smoqprice', e.target.value)}
                                                            /> :
                                                            <input
                                                                className="w-90 h-90 custom-input-dsg"
                                                                type={"number"}
                                                                onChange={(e) => handleInputChange(ind, 'smoqprice', e.target.value)}
                                                            />

                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {priceMatrixList[item.LeatherEnquiryID] != undefined ?
                                                            <input
                                                                className="h-90 custom-input-smoq"
                                                                type={"number"}
                                                                value={priceMatrixList[item.LeatherEnquiryID] != undefined ? (priceMatrixList[item.LeatherEnquiryID][141].MOQ) : ''}
                                                                onChange={(e) => handleInputChange(ind, 'bmoq', e.target.value)}
                                                            /> :
                                                            <input
                                                                className="h-90 custom-input-smoq"
                                                                type={"number"}
                                                                onChange={(e) => handleInputChange(ind, 'bmoq', e.target.value)}
                                                            />
                                                        }
                                                    </td>
                                                    <td>
                                                        {priceMatrixList[item.LeatherEnquiryID] != undefined ?
                                                            <input
                                                                className="w-90 h-90 custom-input-dsg"
                                                                type={"text"}
                                                                value={priceMatrixList[item.LeatherEnquiryID] != undefined ? (parseInt(priceMatrixList[item.LeatherEnquiryID][141].Price)).toFixed(3) : ''}
                                                                onChange={(e) => handleInputChange(ind, 'bmoqprice', e.target.value)}
                                                            /> :
                                                            <input
                                                                className="w-90 h-90 custom-input-dsg"
                                                                type={"text"}
                                                                onChange={(e) => handleInputChange(ind, 'bmoqprice', e.target.value)}
                                                            />
                                                        }
                                                    </td>
                                                    <td>
                                                        <div className="date-picker-container">
                                                            <Datepicker
                                                                selected={priceupto[ind] || null}
                                                                onChange={(date) => handleDateChange(date, ind)}
                                                                dateFormat="dd-MMM-yyyy"
                                                            />
                                                            <span className="calendar-icon"><Image src={Calendar} /></span>
                                                        </div>
                                                    </td>
                                                </>
                                            }
                                            <td>
                                                <img src={Remarks} style={{ cursor: "pointer" }} onClick={() => remarkpopup(remarks.length > 0 && remarks[ind] != '' ? remarks[ind] : item.Remarks, ind)} />
                                            </td>
                                        </tr>
                                    );


                                })}
                            </tbody>
                        </table>
                    </div>

                    <Modal
                        id="#po-card-acknowledge"
                        className="po-acknoweld-card-cont px-3 fnt-fam-f2  pt-3 pb-1"
                        show={openremarkpopup}
                        size="md"
                        centered
                        onHide={() => setOpenRemarkPopup(false)}
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <div className=" d-flex justify-content-end pb-2 mt-3">
                            <span onClick={RemarkCloseModal} style={{ cursor: "pointer" }}> X </span>
                        </div>
                        <label className="my-3">
                            Comments<span className="red-text">*</span>
                        </label>
                        <textarea
                            className="modal-txt-custom"
                            maxLength={250}
                            onChange={(e) => setRemarkContent(e.target.value)}
                            value={remarkContent}
                            rows={5}
                        />
                        <p className="d-flex justify-content-end opac-85">Max 250</p>
                        <div className="d-flex mt-4 mb-1 justify-content-end gap-3">
                            <button className="clear-can-btn border-0 rounded-1 fnt-fam-f1" style={{ color: "black" }} onClick={clearData}>Clear</button>
                            <button className="bg-primary1 bg-primary1 sub-app-btn text-white rounded-1 border-0 fnt-fam-f1" onClick={ackSubmitHandler}>Submit</button>
                        </div>
                    </Modal>

                    <Modal
                        id="#sucess"
                        className="otp-modal"
                        show={submitAck}
                        size="md"
                        centered
                        onHide={() => setSubmitAck(false)}
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <div className="sup-modal ">
                            <Image src={FavLogo} />
                            <div className="mt-2">
                                <div className="d-flex-as-jc img_tick ">
                                    <Image src={Check} />
                                    <div className="px-3">
                                        <p>Do you want to Submit the Acknowledge ?</p>
                                    </div>
                                </div>
                                <div className="d-flex flex-row-reverse">
                                    <div className="row">
                                        <div className="col-sm">
                                            <Link
                                                style={{
                                                    backgroundColor: "#D1D3D4",
                                                    width: "70px",
                                                    height: "30px",
                                                }}
                                                className="my-3 modal_btn"
                                                to="#"
                                                onClick={() => {
                                                    setSubmitAck(false);
                                                }}
                                            >
                                                No
                                            </Link>
                                        </div>
                                        <div className="col-sm">
                                            <Link
                                                style={{
                                                    width: "70px",
                                                    height: "30px",
                                                }}
                                                className="my-3 modal_btn"
                                                to="#"
                                                onClick={() => {
                                                    setSubmitAck(false);
                                                }}
                                            >
                                                Yes
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        id="#sucess"
                        className="otp-modal"
                        show={success}
                        size="md"
                        centered
                        onHide={() => setSuccess(false)}
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <div className="sup-modal ">
                            <Image src={FavLogo} />
                            <div className="mt-4">
                                <div className="d-flex-as-jc img_tick ">
                                    <Image src={Check} />
                                    <div className="px-3">
                                        <h5>Successfull</h5>
                                        <p>Price Enquiry has been updated Successfully</p>
                                    </div>
                                </div>
                                <Link
                                    className="my-3 modal_btn"
                                    to="#"
                                    style={{
                                        width: "70px",
                                        height: "30px",
                                    }}
                                    onClick={() => {
                                        setSuccess(false);
                                        // localStorage.clear();
                                        window.location.reload();
                                    }}
                                >
                                    OK
                                </Link>
                            </div>
                        </div>
                    </Modal>

                </div>
                <div className="">
                </div>
                {/* <GeneralPagination /> */}
            </div>
            <div className="rg-footer">
                <LogInFooter />
            </div>
        </>
    );
}

export default PriceEnquiryList;
