import React, { useState, useEffect } from "react";
import { RiDeleteBack2Line } from "react-icons/ri";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RxTriangleUp } from "react-icons/rx";
import { HiDocumentSearch } from "react-icons/hi";
import ViewDetails from "../../assets/Icons/Normal Icons/ViewDetails.svg";
import { Col } from "react-bootstrap";
import useUser from "../../constants/Storage/userData";
function InvoiceTableContent({
  dcGetDetails,
  invoicedDetails,
  invTableSelectHandler,
  checkData,
  handleCheckAllChange,
  tableCheck,
  onNoNameChange,
  noName,
  setNoNames,
  noNames,
  setPoQuantity,
  poQuantity,
  type,
  tableTwo,
  setPer_centageFlag,
  per_centageFlag,
}) {
  
  const { user } = useUser();
  const [tableDetails, setTableDetails] = useState([]);
  const [showModel, setShowModel] = useState(null);
  const [errors, setErrors] = useState({}); // Initialize an object to store errors
  // const [poQuantity, setPoQuantity] = useState([]);
  const [inputValues, setInputValues] = useState({});

  const modelHideHandler = () => setShowModel(null);
  const modelShowHandler = (sel_id) => {
    setShowModel(+sel_id);
  };

  useEffect(() => {
    let temp = [];
    // dcGetDetails?.map((item) => item?.map((item_1) => temp.push(item_1)));
    setTableDetails(dcGetDetails);
  }, []);

  const handleInputChange = (value, itemId, item) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [itemId]: value, // Update the specific itemId's value immediately
    }));

    // Add to the selected quantity array (or update as needed)
    const newEntry = {
      TotalInvoiceQuantity: item?.detail_delivery_details
        ? Number(item?.detail_delivery_details?.Quantity).toFixed(2)
        : Number(item?.delivery_detail?.Quantity).toFixed(2),
      InvoicedQuantity: value,
      KHSkuCode: item?.full_order_detail?.sku?.MaterialSKUCode || item?.full_order_detail?.sku?.LeatherSKUCode,
      PoNo: item?.full_order_detail?.purchase_order?.MaterialPurchaseOrderNo || item?.full_order_detail?.purchase_order?.LeatherPurchaseOrderNo,
      itemId,
    };

    setPoQuantity((prevSelectedQuantity) => {
      const updatedQuantity = prevSelectedQuantity.map((entry) => {
        if (entry.itemId === newEntry.itemId) {
          entry.InvoicedQuantity = newEntry.InvoicedQuantity;
        }
        return entry; // Keep the other entries unchanged
      });

      if (!updatedQuantity.some((entry) => entry.itemId === newEntry.itemId)) {
        updatedQuantity.push(newEntry);
      }
      return [...updatedQuantity]; // Return a new array to trigger re-render
    });
  };

  useEffect(() => {
  }, [inputValues, poQuantity]);

  return (
    <div className="mid-sc-res">
      <div
        style={{ width: "inherit" }}
        className="table-scroll-horizontal over-flow-x-scroll mb-2 table-sm invoice-table-cont "
      >
        <table className="my-3 py-2 table grn-table-wid">
          <thead style={{ background: "#ededed" }}>
            <tr className="fnt-fam-f3 opac-85 text-center">
              <th className="">
                <div className="d-flex">
                  {" "}
                  <label className="d-flex table-chk-cont">
                    <input
                      type="checkbox"
                      className="me-2 "
                      onChange={handleCheckAllChange}
                      checked={tableCheck?.length === dcGetDetails?.length}
                    />
                  </label>
                  Sl.No.
                </div>
              </th>
              <th className="text-nowrap">PO No.</th>
              <th className="text-nowrap">Del Date</th>
              <th className="text-nowrap">Supplier Mat. Code</th>
              <th className="text-nowrap">KH SKU CODE</th>
              <th className="text-nowrap">KH SKU Name</th>
              <th className="text-nowrap">HSN Code</th>
              <th className="pd_03r text-nowrap">
                UOM
                {/* <RiArrowDropDownLine className="fs-3" /> */}
              </th>
              <th className="pd_03r text-nowrap">
                PO Qty
                {/* <RiArrowDropDownLine className="fs-3" /> */}
              </th>
              <th className="pd_03r text-nowrap">
                Invoiced Qty
                {/* <RiArrowDropDownLine className="fs-3" /> */}
              </th>
              <th className="pd_03r text-nowrap">
                Current Invoice Qty
                {/* <RiArrowDropDownLine className="fs-3" /> */}
              </th>
              <th className="pd_03r text-nowrap">
                Unit Rate
                {/* <RiArrowDropDownLine className="fs-3" /> */}
              </th>
              {+user?.supplier?.CountryID == 1 && <th>GST%</th>}
              <th>Amount</th>
            </tr>
          </thead>
          <tbody className="fs-6 opac-80">
            {dcGetDetails &&
              dcGetDetails?.map((item, ind) => {
                // console.log(invoicedDetails[ind]., 'invoicedDetails');
                const itemId = item?.full_order_detail?.MaterialPurchaseOrderDetailID || item?.full_order_detail?.LeatherPurchaseOrderDetailID;
                const maxValue = noName[itemId] || 0; // Get the max value for the specific item
                const KHSkuCode = item?.full_order_detail?.sku?.MaterialSKUCode || item?.full_order_detail?.sku?.LeatherSKUCode;
                const item2 = invoicedDetails.find(detail => detail.KHSkuCode === KHSkuCode);
                var overallqty = item?.detail_delivery_details ? Number(item?.detail_delivery_details?.Quantity
                  ) : Number(item?.delivery_detail?.Quantity)
                var invoicedQty = 0;
                var remainQty = overallqty;
                console.log(remainQty, 'remainQty1');

                if (item2 && item2 != undefined) {
                  invoicedQty = item2.InvoicedQty;
                  remainQty = overallqty - Number(item2.InvoicedQty);
                }
                return (
                  <tr key={ind} className="text-center">
                    <td>
                      <div className="d-flex gap-1">
                        {/* {noNames ? (
                          <>
                          </>
                        ) : type !== "view" && type !== "edit" ? (
                          <input
                            type="checkbox"
                            className="me-2 my-1"
                            disabled
                          />
                        ) : null} */}
                        <label className="d-flex table-chk-cont">
                          <input
                            type="checkbox"
                            className="me-2 my-1"
                            onClick={() => {
                              invTableSelectHandler(ind);
                              checkData(item, ind);
                            }}
                            checked={tableCheck?.includes(ind)}
                          />
                        </label>
                        0{ind + 1}
                      </div>
                    </td>
                    <td>
                      {item?.full_order_detail?.purchase_order
                        ?.MaterialPurchaseOrderNo ||
                        item?.full_order_detail?.purchase_order
                          ?.LeatherPurchaseOrderNo}
                    </td>
                    <td maxLength={10} style={{ textWrap: "nowrap" }}>
                      {item?.detail_delivery_details?.DeliveryDate.substring(
                        0,
                        10
                      ) ||
                        item?.full_order_detail?.purchase_order
                          ?.LeatherPurchaseOrderNo}
                    </td>
                    <td> - </td>
                    <td>
                      {item?.full_order_detail?.sku?.MaterialSKUCode ||
                        item?.full_order_detail?.sku?.LeatherSKUCode}
                    </td>
                    <td>
                      {item?.full_order_detail?.sku?.MaterialSKUName ||
                        item?.full_order_detail?.sku?.LeatherSKUName}
                    </td>
                    <td>
                      {item?.full_order_detail?.sku?.material_header?.hsn
                        ?.HSNCode ||
                        item?.full_order_detail?.sku?.leather_header?.hsn
                          ?.HSNCode}
                    </td>
                    <td>
                      {item?.full_order_detail?.sku?.material_header?.uom
                        ?.UomCode ||
                        item?.full_order_detail?.sku?.leather_header?.uom
                          ?.UomCode}
                    </td>
                    <td>
                      {item?.detail_delivery_details
                        ? Number(
                          item?.detail_delivery_details?.Quantity
                        ) : Number(item?.delivery_detail?.Quantity)}
                    </td>
                    <td>{overallqty - remainQty}
                      {/* {item?.invoice_quantity == 0
                        ? 0
                        : item?.invoice_quantity
                          ? Number(item?.invoice_quantity)
                          : Number(item?.qty)} */}
                    </td>
                    <td>
                      {/* <input
                        min={1}
                        max={noName[
                          item?.full_order_detail
                            ?.MaterialPurchaseOrderDetailID ||
                            item?.full_order_detail
                              ?.LeatherPurchaseOrderDetailID
                        ]}
                        type="number"
                        value={
                          noName[
                            item?.full_order_detail
                              ?.MaterialPurchaseOrderDetailID ||
                              item?.full_order_detail
                                ?.LeatherPurchaseOrderDetailID
                          ]
                        }
                        onChange={(e) => 
                          onNoNameChange(
                            e,
                            item?.full_order_detail
                              ?.MaterialPurchaseOrderDetailID ||
                              item?.full_order_detail
                                ?.LeatherPurchaseOrderDetailID,
                            +item?.detail_delivery_details?.ReceivedQuantity
                          )
                        }
                        disabled={!tableTwo?.includes(item)}
                        required
                      /> */}
                      <div key={ind}>
                        <input
                          type="number"
                          min={1}
                          max={maxValue}
                          // value={inputValue == '' ? noName[itemId] !== undefined ? Number(noName[itemId]) : 0 : inputValue} // Allow empty string during typing
                          // value={inputValues[KHSkuCode] === '' ? '' : inputValues[KHSkuCode] || (noName[itemId] !== undefined ? Number(noName[itemId]) : 0)}
                          value = {inputValues[KHSkuCode] === '' ? '' : inputValues[KHSkuCode] || ''}
                          onChange={(e) => handleInputChange(e.target.value, KHSkuCode, item)} 

                          // onChange={(e) => {
                          //   const value = e.target.value;
                          //   const numericValue = Number(value);

                          //   // Update the specific input value for the current itemId
                          //   // setInputValues((prevInputValues) => ({
                          //   //   ...prevInputValues,
                          //   //   [KHSkuCode]: value,
                          //   // }));
                          //   // Ensure we only update state with valid numeric values
                          //   if (!isNaN(numericValue) && numericValue >= 1) {
                          //     // Update inputValues for the current itemId only
                          //     setInputValues((prevInputValues) => ({
                          //       ...prevInputValues,
                          //       [KHSkuCode]: numericValue, // Store the raw input so the user can see what they typed
                          //     }));
                          //   }
                          //   console.log(inputValues, 'inpurtValues');

                          //   // Create the new object
                          //   const newEntry = {
                          //     TotalInvoiceQuantity: item?.detail_delivery_details
                          //       ? Number(item?.detail_delivery_details?.Quantity).toFixed(2)
                          //       : Number(item?.delivery_detail?.Quantity).toFixed(2),
                          //     InvoicedQuantity: value,
                          //     KHSkuCode: item?.full_order_detail?.sku?.MaterialSKUCode || item?.full_order_detail?.sku?.LeatherSKUCode,
                          //     PoNo: item?.full_order_detail?.purchase_order?.MaterialPurchaseOrderNo || item?.full_order_detail?.purchase_order?.LeatherPurchaseOrderNo,
                          //   };

                          //   // Update selectedQuantity array in state
                          //   // setPoQuantity((prevSelectedQuantity) => [...prevSelectedQuantity, newEntry]);
                          //   setPoQuantity((prevSelectedQuantity) => [
                          //     ...(Array.isArray(prevSelectedQuantity) ? prevSelectedQuantity : []), // Ensure previous is always an array
                          //     newEntry,
                          //   ]);
                          //   console.log(poQuantity, 'poQuantity');
                          // }}
                          onBlur={(e) => {
                            const value = e.target.value;
                            const numericValue = Number(value);
                            //   ? 0
                            //   : item?.invoice_quantity
                            //     ? Number(item?.invoice_quantity)
                            //     : Number(item?.qty), 'item?.detail_delivery_details?.ReceivedQuantity');
                            // Enforce the min and max constraints on blur
                            if (numericValue === 0) {
                              setErrors((prevErrors) => ({ ...prevErrors, [itemId]: 'Value cannot be 0' }));
                            } else if (numericValue > remainQty) {
                              setErrors((prevErrors) => ({
                                ...prevErrors, [itemId]: `Value cannot exceed ${Number(remainQty)}`
                              })); // Show error if greater than max
                            } else {
                              setErrors((prevErrors) => ({ ...prevErrors, [itemId]: '' }));
                            }
                          }}
                          disabled={!tableTwo?.includes(item)}
                          style={{ width: '100%' }}
                        />
                        {errors[itemId] && <div style={{ color: 'red' }}>{errors[itemId]}</div>} 
                      </div>
                      {remainQty}
                      {/* {Number(
                        item?.detail_delivery_details?.ReceivedQuantity ||
                          item?.delivery_detail?.ReceivedQuantity
                      ).toFixed(2)} */}
                    </td>
                    <td>{Number(item?.full_order_detail?.Price).toFixed(2)}</td>
                    {+user?.supplier?.CountryID == 1 && (
                      <td>
                        {" "}
                        {item?.full_order_detail?.tax?.TaxPercentage ?? "-"}
                      </td>
                    )}
                    <td>
                      {/* {item?.detail_delivery_details?.ReceivedQuantity
                        ? Number(
                            item?.full_order_detail?.Price *
                              item?.detail_delivery_details?.ReceivedQuantity
                          ).toFixed(2)
                        : item?.delivery_detail?.ReceivedQuantity
                        ? Number(
                            item?.full_order_detail?.Price *
                              item?.delivery_detail?.ReceivedQuantity
                          ).toFixed(2)
                        : "-"} */}
                      {Number(
                        noName[
                        item?.full_order_detail
                          ?.MaterialPurchaseOrderDetailID ||
                        item?.full_order_detail
                          ?.LeatherPurchaseOrderDetailID
                        ] *
                        +item?.full_order_detail?.Price *
                        +item?.full_order_detail?.purchase_order?.ExRate
                      ).toFixed(2)}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default InvoiceTableContent;
